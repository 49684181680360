import { message } from 'antd';
import { verifyActivationCode } from '../api/dashboard';
import { LocalStorageKeys, LocalStorageService } from 'src/services/localStorage';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const handleSignInWithActivationLink = async (props) => {
    const searchParams = new URLSearchParams(props?.search);
    const activationCode = searchParams?.has('activationCode') ? searchParams.get('activationCode') : '';

    if (activationCode) {
      const decodedActivationCode = atob(activationCode);
      const decodedCredentials = decodedActivationCode.split(':');
      if (!(Array.isArray(decodedCredentials) && decodedCredentials.length === 3)) {
        message.error('Wrong Activation Code');
        return;
      }
      removeOldLocalStorageData();

      const [decodedOrganisationId, decodedEmployeeId, decodedAuthToken] = decodedCredentials;
      await LocalStorageService.set(LocalStorageKeys.__ORGANISATION_ID__, decodedOrganisationId);

      const response = await verifyActivationCode({
        activationCode,
        employeeId: decodedEmployeeId,
        authToken: decodedAuthToken,
      });

      if (response?.response?.status === 'OK') {
        await setLocalStorageData(response);
      } else {
        const errorMessage = response?.response?.error?.message || 'Activation Link Expired';
        message.error(errorMessage);
      }
    }
}

const removeOldLocalStorageData = () => {
  cookies.remove('is_authenticated', { path: '/' });
  LocalStorageService.remove(LocalStorageKeys.___USER_ID__);
  LocalStorageService.remove(LocalStorageKeys.__AUTH_TOKEN__);
  LocalStorageService.remove(LocalStorageKeys.__ORGANISATION_ID__);
};

const setLocalStorageData = async (response: any) => {
    const accessToken = response.data?.token?.id;
    // const accessTokenExpiry = response.data?.token?.expiry_at;
    const employeeId = response.data?.employeeId;
    const organisationId = response.data?.organisationId;

    await LocalStorageService.set(LocalStorageKeys.___USER_ID__, employeeId);
    await LocalStorageService.set(LocalStorageKeys.__AUTH_TOKEN__, accessToken);
    await LocalStorageService.set(LocalStorageKeys.__ORGANISATION_ID__, organisationId);
    cookies.set('is_authenticated', 'true', {
      path:'/',
      expires: new Date((new Date().getTime()) + (1000 * 60 * 60 * 24 * 365)) ,
      secure: true, sameSite: 'lax',
    });

    window.location.href = '/';
};
import * as React from 'react';
import withStyles from 'react-jss';
import * as  moment from 'moment';
import { CssType, ThemeType } from './theming/jssTypes';
import { withRouter } from 'react-router';
import './App.css';
import 'antd/dist/antd.less';
import { loadInitialData } from './actions/loadInitialData';
import { connect } from 'react-redux';
import SideMenu from './components/sidemenu/SideMenu';
import Loader from './components/common/Loader';
import * as LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import {
  LOG_ROCKET_KEY, ENVIRONMENT, RENDER_PERSISTENT_IFRAME_OPS,
  SENTRY_KEY, VERSION, IS_ENV_PRODUCTION, USERPILOT_TOKEN,
  LOG_ROCKET_CONFIG,
} from './globals';
import * as Sentry from '@sentry/browser';
import Router from './Router';

import { getUsecaseType } from './selectors/masterdata';
import PersistentIframeInjector from './components/common/persistentIframeRenderer/PersistentIframeInjector';
import { IframeApps } from './components/common/persistentIframeRenderer/constants';
import { setCurrHub } from './actions/cachedDataActions';
import { withTranslation } from 'react-i18next';
import { isLanguageRTL } from './utils/intl-utils';
import { setRTL } from './actions/uiThemeActions';

import UserConsentModal from './components/common/UserConsentModal';
import PaymentOverdueBanner from './components/common/PaymentOverdueBanner';
import ScheduledMaintenanceBanner from './components/common/ScheduledMaintenanceBanner';
import PasswordWarning from './components/pages/PasswordPolicy/PasswordWarning';
import ChangePasswordModal from './components/common/navbarModals/changePassword/changePasswordModal';
import { getDevToolsRoute } from './routing/utils';
import * as globals from './globals';
import { notification } from 'antd';
import { handleSignInWithActivationLink } from './utils/app-utils';
import { initializeGa } from './utils/googleAnalyticsHelper';
import { userpilotInitialize } from './utils/userpilotHelper';
import { isAuthenticationHeadersPresent, redirectToLoginPage, isAuthenticationFlowPage } from './api/utils';
import DevrevWidget from './components/common/DevrevWidget';
import { notificationInitialisation } from './utils/notificationServiceHelper';
import { OlvyWidget } from '@olvyhq/widget-react';
import { LocalStorageKeys, LocalStorageService } from './services/localStorage';
import Cookies from 'universal-cookie';

const styles = (theme: ThemeType): CssType => ({
  mainDiv: {
    direction: (props) => isLanguageRTL(props.i18n.language) ? 'rtl' : 'ltr',
  },
  contentDiv: {
    height: '100%',
    overflow: 'auto',
    backgroundColor: theme.colors.pageBg,
  },
  bannerDiv:{
    position: 'relative',
    top: '-115px',
    maxWidth: '80%',
    width: 'fit-content',
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'space-around',
    margin: 'auto',
    zIndex: 999,
    overflow: 'hidden',
  },
});
const allowedViewTypesIframeOps = [
  'logistics',
  'retail',
  'domestic',
  'tms',
  'logistics_express',
  'ondemand',
];
const cookies = new Cookies();
class App extends React.Component<any, any> {

  state = {
    overdueBannerClosed: false,
    maintenanceBannerClosed: false,
    isConsentGiven: false,
    passwordWarningClosed: false,
    isChangePassowrdModalVisible: false,
    userId: null,
    organisationId: null,
    scheduledMaintenanceBannerCloseExpired: false,
  };

  checkSignInFromInternal = async () => {
    const searchParams = new URLSearchParams(this.props.location?.search);
    const decodedAccessToken = searchParams?.has('accessToken') ? window.atob(searchParams.get('accessToken')) : '';
    const decodedAccessTokenExpiry = searchParams?.has('tokenExpiry') ? window.atob(searchParams.get('tokenExpiry')): '';
    const decodedOrganisationId = searchParams?.has('organisationId') ? window.atob(searchParams.get('organisationId')): '';
    const decodedEmployeeId = searchParams?.has('employeeId') ? window.atob(searchParams.get('employeeId')): '';
    if (
      decodedAccessToken &&
      decodedAccessTokenExpiry &&
      decodedEmployeeId &&
      decodedOrganisationId
    ) {
      await LocalStorageService.set(LocalStorageKeys.__AUTH_TOKEN__, decodedAccessToken);
      await LocalStorageService.set(LocalStorageKeys.___USER_ID__, decodedEmployeeId);
      await LocalStorageService.set(LocalStorageKeys.__ORGANISATION_ID__, decodedOrganisationId);
      cookies.set('is_authenticated', 'true', {
        path:'/',
        expires: new Date((new Date().getTime()) + (1000 * 60 * 60 * 24 * 365)) ,
        secure: true, sameSite: 'lax',
      });
      window.location.href = '/';
    }
  };

  handleSignInUsingActivationLink = async () => {
    await handleSignInWithActivationLink(this.props.location);
  };

  // @TODO: remove this after adoption of localstorage
  destroyCookies = () => {
    //Remove older cookies
    const itemsToRemoveFromCookies = ['name', 'userId', 'authToken', 'organisationID', 'logoURL', 'bookingVisible', 'permission', 'redirectToViewType', 'redirectToHub', 'employeePhone'];
    itemsToRemoveFromCookies.forEach((elem) => {
      cookies.remove(elem, { path: '/dashboard/' });
      cookies.remove(elem, { path: '/analytics' });
      cookies.remove(elem, { path: '/dashboard' });
      cookies.remove(elem, { path: '/' });
    });
  }

  // @TODO: remove this after adoption of localstorage
  checkForCookiesAndTransferDataToLocal = async () => {
    try {
      const keysMap = {
        authToken: LocalStorageKeys.__AUTH_TOKEN__,
        bookingVisible: LocalStorageKeys.__BOOKING_VISIBLE__,
        employeePhone: LocalStorageKeys.__EMPLOYEE_PHONE__,
        logoURL: LocalStorageKeys.__LOGO_URL__,
        name: LocalStorageKeys.__NAME__,
        organisationID: LocalStorageKeys.__ORGANISATION_ID__,
        userId: LocalStorageKeys.___USER_ID__,
        redirectToViewType: LocalStorageKeys.__REDIRECT_TO_VIEW_TYPE__,
        permission: LocalStorageKeys.__PERMISSION__,
        redirectToHub: LocalStorageKeys.__REDIRECT_TO_HUB__,
      };
      const cookiesPresent = ['authToken', 'userId', 'organisationID'].every(key => (cookies.get(key) && cookies.get(key) !== 'undefined' && cookies.get(key) !== 'null'));
      // if user is logged in using cookies and data has not been transferred to local storage then transfer it
      if (cookiesPresent && LocalStorageService.getRaw(LocalStorageKeys.__AUTH_DATA_TRANSFERRED__) !== 'true') {
        for (const key in keysMap) {
          const value = cookies.get(key);
          if (value) {
            await LocalStorageService.set(keysMap[key], value);
          }
        }
        const currentHubOld = LocalStorageService.getRaw('currentHub');
        const currentHubsOld = LocalStorageService.getRaw('currentHubs');
        if (currentHubOld && currentHubOld !== 'undefined' && currentHubOld !== 'null') {
          await LocalStorageService.set(LocalStorageKeys.__CURRENT_HUB__, currentHubOld);
          LocalStorageService.remove('currentHub');
        }
        if (currentHubsOld && currentHubsOld !== 'undefined' && currentHubsOld !== 'null') {
          await LocalStorageService.set(LocalStorageKeys.__CURRENT_HUBS__, currentHubsOld);
          LocalStorageService.remove('currentHubs');
        }
        await LocalStorageService.set(LocalStorageKeys.__AUTH_DATA_TRANSFERRED__, 'true');
        cookies.set('is_authenticated', 'true', {
          path:'/',
          expires: new Date((new Date().getTime()) + (1000 * 60 * 60 * 24 * 365)) ,
          secure: true, sameSite: 'lax',
        });
      }
    } catch (e) {
      console.log('There was an error while transferring data from cookies to local storage', e);
    } finally {
      this.destroyCookies();
    }
  };

  async componentDidMount() {
    await this.checkForCookiesAndTransferDataToLocal();
    const favicon_url = await LocalStorageService.get(LocalStorageKeys.__FAVICON_URL__);
    const favElement: any = document.getElementById('favicon');
    if(favicon_url && typeof favicon_url === 'string'){
      favElement.href = favicon_url;
    }
    const { fetchingData, dispatch, location, i18n } = this.props;
    if (!fetchingData) return;

    if (this.props.location?.pathname?.includes('signInFromInternal')) {
      await this.checkSignInFromInternal();
    }

    if (this.props.location?.pathname === '/employeeActivation') {
      await this.handleSignInUsingActivationLink();
    }

    if (isAuthenticationHeadersPresent() && !isAuthenticationFlowPage()) {
      await dispatch(loadInitialData(location.pathname));
    } else if (!isAuthenticationFlowPage()) {
      redirectToLoginPage();
    } else {
      return;
    }

    await this.initLogRocket();
    await this.initSentry();

    // Handle RTL theme set
    this.setUIDirection(i18n.language);
    i18n.on('languageChanged', (lng) => {
      this.setUIDirection(lng);
    });

    // Add event listener for change in current hub from iframe
    window.addEventListener('storage', (event) => {
      if (event.key !== 'currentHub') {
        return;
      }
      try {
        const oldValue = JSON.parse(event.oldValue || '{}');
        const newValue = JSON.parse(event.newValue || '{}');
        if (oldValue.id !== newValue.id) {
          dispatch(setCurrHub({ currHub: newValue }, true));
        }
      } catch (e) { }
    });
    this.showConfigWarning();
    initializeGa();
    if(IS_ENV_PRODUCTION){
      userpilotInitialize(this.props.username);
    }
    notificationInitialisation(dispatch);
    this.setState({
      userId: await LocalStorageService.get(LocalStorageKeys.___USER_ID__),
      organisationId: await LocalStorageService.get(LocalStorageKeys.__ORGANISATION_ID__),
      scheduledMaintenanceBannerCloseExpired: (await LocalStorageService.get(LocalStorageKeys.__SHOW_SCHEDULED_MAINTAINENCE_BANNER__) || 'true') === 'true',
    });
  }

  showConfigWarning() {
    const { history } = this.props;
    if (!globals.IS_FINAL_CONFIG_EQUAL) {
      notification.warning({
        message: 'Warning!',
        description: 'Your config file differs from the default one. App may behave differently. To avoid this, navigate to Dev Tools or click in this notification box',
        duration: 6,
        placement: 'bottomRight',
        style: { cursor: 'pointer' },
        onClick: () => history.push(getDevToolsRoute()),
      });
    }
  }

  setUIDirection = (lng) => {
    const { dispatch } = this.props;
    const isRTL = isLanguageRTL(lng);
    dispatch(setRTL(isRTL));
  };

  initLogRocket = async () => {
    if (LOG_ROCKET_KEY) {
      const userId: any = this.props.userData.username;
      const orgId: any = await LocalStorageService.get(LocalStorageKeys.__ORGANISATION_ID__);
      const name = `${orgId}_${this.props.userData.employee_code}_${this.props.userData.username}`;
      localStorage.setItem('userName', userId);
      if (LOG_ROCKET_CONFIG[orgId]?.includes(userId)) {
        LogRocket.init(LOG_ROCKET_KEY, {
          mergeIframes: true,
          childDomains: ['*']
        });
        LogRocket.identify(userId, {
          name,
          email: this.props.userData.email,
        });
        setupLogRocketReact(LogRocket);
      }
    }
  };
  initSentry = async () => {
    if (SENTRY_KEY) {
      const userId: any = this.props.userData.username;
      const orgId: any = await LocalStorageService.get(LocalStorageKeys.__ORGANISATION_ID__);
      const name = `${orgId}_${this.props.userData.employee_code}_${this.props.userData.username}`;
      Sentry.init({ dsn: SENTRY_KEY, release: VERSION, environment: 'production' });
      Sentry.configureScope((scope) => {
        scope.setUser({
          orgId,
          userId,
          email: this.props.userData.email,
          username: name,
        });
      });
    }
  };

  closeConsentModal = () => {
    this.setState({
      isConsentGiven: true,
    });
  };

  closeChangePasswordModal = () => {
    this.setState({
      isChangePassowrdModalVisible: false,
    });
  };

  closePasswordWarningModal = (type) => {
    if(type === 'skip'){
      this.setState({
        passwordWarningClosed: true,
      });
    } else {
      this.setState({
        passwordWarningClosed: true,
        isChangePassowrdModalVisible: true,
      });
    }
  };
  closeOverdueBanner = () => {
    this.setState({
      overdueBannerClosed: true,
    });
  };

  closeMaintenanceBanner = () => {
    this.setState({
      maintenanceBannerClosed: true,
    });
    LocalStorageService.set(LocalStorageKeys.__SHOW_SCHEDULED_MAINTAINENCE_BANNER__, 'false');
  };

  getTypeForPasswordWarning = () => {
    const { isFirstLogin, emailActivationOption, allowSkipPasswordWarning } = this.props;

    if (allowSkipPasswordWarning) {
      return 'warning';
    }

    if (emailActivationOption && isFirstLogin) {
      return 'firstLogin';
    }

    return 'expired';
  };

  render() {
    const { fetchingData } = this.props;
    if (fetchingData && !isAuthenticationFlowPage()) {
      return <Loader />;
    }

    const { classes, isOpsIframeLoaded, viewType, showUserConsent,
      showHybridView, paymentConfig, scheduledMaintenanceConfig,
      showPasswordWarning, allowSkipPasswordWarning } = this.props;
    const { isConsentGiven, overdueBannerClosed, maintenanceBannerClosed,
      passwordWarningClosed, isChangePassowrdModalVisible, scheduledMaintenanceBannerCloseExpired, userId,
      organisationId } = this.state;
    const showPaymentOverdueBanner = (paymentConfig?.banner_status ?? false)&& !overdueBannerClosed;
    const beforeMaintenanceEndTime = scheduledMaintenanceConfig
      && scheduledMaintenanceConfig.end_time
      ? moment(scheduledMaintenanceConfig.end_time).isAfter()
      : false;
    const showScheduledMaintenanceBanner = beforeMaintenanceEndTime
      && scheduledMaintenanceBannerCloseExpired && !maintenanceBannerClosed;
    const isConsentModalVisible = !isConsentGiven && showUserConsent;
    const isPasswordWarningVisible = !passwordWarningClosed && showPasswordWarning;

    return (
      <div className={classes.mainDiv} style={{ height: isAuthenticationFlowPage() ? 0 : '100%' }}>
       <OlvyWidget
          config={{ workspaceAlias: 'shipsy',
            options: {
              user: {
                name: this.props.userData.name,
                email: this.props.userData.email,
                identifier: userId,
                tags: [organisationId],
              },
            },
          }}
          targetElement={<div></div>}
        ></OlvyWidget>
        {isConsentModalVisible &&
          <UserConsentModal
            onModalClose={this.closeConsentModal}
            isVisible={isConsentModalVisible} />}
        {isPasswordWarningVisible && <PasswordWarning
          isVisible={isPasswordWarningVisible}
          type={this.getTypeForPasswordWarning()}
          handleSkip={() => this.closePasswordWarningModal('skip')}
          handleChangePassword={() => this.closePasswordWarningModal('change')}
        />}
        {
          isChangePassowrdModalVisible &&
            <ChangePasswordModal
              hideCloseButton={!allowSkipPasswordWarning}
              isModalActive={isChangePassowrdModalVisible}
              onModalClose={this.closeChangePasswordModal}
            />
        }
        <React.Suspense fallback={<Loader />}>
          <div className={classes.contentDiv}>
            <Router {...this.props} />
          </div>
        </React.Suspense>
        <SideMenu />
        <div className={classes.bannerDiv}>
        {showPaymentOverdueBanner &&
          <PaymentOverdueBanner
            paymentConfig={paymentConfig}
            onBannerClose={this.closeOverdueBanner}
          />
        }
        {showScheduledMaintenanceBanner &&
          <ScheduledMaintenanceBanner
            scheduledMaintenanceConfig={scheduledMaintenanceConfig}
            onBannerClose={this.closeMaintenanceBanner}
          />
        }
        </div>
        <DevrevWidget />
        <PersistentIframeInjector appName={IframeApps.GENERIC_ANALYTICS} topRenderDistance='0px' />
        <PersistentIframeInjector appName={IframeApps.API_PLAYGROUND} />
        <PersistentIframeInjector appName={IframeApps.LIA_CO_PILOT} />
        {
          (RENDER_PERSISTENT_IFRAME_OPS &&
            allowedViewTypesIframeOps.indexOf(viewType) > -1) ?
            <>
              <PersistentIframeInjector appName={IframeApps.OpsDashboard} />
              {/* {
              isOpsIframeLoaded ? null
              :
              <div style={{
                width: '100vw', height: '100vh', position: 'fixed',
                top: 0, left: 0, background: 'white', zIndex: 2000,
              }}>
                <Loader zIndex={100}/>
              </div>
            } */}
            </>
            : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { initializing, masterData, persistentIframe } = state;
  return {
    fetchingData: initializing,
    userData: masterData.user_data || {},
    usecaseType: getUsecaseType(masterData),
    viewType: masterData.viewType,
    isOpsIframeLoaded: persistentIframe[IframeApps.OpsDashboard].isLoaded,
    // isOpsIframeLoaded: false,
    showUserConsent: masterData.ops_dashboard_config?.parts_to_show?.show_consent,
    showPasswordWarning: masterData.password_expiry?.should_send,
    allowSkipPasswordWarning: masterData.password_expiry?.allow_skip,
    showHybridView: masterData.show_v1_hybrid_view,
    paymentConfig: masterData.ops_dashboard_config?.config?.payment_config,
    scheduledMaintenanceConfig:
      masterData.ops_dashboard_config?.config?.scheduled_maintenance_config,
    emailActivationOption: masterData.employee_email_activation_option,
    isFirstLogin: masterData.user_data?.is_first_login,
    isSuperAdmin: masterData.ops_dashboard_config?.parts_to_show?.super_admin,
    username: masterData.user_data?.username,
  };

};

export default withTranslation()(withRouter(
  connect(mapStateToProps)(withStyles(styles,
    { injectTheme: true })(App))) as React.ComponentType<any>);

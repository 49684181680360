import React, { useEffect, useRef } from 'react';
import UIMap from '../UIMap/UIMap';
import { UIMapMarker } from '../UIMap';
import type { MapRef } from 'react-map-gl';
import withStyles from 'react-jss';
import styles from './styles';
import { getStatusForRiderHistory } from 'src/utils/utils';
import moment from 'moment';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  popoverRow: {
    justifyContent: 'space-between',
    display: 'flex',
  },
  popverValue: {
    color: '#666',
    fontWeight: 500,
  },
  popoverTag: {
    fontWeight: 600,
    color: '#000',
  },
  popoverBody: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '10px',
    width: '13.5rem',
  },
});

const PinLocationMap = (props: any) => {
  const {
    initialLat,
    initialLng,
    geocodedAddress,
    onLocationChange,
    coordinates,
    hubLocation,
    riderConsignmentData = [],
  } = props;
  const updateCenter = useRef<boolean>(true);
  const mapRef = React.useRef<MapRef>();
  const classes = useStyles();

  const viewState = {
    latitude: initialLat ?? 28.4493,
    longitude: initialLng ?? 77.0759,
    zoom: 13,
  };

  const updateMapCenter = (map: any) => {
    const currentCenter = map?.target.getCenter();
    const centerCoordinates = {
      lat: currentCenter.lat,
      lng: currentCenter.lng,
    };
    if (onLocationChange) onLocationChange(centerCoordinates);
  };

  useEffect(() => {
    if (
      (updateCenter.current && initialLat && initialLng) ||
      (initialLat && initialLng && geocodedAddress)
    ) {
      mapRef?.current?.flyTo({
        center: [initialLng, initialLat],
        speed: 4,
      });
      updateCenter.current = false;
    }
  }, [initialLat, initialLng, geocodedAddress]);
  const hub = [hubLocation?.[1], hubLocation?.[0]];
  return (
    <>
      <UIMap
        mapRef={mapRef as any}
        initialViewState={viewState}
        onZoom={updateMapCenter}
        onDrag={updateMapCenter}
        onMove={updateMapCenter}
      >
        {coordinates.map((coordinate, index) => (
          <UIMapMarker key={index} coordinates={coordinate} color="blue" />
        ))}
        {riderConsignmentData.map((consignment, index) => (
          <UIMapMarker
            coordinates={[
              consignment.completion_long ?? consignment.lng ?? '77.043976',
              consignment.completion_lat ?? consignment.lat ?? '28.407684',
            ]}
            status={getStatusForRiderHistory({
              status: consignment.status,
              taskType: consignment.task_type,
            })}
            color="blue"
            type={'consigments'}
            index={index + 1}
            popupHoverContent={
              <div className={classes.popoverBody}>
                <div className={classes.popoverRow}>
                  <span className={classes.popoverTag}>Reference Number:</span>
                  <span className={classes.popverValue}>
                    {consignment.task_type === 'pickup'
                      ? consignment.task_reference_number
                      : consignment.reference_number}
                  </span>
                </div>
                <div className={classes.popoverRow}>
                  <span className={classes.popoverTag}>Status:</span>
                  <span className={classes.popverValue}>
                    {getStatusForRiderHistory({
                      status: consignment.status,
                      taskType: consignment.task_type,
                    })}
                  </span>
                </div>
                <div className={classes.popoverRow}>
                  <span className={classes.popoverTag}>Event Time:</span>
                  <span className={classes.popverValue}>
                    {moment(consignment.completion_time).format('hh:mm A')}
                  </span>
                </div>
              </div>
            }
          />
        ))}
        {hub?.[0] && (
          <UIMapMarker coordinates={hub} color="blue" type={'planning-hub'} />
        )}
      </UIMap>
    </>
  );
};

const styledComp = withStyles(styles, { injectTheme: true })(PinLocationMap);
export default styledComp;

import * as React from 'react';
import { Marker } from 'react-map-gl';

import { IUIMapMarker } from '@components/common/UIMap/utils/types';
import UpcountryHubSVG from '@components/pages/retail/tracking/UpCountryHubSVG';
import PlanningHubSVG from '@components/pages/retail/tracking/PlanningHubSVG';
import { UIMapPopup } from '@components/common/UIMap';
import { getUrlFromSVG } from '@utils/utils';
import { BlueDot } from 'src/assets/images/blueDot';
import { PinLocation } from 'src/assets/images/PinLocation';

const UIMapMarker = (props: IUIMapMarker) => {
  const {
    coordinates,
    anchor = 'bottom',
    offset,
    type,
    iconHeight = 30,
    iconWidth = 30,
    children,
    showPopup,
    popupContent,
    popupHoverContent,
    popupOffset,
    onMarkerClick = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    index,
    status,
    ...rest
  } = props;

  const [isPopupVisible, setIsPopupVisible] = React.useState(false);
  const [hover, setHover] = React.useState(false);

  const getMarkerContent = () => {
    if (children) return children;
    switch (type) {
      case 'planning-hub':
        return (
          <img
            src={getUrlFromSVG(PlanningHubSVG())}
            width={iconWidth}
            height={iconHeight}
          />
        );
      case 'upcountry-hub':
        return (
          <img
            src={getUrlFromSVG(UpcountryHubSVG())}
            width={iconWidth}
            height={iconHeight}
          />
        );
      case 'consigments':
        const statusColorMap = {
          Delivered: '#64A67A',
          'Picked Up': '#E6AC7F',
        };
        const color = statusColorMap[status] || '#E67F7F';
      return (
        <div><PinLocation index={index} color={color} fillColor={hover? color: '#FFF'}/></div>
      );
      default:
        return <div><BlueDot/></div>;
    }
  };

  const handleMarkerClick = (e) => {
    if (showPopup) {
      setIsPopupVisible(true);
    }
    onMarkerClick(false);
    e.originalEvent?.stopPropagation();
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  const onMouseEnterHandler = (e) => {
    setHover(true);
    if (onMouseEnter) {
      onMouseEnter(e);
    }
  };

  const onMouseLeaveHandler = (e) => {
    setHover(false);
    if (onMouseLeave) {
      onMouseLeave(e);
    }
  };
  return (
    <div onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler}>
      <Marker
        longitude={coordinates[0]}
        latitude={coordinates[1]}
        offset={offset}
        anchor={anchor}
        onClick={handleMarkerClick}
        {...rest}
      >
        {getMarkerContent()}
        {showPopup && isPopupVisible && (
          <UIMapPopup
            coordinates={coordinates}
            offset={popupOffset}
            showPopup={showPopup}
            popupContent={popupContent}
            onPopupClose={handleClosePopup}
            closeOnClick={true}
          />
        )}
        {hover && popupHoverContent && (
          <UIMapPopup
            coordinates={coordinates}
            offset={popupOffset}
            showPopup={true}
            popupContent={popupHoverContent}
            closeButton={false}
          />
        )}
      </Marker>
    </div>
  );
};

export default UIMapMarker;

import {
  BUSINESS_BOOKING_API_URL,
  LTL_BASE_URL,
  API_BASE_URL,
  QUERYBUILDER_CONFIG,
} from '../globals';
import {
  GET_COURIER_FIELDS,
  ADD_COURIER_FIELDS,
  DELETE_COURIER_FIELDS,
  GET_WITH_FILTER_COURIER_FIELDS,
  GET_ALL_PARTNERS_COURIER_FIELDS,
  UPDATE_COURIER_FIELDS,
  ADD_ALLOCATION_CONFIG,
  DELETE_ALLOCATION_CONFIG,
  FETCH_ALLOCATION_CONFIG_MASTERDATA,
  FETCH_ALLOCATION_MASTERDATA,
  REORDER_ALLOCATION_CONFIG,
  FETCH_ALLOCATION_DATA,
  UPDATE_ALLOCATION_DATA,
  FETCH_ALLOCATION_RULES,
  DELETE_ALLOCATION_RULES,
  ADD_ALLOCATION_RULES,
  FETCH_GME_DOWNLOAD_REQUESTS,
  COURIER_TRACKING_FETCH,
  COURIER_TRACKING_ADD,
  COURIER_TRACKING_ADD_BULK,
  COURIER_TRACKING_DELETE,
  COURIER_TRACKING_UPDATE,
  COURIER_TRACKING_ADD_MAPPING_GROUP,
  COURIER_TRACKING_EDIT_MAPPING_GROUP,
  COURIER_TRACKING_DELETE_MAPPING_GROUP,
  COURIER_TRACKING_UPDATE_SETTINGS,
  FETCH_UPDATE_HEADERS,
  DOWNLOAD_ALLOCATION_DATA,
  DOWNLOAD_ALLOCATION_DATA_SYNC,
  DISABLE_ALLOCATION_RULES,
  EDIT_ALLOCATION_RULE,
  UPDATE_COURIER_FIELD_MASTER_CONFIG,
  GET_COURIER_FIELD_MASTER_CONFIG,
  FETCH_COURIER_PARTNER_ACCOUNT,
  GET_META_DATA_FOR_ALLOCATION_ENGINE,
  GET_TREE_FOR_ALLOCATION_ENGINE,
  POST_TREE_FOR_ALLOCATION_ENGINE,
  COURIER_FIELD_MASTER_FILTER_KEYS,
  DISTINCT_PRICE_DATA_FOR_ALLOCATION,
  COURIER_FIELD_MASTER_MASTER_DATA
} from './routes';
import { GET, POST } from './apiHandler';
import { getAuthenticationHeaders } from './utils';
// Carrier Field Master
export const courierFieldMasterFetch = (PAGE) => {
  return GET(`${LTL_BASE_URL}${GET_COURIER_FIELDS}?page=${PAGE}`);
};

export const courierFieldMasterAddSingle = async (body) => {
  return POST(`${LTL_BASE_URL}${ADD_COURIER_FIELDS}`, body);
};

export const courierFieldMasterDeleteSingle = async (body) => {
  return POST(`${LTL_BASE_URL}${DELETE_COURIER_FIELDS}`, body);
};

export const courierFieldMasterFetchWithFilter = async (PAGE, body) => {
  return POST(
    `${LTL_BASE_URL}${GET_WITH_FILTER_COURIER_FIELDS}?page=${PAGE}`,
    body,
  );
};

export const updateCourierFieldMasterConfig = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_COURIER_FIELD_MASTER_CONFIG}`, body);
};

export const getCourierFieldMasterConfig = async () => {
  return GET(`${API_BASE_URL}${GET_COURIER_FIELD_MASTER_CONFIG}`);
};

export const courierFieldMasterGetAllCourierPartners = async () => {
  return GET(`${LTL_BASE_URL}${GET_ALL_PARTNERS_COURIER_FIELDS}`);
};

export const courierFieldMasterUpdateSingle = async (body) => {
  return POST(`${LTL_BASE_URL}${UPDATE_COURIER_FIELDS}`, body);
};

export const getCourierFieldMasterFilterKeys = async () => {
  return GET(`${LTL_BASE_URL}${COURIER_FIELD_MASTER_FILTER_KEYS}`);
};

export const postCourierFieldMasterFilterKeys = async (body) => {
  return POST(`${LTL_BASE_URL}${COURIER_FIELD_MASTER_FILTER_KEYS}`, body);
};

export const getCourierFieldMasterData = async () => {
  return GET(`${LTL_BASE_URL}${COURIER_FIELD_MASTER_MASTER_DATA}`)
}

export const fetchAllocationMasterdata = async () => {
  return GET(`${BUSINESS_BOOKING_API_URL}${FETCH_ALLOCATION_MASTERDATA}`);
};
export const deleteAllocationConfig = async (body) => {
  return POST(`${BUSINESS_BOOKING_API_URL}${DELETE_ALLOCATION_CONFIG}`, body);
};

export const addAllocationConfig = async (body) => {
  return POST(`${BUSINESS_BOOKING_API_URL}${ADD_ALLOCATION_CONFIG}`, body);
};

export const reorderAllocationConfig = async (body) => {
  return POST(`${BUSINESS_BOOKING_API_URL}${REORDER_ALLOCATION_CONFIG}`, body);
};

export const fetchAllocationConfigMasterdata = async (params) => {
  return GET(
    `${BUSINESS_BOOKING_API_URL}${FETCH_ALLOCATION_CONFIG_MASTERDATA}`,
    params,
  );
};

export const fetchAllocationData = async (params) => {
  return GET(`${BUSINESS_BOOKING_API_URL}${FETCH_ALLOCATION_DATA}`, params);
};

export const updateAllocationData = async (body) => {
  return POST(`${BUSINESS_BOOKING_API_URL}${UPDATE_ALLOCATION_DATA}`, body);
};

export const fetchAllocationRules = async (params) => {
  return GET(`${BUSINESS_BOOKING_API_URL}${FETCH_ALLOCATION_RULES}`, params);
};

export const deleteAllocationRules = async (body) => {
  return POST(`${BUSINESS_BOOKING_API_URL}${DELETE_ALLOCATION_RULES}`, body);
};

export const createAllocationRules = async (body) => {
  return POST(`${BUSINESS_BOOKING_API_URL}${ADD_ALLOCATION_RULES}`, body);
};

export const getAllocationSampleUrl = async (body) => {
  const headers = await getAuthenticationHeaders();
  return (
    `${BUSINESS_BOOKING_API_URL}/pricedata/getCSV?chargeId=${body.chargeId}` +
    `&metadataType=${body.metadataType}` +
    `&header-access-token=${headers['access-token']}` +
    `&header-user-id=${headers['user-id']}` +
    `&header-organisation-id=${headers['organisation-id']}`
  );
};

export const getUpdateHeaders = (params) => {
  return GET(`${BUSINESS_BOOKING_API_URL}${FETCH_UPDATE_HEADERS}`, params);
};

export const getDownloadRequests = async (params) => {
  return GET(
    `${BUSINESS_BOOKING_API_URL}${FETCH_GME_DOWNLOAD_REQUESTS}`,
    params,
  );
};

export const courierTrackingFetch = async (body) => {
  return POST(`${LTL_BASE_URL}${COURIER_TRACKING_FETCH}`, body);
};

export const courierTrackingAddSingle = async (body) => {
  return POST(`${LTL_BASE_URL}${COURIER_TRACKING_ADD}`, body);
};

export const courierTrackingDeleteSingle = async (body) => {
  return POST(`${LTL_BASE_URL}${COURIER_TRACKING_DELETE}`, body);
};

export const courierTrackingUpdateSingle = async (body) => {
  return POST(`${LTL_BASE_URL}${COURIER_TRACKING_UPDATE}`, body);
};

export const courierTrackingAddBulk = async (body) => {
  return POST(`${LTL_BASE_URL}${COURIER_TRACKING_ADD_BULK}`, body);
};

export const courierTrackingAddMappingGroup = async (body) => {
  return POST(`${LTL_BASE_URL}${COURIER_TRACKING_ADD_MAPPING_GROUP}`, body);
};

export const courierTrackingEditMappingGroup = async (body) => {
  return POST(`${LTL_BASE_URL}${COURIER_TRACKING_EDIT_MAPPING_GROUP}`, body);
};

export const courierTrackingDeleteMappingGroup = async (body) => {
  return POST(`${LTL_BASE_URL}${COURIER_TRACKING_DELETE_MAPPING_GROUP}`, body);
};

export const courierTrackingUpdateSettings = async (body) => {
  return POST(`${LTL_BASE_URL}${COURIER_TRACKING_UPDATE_SETTINGS}`, body);
};
export const downloadAllocationRules = async (params) => {
  return POST(`${BUSINESS_BOOKING_API_URL}${DOWNLOAD_ALLOCATION_DATA}`, params);
};

export const downloadAllocationRulesSync = async (params) => {
  return GET(
    `${BUSINESS_BOOKING_API_URL}${DOWNLOAD_ALLOCATION_DATA_SYNC}`,
    params,
    'blob',
  );
};

export const disableRule = async (body) => {
  return POST(`${BUSINESS_BOOKING_API_URL}${DISABLE_ALLOCATION_RULES}`, body);
};

export const editRule = async (body) => {
  return POST(`${BUSINESS_BOOKING_API_URL}${EDIT_ALLOCATION_RULE}`, body);
};

// allocationEngine - v3

export const getAllCourierAccount = async (body) => {
  return GET(`${LTL_BASE_URL}${FETCH_COURIER_PARTNER_ACCOUNT}`, body);
};

export const getMetaDataForAllocationEngine = async (body) => {
  return GET(
    `${BUSINESS_BOOKING_API_URL}${GET_META_DATA_FOR_ALLOCATION_ENGINE}`,
    body,
  );
};

export const getTreeForAllocationEngine = async (body) => {
  return GET(
    `${BUSINESS_BOOKING_API_URL}${GET_TREE_FOR_ALLOCATION_ENGINE}`,
    body,
  );
};

export const getSuggestionForAllocationEngine = async (body) => {
  let headers: any = await getAuthenticationHeaders();
  headers = {
    ...headers,
    'project-name': 'logistics',
  };
  return POST(`${QUERYBUILDER_CONFIG.BACKENDURL}`, body, headers);
};

export const postTreeForAllocationEngine = async (body) => {
  return POST(
    `${BUSINESS_BOOKING_API_URL}${POST_TREE_FOR_ALLOCATION_ENGINE}`,
    body,
  );
};

export const getDistinctPriceData = async (body) => {
  return GET(
    `${BUSINESS_BOOKING_API_URL}${DISTINCT_PRICE_DATA_FOR_ALLOCATION}`,
    body,
  );
};

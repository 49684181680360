import Icon from '@ant-design/icons';
import * as React from 'react';
const PinLocationSVG = ({ color,index,fillColor, ...props }: any) => (
  // tslint:disable-next-line: max-line-length
  <div>
    <div style={{position:'absolute', zIndex:1, top:'5px', right:index > 10 ?'9px':'12px', color:fillColor==='#FFF'?color: '#000'}}>{index}</div>
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="40" viewBox="0 0 40 57" fill="none">
  <g filter="url(#filter0_d_1663_2537)">
    <mask id="path-1-inside-1_1663_2537" fill="white">
      <path fillRule="evenodd" clipRule="evenodd" d="M20 33.9693C28.3707 33.4531 35 26.5007 35 18C35 9.16344 27.8366 2 19 2C10.1634 2 3 9.16344 3 18C3 26.5007 9.62927 33.4531 18 33.9693V50C18 50.5523 18.4477 51 19 51C19.5523 51 20 50.5523 20 50V33.9693Z"/>
    </mask>
    <path fillRule="evenodd" clipRule="evenodd" d="M20 33.9693C28.3707 33.4531 35 26.5007 35 18C35 9.16344 27.8366 2 19 2C10.1634 2 3 9.16344 3 18C3 26.5007 9.62927 33.4531 18 33.9693V50C18 50.5523 18.4477 51 19 51C19.5523 51 20 50.5523 20 50V33.9693Z" fill={fillColor}/>
    <path d="M20 33.9693L19.8769 31.973L18 32.0888V33.9693H20ZM18 33.9693H20V32.0888L18.1231 31.973L18 33.9693ZM33 18C33 25.4374 27.1994 31.5215 19.8769 31.973L20.1231 35.9655C29.542 35.3846 37 27.564 37 18H33ZM19 4C26.732 4 33 10.268 33 18H37C37 8.05887 28.9411 0 19 0V4ZM5 18C5 10.268 11.268 4 19 4V0C9.05887 0 1 8.05887 1 18H5ZM18.1231 31.973C10.8006 31.5215 5 25.4374 5 18H1C1 27.564 8.45797 35.3846 17.8769 35.9655L18.1231 31.973ZM20 50V33.9693H16V50H20ZM19 49C19.5523 49 20 49.4477 20 50H16C16 51.6569 17.3431 53 19 53V49ZM18 50C18 49.4477 18.4477 49 19 49V53C20.6569 53 22 51.6569 22 50H18ZM18 33.9693V50H22V33.9693H18Z" fill={color} mask="url(#path-1-inside-1_1663_2537)"/>
  </g>
  <defs>
    <filter id="filter0_d_1663_2537" x="0" y="0" width="40" height="57" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="1" dy="2"/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1663_2537"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1663_2537" result="shape"/>
    </filter>
  </defs>
</svg>
</div>
);
export const PinLocation = (props) => (
  <Icon component={() => <PinLocationSVG {...props} />} {...props} />
);

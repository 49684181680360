import { applicationType } from '../globals';
import { getViewControl } from '../utils/genericConfig/utils';
import { getSortByDateFilterMapping } from '../components/common/utils';
import { getKeyArrayFromObject } from '../utils/utils';
const pagination = {
  numberOfRecords: 'pagination.perPageCount',
  pageNumber: 'pagination.currentPageNumber',
  lastSortByKey:'pagination.lastSortByKey',
  nextOrPrev: 'pagination.nextOrPrev',
  lastSortedColumnValue: {
    path: 'pagination',
    fn : (value) => {
      return getLastSortedColumnValue(value);
    },
  },
  lastRowId: {
    path: 'pagination',
    fn : (value) => {
      return getLastRowId(value);
    },
  },
};

const sort = {
  sortBy:  {
    path: 'sort.sortBy',
    fn : (value) => {
      return getSortByDateFilterMapping[value] ? getSortByDateFilterMapping[value] : value;
    },
  },
  descendingOrder: 'sort.descendingOrder',
};

const globalFilters = {
  timeFilters: [{
    fromDateString: 'globalAppliedFilters.startDate',
    toDateString: 'globalAppliedFilters.endDate',
    columnName: 'globalAppliedFilters.dateFilterType',
  }],
  hubIdList : (iteratee) => {
    return getHubIdList(iteratee);
  },
};
const bucketFilters = {
  statusList: {
    path: 'appliedFilters.status',
    fn : (value) => {
      return getKeyArrayFromObject(value);
    },
  },
};

const getLastSortedColumnValue = (pagination) => {
  switch (pagination.nextOrPrev) {
    case 'prev':
      return pagination.lastSortedColumnValuePrev;
    case 'next':
      return pagination.lastSortedColumnValueNext;
    default:
      return null;
  }
};

const getLastRowId = (pagination) => {
  switch (pagination.nextOrPrev) {
    case 'prev':
      return pagination.lastItemIdPrev;
    case 'next':
      return pagination.lastItemIdNext;
    default:
      return null;
  }
};

const getHubIdList = (iteratee) => {
  return iteratee.viewType === 'domestic' ?
  [] : iteratee.viewType === 'crm' ?
  iteratee.globalAppliedFilters.hubId || [] : [iteratee.currHub.id];
};

const getVehicle = (iteratee) => {
  return iteratee.appliedFilters.vehicle_number?[iteratee.appliedFilters.vehicle_number]:[];
};

const requestModelTMS = {
  bucket : {
    path : 'bucket',
    fn : value => getBucketApiValue(value),
  },

  subBucket : 'subBucket',
  ...pagination,
  ...sort,
  ...globalFilters,
  ...bucketFilters,
};
const getBucketApiValue = (bucket) => {
  if (bucket === 'crm_default') {
    return undefined;
  }
  return bucket;
};
const requestAttendanceModelTMS = {
  ...pagination,
  ...globalFilters,
  vehicleNumberList : (iteratee) => {
    return getVehicle(iteratee);
  },
};

const responseModelTMS = {
  isNextPresent: 'is_next_present',
  pageNumber: 'page_number',
  lastItemIdNext: 'last_row_id_next',
  lastItemIdPrev: 'last_row_id_prev',
  lastSortByKey: 'last_sort_by_key',
  lastSortedColumnValueNext: 'last_sorted_column_value_next',
  lastSortedColumnValuePrev: 'last_sorted_column_value_prev',
  page_data: 'page_data',
};

const responseModelTMSAttendance = {
  isNextPresent: 'is_next_present',
  pageNumber: 'page_number',
  perPageCount:'number_of_records',
  page_data: 'page_data',
};

const requestModelSummaryMetricsTMS = {
  ...globalFilters,
  bucketIds : (iteratee) => {
    return getViewControl(iteratee.viewType).buckets;
  },
};
const responseModelTMSBags = {
  isNextPresent: 'is_next_present',
  lastItemIdNext: 'last_bag_id_next',
  lastItemIdPrev: 'last_bag_id_prev',
  lastSortByKey: 'last_sort_by_key',
  lastSortedColumnValueNext: 'last_sorted_column_value_next',
  lastSortedColumnValuePrev: 'last_sorted_column_value_prev',
  page_data: 'page_data',
};

const bagPagination = {
  result_per_page: 'pagination.perPageCount',
  next_or_prev: 'pagination.nextOrPrev',
  last_sorted_column_value: {
    path: 'pagination',
    fn : (value) => {
      return getLastSortedColumnValue(value);
    },
  },
  last_bag_id: {
    path: 'pagination',
    fn : (value) => {
      return getLastRowId(value);
    },
  },
};
const getSearchObject = (inp, param) => {
  if (param === inp.appliedFilters?.searchType) {
    return inp.appliedFilters?.bagNumber || undefined;
  }
  return undefined;
};
const requestModelTMSBag = {
  ...bagPagination,
  from_date: 'globalAppliedFilters.startDate',
  to_date:  'globalAppliedFilters.endDate',
  time_filter: 'globalAppliedFilters.dateFilterType',
  hub_ids:  (iteratee) => {
    return getHubIdList(iteratee);
  },
  reference_number : (iteratee) => {
    return getSearchObject(iteratee, 'reference_number');
  },
  courier_partner_reference_number : (iteratee) => {
    return getSearchObject(iteratee, 'courier_partner_reference_number');
  },
  courier_account_list: 'appliedFilters.courier',
  is_handed_over_3pl: 'appliedFilters.isHandedOver',
  exception_ids: {
    path : 'appliedFilters.exceptions',
    fn : (value) => {
      return value ? value.map(x => x.key) : [];
    },
  },
  bag_type_list: {
    path : 'appliedFilters.bag_type',
    fn : (value) => {
      return value ? value.map(x => x.key) : [];
    },
  },
  status_list: {
    path: 'appliedFilters.status',
    fn : (value) => {
      return getKeyArrayFromObject(value);
    },
  },
};

const requestModelTMSSetup = {
  result_per_page: 'pagination.perPageCount',
  page_number: 'pagination.currentPageNumber',
  descending_order: 'sort.descendingOrder',
  route_id_list: {
    path: 'globalAppliedFilters.route',
    fn : (value) => {
      return value && value.key ? [value.key] : [];
    },
  },
  hub_id_list: {
    path: 'globalAppliedFilters.currHubSetup',
    fn : (value) => {
      return value && value.id ? [value.id] : [];
    },
  },
  is_active: {
    path: 'globalAppliedFilters.is_active',
    fn: (value) => {
      if (value === 'false') {
        return false;
      }
      return true;
    },
  },
};

const requestModelTMSSetupVehicleDocuments = {
  resultPerPage: 'pagination.perPageCount',
  pageNumber: 'pagination.currentPageNumber',
  vehicleNumber: 'globalAppliedFilters.vehicle_number',
  hubId: {
    path: 'globalAppliedFilters.currHubSetup',
    fn : (value) => {
      return value && value.id ? value.id : '';
    },
  },
};

const responseModelTMSSetupVehicleDocuments = {
  isNextPresent: 'pagination_result.is_next_present',
  page_data: 'page_data',
};

const requestOverviewSchema = {
  hubIdList : 'currHub',
  chartId: 'chart',
  timeFilters: [
    {
      fromDateString: 'globalAppliedFilters.startDate',
      columnName: 'globalAppliedFilters.dateFilterType',
      toDateString: 'globalAppliedFilters.endDate',
    },
  ],
};
const requestModelSetupException = {
  result_per_page: 'pagination.perPageCount',
  page_number: 'pagination.currentPageNumber',
  descending_order: 'sort.descendingOrder',
  exception_code: 'appliedFilters.exceptionCode',
  active: {
    path: 'appliedFilters.showDisabled',
    fn : (value) => {
      return value ? false : true;
    },
  },
};

const getHubIdListSetup = (value) => {
  return value && value.id ? [value.id] : [];
};

const requestModelTMSSetupBagging = {
  ...requestModelTMSSetup,
  hub_id_list: undefined,
  route_id_list: undefined,
  origin_hub_id_list: {
    path: 'globalAppliedFilters.currHubSetup',
    fn : (value) => {
      return getHubIdListSetup(value);
    },
  },
  destination_hub_id_list: {
    path: 'globalAppliedFilters.currDestinationHub',
    fn : (value) => {
      return getHubIdListSetup(value);
    },
  },
  consignment_destination_hub_id_list: {
    path: 'globalAppliedFilters.currBaggingConsignmentDestinationHub',
    fn : (value) => {
      return getHubIdListSetup(value);
    },
  },
  is_active: {
    path: 'globalAppliedFilters.is_active',
    fn: (value) => {
      if (value === 'false') {
        return false;
      }
      return true;
    },
  },
};

const requestModelTMSSetupGate = {
  result_per_page: 'pagination.perPageCount',
  page_number: 'pagination.currentPageNumber',
  descending_order: 'sort.descendingOrder',
  hub_id_list: {
    path: 'globalAppliedFilters.currHubSetup',
    fn : (value) => {
      return value && value.id ? [value.id] : [];
    },
  },
  gate_search_string: 'globalAppliedFilters.currGateCode',
  is_active: {
    path: 'globalAppliedFilters.is_active',
    fn: (value) => {
      if (value === 'false') {
        return false;
      }
      return true;
    },
  },
};
const requestModelTMSSetupDock = {
  result_per_page: 'pagination.perPageCount',
  page_number: 'pagination.currentPageNumber',
  descending_order: 'sort.descendingOrder',
  hub_id_list: {
    path: 'globalAppliedFilters.currHubSetup',
    fn : (value) => {
      return value && value.id ? [value.id] : [];
    },
  },
  docking_station_search_string: 'globalAppliedFilters.currDockCode',
  is_active: {
    path: 'globalAppliedFilters.is_active',
    fn: (value) => {
      if (value === 'false') {
        return false;
      }
      return true;
    },
  },
};
const responseModelEWBThresholdMaster = {
  isNextPresent: 'isNextPresent',
  page_data: 'data',
};
export {
  requestModelTMS,
  responseModelTMS,
  requestModelSummaryMetricsTMS,
  requestModelTMSSetup,
  requestModelTMSBag,
  responseModelTMSBags,
  requestOverviewSchema,
  requestModelSetupException,
  requestModelTMSSetupBagging,
  requestModelTMSSetupGate,
  requestModelTMSSetupDock,
  requestAttendanceModelTMS,
  responseModelTMSAttendance,
  requestModelTMSSetupVehicleDocuments,
  responseModelTMSSetupVehicleDocuments,
  responseModelEWBThresholdMaster,
};

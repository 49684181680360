import Icon from '@ant-design/icons';
import * as React from 'react';
const VechileSVG = ({ width = '8', height = '8', ...props }: any) => (
  // tslint:disable-next-line: max-line-length
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M17.9983 23.1429C17.3158 23.1429 16.6613 22.8718 16.1788 22.3892C15.6962 21.9067 15.4251 21.2522 15.4251 20.5697C15.4251 19.8873 15.6962 19.2328 16.1788 18.7502C16.6613 18.2677 17.3158 17.9966 17.9983 17.9966C18.6807 17.9966 19.3352 18.2677 19.8177 18.7502C20.3003 19.2328 20.5714 19.8873 20.5714 20.5697C20.5714 21.2522 20.3003 21.9067 19.8177 22.3892C19.3352 22.8718 18.6807 23.1429 17.9983 23.1429ZM5.99825 23.1429C5.31581 23.1429 4.66132 22.8718 4.17877 22.3892C3.69621 21.9067 3.42511 21.2522 3.42511 20.5697C3.42511 19.8873 3.69621 19.2328 4.17877 18.7502C4.66132 18.2677 5.31581 17.9966 5.99825 17.9966C6.68069 17.9966 7.33518 18.2677 7.81774 18.7502C8.3003 19.2328 8.5714 19.8873 8.5714 20.5697C8.5714 21.2522 8.3003 21.9067 7.81774 22.3892C7.33518 22.8718 6.68069 23.1429 5.99825 23.1429Z" stroke="black" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M3.42855 20.5715H0.857117V17.1429C0.857117 16.2336 1.21834 15.3615 1.86132 14.7186C2.5043 14.0756 3.37637 13.7144 4.28569 13.7144H7.71426C8.62357 13.7144 9.49564 14.0756 10.1386 14.7186C10.7816 15.3615 11.1428 16.2336 11.1428 17.1429V18.0001C11.1428 18.6821 11.4137 19.3361 11.896 19.8183C12.3782 20.3006 13.0323 20.5715 13.7143 20.5715H15.4285H8.5714M20.5714 20.5715H21.4285C22.3748 20.5715 23.1634 19.7966 22.9851 18.8675C22.4177 15.9052 20.3417 13.7144 17.1428 13.7144H16.2857" stroke="black" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M13.7142 6H16.2857V18.6497" stroke="black" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M19.7143 7.71436H18C17.5453 7.71436 17.1093 7.89497 16.7878 8.21646C16.4663 8.53795 16.2857 8.97398 16.2857 9.42864C16.2857 9.8833 16.4663 10.3193 16.7878 10.6408C17.1093 10.9623 17.5453 11.1429 18 11.1429H19.7143V7.71436Z" stroke="black" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M2.5714 13.7143H6.85712C7.31177 13.7143 7.74781 13.5337 8.0693 13.2122C8.39079 12.8907 8.5714 12.4547 8.5714 12V7.71429C8.5714 7.25963 8.39079 6.82359 8.0693 6.5021C7.74781 6.18061 7.31177 6 6.85712 6H2.5714C2.11675 6 1.68071 6.18061 1.35922 6.5021C1.03773 6.82359 0.857117 7.25963 0.857117 7.71429V12C0.857117 12.4547 1.03773 12.8907 1.35922 13.2122C1.68071 13.5337 2.11675 13.7143 2.5714 13.7143Z" stroke="black" strokeWidth="1.6" strokeLinecap="round"/>
</svg>
);
export const VechileIcon = (props) => (
  <Icon component={() => <VechileSVG {...props} />} {...props} />
);

import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../../../theming/jssTypes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as moment from 'moment';
import {
  Button,
  Form,
  Input,
  DatePicker,
  TimePicker,
  Switch,
  Slider,
} from 'antd';
import { FilterTwoTone } from '@ant-design/icons';
import { applyBucketFilters } from '../../../../actions/genericConsignmentActions';
import {
  defaultTokenSeperators,
  sanitiseObject,
  isInvalidFilter,
} from './utils';
import TagsInput from '../../../common/TagsInput';
import Select from '../../../common/Select';
import FilterPopup from './FilterPopup';
import MultiSelectSearchFilter from './MultiSelectSearchFilter';
import classNames from 'classnames';
import { trackGaEvents } from 'src/utils/googleAnalyticsHelper';
import { INPUT_FILTER, gaType } from 'src/utils/gaConstants';
import { withTranslation } from 'react-i18next';
const Option = Select.Option;
const styles = (theme: ThemeType): CssType => ({
  mainDiv: {
    backgroundColor: theme.colors.surfaceBg,
    marginRight: '8px',
    marginLeft: '8px',
  },
  actionButtons: {
    margin: '0px',
  },
  multipleInput: {
    minWidth: '105px',
    // height: 'auto !important',
    maxWidth: '100% !important',
    '& .ant-select-selector': {
      height: 'auto !important',
    },
    '& .ant-select-selection-item': {
      height: '19px !important',
    },
  },
  multipleInputSelected: {
    '& .ant-select-selector': {
      backgroundColor: `${theme.colors.filterSelectionColor} !important`,
      borderColor: `${theme.colors.filterSelectionColor} !important`,
    },
    '& .ant-select-selection-overflow': {
      flexWrap: 'none !important',
    },
    '& .ant-select-clear': {
      backgroundColor: `${theme.colors.filterSelectionColor} !important`,
    },
  },
  dateTimeSelected: {
    backgroundColor: theme.colors.filterSelectionColor,
    borderColor: theme.colors.filterSelectionColor,
    '& .ant-picker-clear': {
      backgroundColor: theme.colors.filterSelectionColor,
    },
  },
  formContainer: {
    // borderBottom: `0.5px solid ${theme.colors.primaryDividers}`,
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    gap: '7px',
    '& .ant-form-item': {
      maxWidth: '100%',
    },
    '& label': {
      fontSize: '12px',
    },
    '& .ant-form-item-explain': {
      fontSize: '12px',
      width: '150px',
    },
    '& .ant-btn': {
      padding: '2px 4px',
      fontSize: '12px',
      '&:hover': {
        backgroundColor: theme.colors.tertiaryColor,
      },
    },
    '& .ant-btn-link': {
      fontSize: theme.sizes.bodyText,
      '&:hover': {
        backgroundColor: `${theme.colors.selectionOnWhiteBg} !important`,
      },
    },
    '& .ant-form-inline .ant-form-item .ant-btn': {
      '&:hover': {
        backgroundColor: theme.colors.HoverOnWhiteBg,
      },
    },
    '& .ant-select, .ant-input, .ant-picker, .ant-picker-range, .ant-input-search-button':
      {
        fontSize: '12px',
        height: '24px',
      },
    '& .ant-select': {
      width: '150px !important',
      overflow: 'scroll',
      scrollbarWidth: 'none !important',
    },
    '& .ant-input': {
      minWidth: '60px',
    },
    '& .ant-picker, .ant-picker-range': {
      width: '215px',
    },
    '& .ant-select-selector': {
      height: '24px',
      overflow: 'auto',
    },
  },
  multiSelectFilter: {
    marginLeft: '0px',
  },
  singleInput: {
    width: '105px',
  },
  singleInputSelected: {
    borderColor: theme.colors.filterSelectionColor,
    backgroundColor: theme.colors.filterSelectionColor,
    '& .ant-input': {
      backgroundColor: theme.colors.filterSelectionColor,
    },
  },
  filterSelect: {
    borderColor: theme.colors.filterSelectionColor,
    backgroundColor: theme.colors.filterSelectionColor,
    borderRadius: '4px',
  },
  nonSelectFilters: {
    padding: '2px 5px',
  },
});

const FilterBar = (props: any) => {
  const {
    classes,
    filtersToShow,
    selectedFilters,
    viewType,
    bucket,
    applyBucketFilters,
    hubId,
    hideActions,
    fullColumnList,
    columnsToShow,
    t,
  } = props;
  const [isMoreFilterVisible, setMoreFilterVisible] = React.useState(false);
  const [form] = Form.useForm();

  const getDefaultValues = () => {
    const defaultValues = {};
    filtersToShow.forEach((ele) => {
      defaultValues[ele.id] = ele.default;
    });
    return defaultValues;
  };

  React.useEffect(() => {
    form.setFieldsValue(selectedFilters);
  }, [selectedFilters]);

  const getRule = (validationKey, validationObj) => {
    switch (validationKey) {
      case 'min':
        return { min: validationObj.value, message: validationObj.message };
      case 'max':
        return { max: validationObj.value, message: validationObj.message };
      case 'regex':
        return {
          pattern: new RegExp(validationObj.value),
          message: validationObj.message,
        };
      case 'type':
        return { type: validationObj.value, message: validationObj.message };
      default:
        return undefined;
    }
  };

  const getRules = (filter) => {
    let validationsObj = filter.validation;
    const rules: any = [];
    if (
      validationsObj instanceof Object &&
      Object.keys(validationsObj).length
    ) {
      if (validationsObj.input_length instanceof Object) {
        validationsObj = {
          ...validationsObj,
          ...validationsObj.input_length,
        };
        delete validationsObj['input_length'];
      }
      const validationKeys = Object.keys(validationsObj);
      validationKeys.forEach((ele) => {
        const rule = getRule(ele, validationsObj[ele]);
        rule && rules.push(rule);
      });
      return rules;
    }
    return [];
  };

  const generateOptionsFromList = (list) => {
    const optionsList: any = [];
    if (list) {
      list.forEach((element) => {
        optionsList.push(
          <Option key={element.value} value={element.value}>
            {element.label}
          </Option>,
        );
      });
    }
    return optionsList;
  };

  const renderMultipleInput = (filter) => {
    let tokenSeparators = new Set([
      ...defaultTokenSeperators,
      ...(filter.tokenSeparators || []),
    ]);

    if(filter.ignoreDefaultSeparators){
      tokenSeparators = filter.tokenSeparators || [];
    }

    return (
      <TagsInput
        notFoundContent={''}
        size="small"
        placeholder={filter.placeholder || ''}
        tokenSeparators={Array.from(tokenSeparators)}
        maxHeight={100}
        className={
          isInvalidFilter(form.getFieldValue(filter.id))
            ? classes.multipleInput
            : classNames(classes.multipleInputSelected, classes.multipleInput)
        }
      />
    );
  };

  const renderSingleInput = (filter) => {
    return (
      <Input
        placeholder={filter.placeholder || ''}
        size="small"
        onPressEnter={() =>
          applyValidFilters({ [filter.id]: form.getFieldValue(filter.id) })
        }
        allowClear
        className={
          isInvalidFilter(form.getFieldValue(filter.id))
            ? classes.singleInput
            : classNames(classes.singleInputSelected, classes.singleInput)
        }
      />
    );
  };

  const renderMultiSelectSearch = (filter) => {
    const isSingleSelect =
      filter.mode !== 'multiple' || filter.type === 'radio';
    return (
      <MultiSelectSearchFilter
        filter={filter}
        viewType={viewType}
        bucket={bucket}
        hubId={hubId}
        useMultiSelect
        filterLoc="outside"
        isSingleSelect={isSingleSelect}
      />
    );
  };

  const getShowTime = (showTime, timeFormat) => {
    return showTime ? { format: timeFormat || 'HH:mm:ss' } : false;
  };

  const renderDate = (filter) => {
    const dateClass = isInvalidFilter(form.getFieldValue(filter.id))
      ? undefined
      : classes.dateTimeSelected;
    if (filter.mode === 'range') {
      return (
        <DatePicker.RangePicker
          format={filter.date_format || 'YYYY-MM-DD'}
          size="small"
          ranges={{
            Today: [moment(), moment()],
            'Last 7 days': [moment().subtract(6, 'day'), moment()],
            'Last 30 days': [moment().subtract(29, 'day'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
          }}
          showTime={getShowTime(filter.show_time, filter.time_format)}
          className={dateClass}
        />
      );
    }
    return (
      <DatePicker
        size="small"
        format={filter.date_format || 'YYYY-MM-DD'}
        showTime={getShowTime(filter.show_time, filter.time_format)}
        className={dateClass}
      />
    );
  };

  const renderSlider = (filter) => {
    return (
      <Slider
        style={{ width: '100px' }}
        range
        defaultValue={[
          (filter.defaultMinValue as number) ?? 0,
          (filter.defaultMaxValue as number) ?? 0,
        ]}
        min={filter.minValue || 0}
        max={filter.maxValue || 1}
        onAfterChange={(val) => {
          form.setFieldsValue({ [filter.id]: val });
          applyValidFilters(form.getFieldsValue());
        }}
      />
    );
  };

  const renderTime = (filter) => {
    const timeClass = isInvalidFilter(form.getFieldValue(filter.id))
      ? undefined
      : classes.dateTimeSelected;
    if (filter.mode === 'range') {
      return (
        <TimePicker.RangePicker
          format={filter.time_format || 'HH:mm:ss'}
          size="small"
          className={timeClass}
        />
      );
    }
    return (
      <TimePicker
        format={filter.time_format || 'HH:mm:ss'}
        size="small"
        className={timeClass}
      />
    );
  };

  const renderFilter = (filter) => {
    if (typeof filter.type !== 'string') {
      return null;
    }

    switch (filter.type.toLowerCase()) {
      case 'input':
        return filter.mode === 'multiple'
          ? renderMultipleInput(filter)
          : renderSingleInput(filter);
      case 'date':
        return renderDate(filter);
      case 'time':
        return renderTime(filter);
      case 'select':
      case 'search':
      case 'radio':
      case 'checkbox':
        return renderMultiSelectSearch(filter);
      case 'slider_range':
        return renderSlider(filter);
      default:
        return null;
    }
  };

  const handleMoreFilter = () => {
    setMoreFilterVisible(!isMoreFilterVisible);
  };

  const onReset = () => {
    form.resetFields();
    applyBucketFilters(sanitiseObject(getDefaultValues()));
  };

  const applyValidFilters = (values) => {
    applyBucketFilters(sanitiseObject({ ...selectedFilters, ...values }));
  };

  const applyFilters = (values, allValues) => {
    // Ignore slider values, apply filters on 'onAfterChange' method
    // Related issue: https://github.com/ant-design/ant-design/issues/14119
    if (values.dotFilter) {
      return;
    }
    trackGaEvents({actionType: INPUT_FILTER}, {values, bucket, filtersToShow});
    const fieldsChanged = Object.keys(values);
    const validChanges = fieldsChanged.filter((ele) => {
      const fieldObj = filtersToShow.filter((item) => item.id === ele)?.[0];
      return !(fieldObj?.type === 'input' && fieldObj?.mode !== 'multiple');
    });
    validChanges?.length && applyValidFilters(values);
  };

  const isMultiSelect = (filterType) => {
    return ['select', 'search', 'radio', 'checkbox'].includes(filterType);
  };

  return (
    <div className={classes.mainDiv}>
      <Form
        onValuesChange={applyFilters}
        className={classes.formContainer}
        form={form}
        initialValues={getDefaultValues()}
        layout="inline"
      >
        {filtersToShow.map((filter) => {
          return (
            <Form.Item
              noStyle
              className={
                isMultiSelect(filter.type)
                  ? undefined
                  : isInvalidFilter(form.getFieldValue(filter.id))
                  ? classes.nonSelectFilters
                  : classNames(classes.filterSelect, classes.nonSelectFilters)
              }
              label={isMultiSelect(filter.type) ? undefined : filter.label}
              name={filter.id}
              key={filter.id}
              rules={getRules(filter)}
            >
              {renderFilter(filter)}
            </Form.Item>
          );
        })}
        <Form.Item className={classes.actionButtons} style={{ margin: '0px' }}>
          <Button type="link" onClick={handleMoreFilter} className={classes.extraButtons}>
            <FilterTwoTone />
            {t('more_filters')}
          </Button>
          <Button
            type="link"
            onClick={onReset}
            danger
            style={{ marginRight: '5px' }}
            className={classes.extraButtons}
          >
            {t('reset_all')}
          </Button>
        </Form.Item>
      </Form>
      {isMoreFilterVisible && (
        <div style={{ width: '100%', position: 'relative' }}>
          <FilterPopup
            onFilterClose={handleMoreFilter}
            viewType={viewType}
            bucket={bucket}
            hubId={hubId}
            hideActions={hideActions}
            fullColumnList={fullColumnList}
            columnsToShow={columnsToShow}
          />
        </div>
      )}
    </div>
  );
};

const FilterBarStyled = withStyles(styles, { injectTheme: true })(FilterBar);

const mapStateToProps = (
  { genericConsignmentReducer, masterData },
  ownProps,
) => {
  const { viewType, bucket, t } = ownProps;
  const genericReducer = genericConsignmentReducer[viewType];
  const appliedFilters =
    genericConsignmentReducer[viewType].buckets[bucket].appliedFilters;
  const filtersMetadata = Array.isArray(masterData.ops_filter_metadata)
    ? masterData.ops_filter_metadata
    : [];
  const existingFilters = Array.isArray(
    masterData.ops_current_filter_list_by_bucket?.[bucket],
  )
    ? masterData.ops_current_filter_list_by_bucket[bucket]
    : [];
  const outsideFilters = existingFilters.filter(
    (ele) => ele.show_on_main_dashboard,
  );

  const showFilters =  outsideFilters.map((ele) => {
    return filtersMetadata.filter((item) => item.id === ele.id)[0];
  });

  if(showFilters.length > 0) {
    showFilters.forEach((i) => {
      i.label = t(i.id);
      i.placeholder = t(i.id);
    });
  }

  return {
    selectedFilters: appliedFilters,
    existingFilters,
    filtersToShow: showFilters,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { viewType, bucket } = ownProps;
  return bindActionCreators(
    {
      applyBucketFilters: applyBucketFilters(viewType)(bucket),
    },
    dispatch,
  );
};

export default withTranslation('translation')(connect(mapStateToProps, mapDispatchToProps)(FilterBarStyled));

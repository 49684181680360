import withStyles from 'react-jss';
import { CssType,
  ThemeType} from '../../../../theming/jssTypes';
import { bindActionCreators } from 'redux';
import * as moment from 'moment';
import { connect } from 'react-redux';
import * as React from 'react';
import { ExclamationCircleFilled, CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Modal, message, Button, Table, Select, DatePicker, Checkbox } from 'antd';
import ExcelUpload from '../../../common/ExcelUpload';
import { SizeMe } from 'react-sizeme';
import { downloadFileData } from '../../../../utils/utils';
import { getRetailTripManagerRoute, getAutoAllocateRequestsPageRoute } from '../../../../routing/utils';
import { createRoute, downloadPlanningSampleCSV, downloadSampleCSV } from '../../../../api/retailDashboard';
import { withRouter } from 'react-router';
import { generateTimeSlotsOptionsFromList } from '../../../../utils/retailTimeslotsOptions';
import { getViewBuckets }from '../../../../utils/genericConfig/utils';
import CustomTripCreation from '../dashboard/CustomTripCreationModal';
import { createSecondaryTrips } from '../../../../api/trips';
import { length } from '../tracking/strandedVehicleWorker';
import { validateInScannedConsignments } from '@api/dashboard';
import * as lodash from 'lodash';
import ScanErrorModal from '@components/common/ScanErrorModal';

const styles = (theme: ThemeType): CssType => ({
  tableMain: {
    '& .ant-table-thead > tr > th.ant-table-column-has-filters .ant-table-column-sorters': {
      paddingRight: '34px',
    },
    '& .ant-table-thead > tr > th .ant-table-column-sorters': {
      paddingRight: '8px',
    },
  },
  uploadDirection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    margin: '16px',
  },
  customHeaderLayout: {
    color: theme.errorColor,
    fontSize: '14px',
    marginLeft: '16px',
  },
  row: {
    display: 'flex',
  },
  uploadCol: {
    fontSize: '15px',
    padding: '16px',
    fontWeight: 'bold',
  },
  tableLayout: {
    marginLeft: '16px',
    marginRight: '16px',
    overflow: 'auto',
  },
  footerLayout: {
    margin: '16px',
    paddingBottom: '15px',
  },
  downloadButton : {
    float: 'left',
    color: `${theme.primaryColor} !important`,
    background: 'transparent !important',
    boxShadow: 'none !important',
    border: 'none !important',
    fontSize: '14px',
  },
  selectStyle: {
    width: '100%',
    minWidth: '200px',
  },
  selectContainer: {
    marginBottom: '10px',
  },
  piecesDetailContainer: {
    display: 'flex',
    gap: '1rem',
    margin: '1rem 0',
    fontSize: '0.8rem',
  },
});
class MultiShipmentUpload extends React.PureComponent<any, any>{
  state: any = {
    files: [],
    isFileTypeCorrect: true,
    shipmentDataList: [],
    filteredInfo: null,
    sortedInfo: null,
    scrollY: 0,
    failures:[],
    toBeUploaded:[],
    tasksToBeUploaded: [],
    isProcessed: false,
    confirmLoading: false,
    startTime: '',
    endTime: '',
    prettyName: '',
    tripStartTime: '',
    piecesList: {
      completePieces: [],
      missingPieces: [],
      partialPieces: [],
      partialCN: [],
    },
    showErrorModal: false,
    validationLoader: false,
    isFTL: false,
  };

  componentDidMount() {
    const { files, isFileTypeCorrect } = this.props;
    this.setState({ files, isFileTypeCorrect });
  }

  getValidConsignmentList = async (list) => {
    const { piecesList } = this.state;
    const { selectedHub } = this.props;
    const completePieces = piecesList.completePieces.map((item) => {
      if (item) return item.reference_number;
    });

    const partialPieces = piecesList.partialPieces.map((item) => {
      if (item) return item.reference_number;
    });

    const res = await validateInScannedConsignments({
      hub_id: selectedHub?.id,
      consignments: [...completePieces, ...partialPieces],

    });

    const missingPieces = res.data?.missing_consignments;

    if (missingPieces?.length) {
      this.setState({
        piecesList: {
          ...piecesList,
          missingPieces,
        },
      });
      return false;
    }
    return true;
  };

  handleCreateRouteSubmit = async() => {
    const { viewType, bucket, action, dateFilter, selectedHub, isAsync,
            showTripStartTimeForOptimisation, showTripStartTime,
            enablePieceScanning } = this.props;
    const { tripStartTime, prettyName, startTime, endTime, toBeUploaded,
            tasksToBeUploaded, isFTL } = this.state;
    const commonParams = {
      hubId: selectedHub.id,
      routeOptimizationTimeSlots : {
        pretty_name: prettyName,
        start_time: startTime,
        end_time: endTime,
      },
      is_ftl: isFTL,
    };
    const defaultStartTime = tripStartTime ? Date.parse(tripStartTime) : Date.now();
    let actionConfig : any = {};
    if (action) {
      const bucketConfig = getViewBuckets(viewType)[bucket];
      actionConfig = bucketConfig.actionsApiCalls ?
                     bucketConfig.actionsApiCalls[action] : {};
    }
    this.setState({
      confirmLoading: true,
    });
    if (enablePieceScanning) {
      const isValid = await this.getValidConsignmentList(toBeUploaded);
      if (!isValid) {
        this.setState({
          confirmLoading: false,
          showErrorModal: true,
        });
        return;
      }
    }
    let response;
    let body = action === 'create_secondary_trips' ? {
      tripReferenceNumberList: toBeUploaded,
      fromDate: dateFilter.start,
      toDate: dateFilter.end,
      dateField: dateFilter.dateFilterType,
      endPointType: 'selected',
      ...commonParams,
    } : {
      // consignmentCapacity,
      consignments : this.state.toBeUploaded,
      ...commonParams,
      scheduledDate : dateFilter.dateFilterType === 'scheduled_at' ?
      { fromDateString: dateFilter.start , toDateString: dateFilter.end } :undefined,
      ...(showTripStartTimeForOptimisation &&
        showTripStartTime && { defaultStartTime }),
    };
    if(this.props.tripIsConsolidatedPlanningType)
    {
      const extraParams = {
        taskReferenceNumberList : tasksToBeUploaded,
        planningType : viewType,
      };
      body = {
        ...body,
        ...extraParams,
      };
    }
    if(action === 'create_secondary_trips'){
      response = actionConfig?.createSecondaryTrips ?
        await actionConfig.createSecondaryTrips(body, isAsync) :
        await createSecondaryTrips(body, isAsync);
    } else {
      response = actionConfig && actionConfig.bulkCreateRoute ?
        await actionConfig.bulkCreateRoute(body, this.props.isAsync) :
        await createRoute(body, this.props.isAsync);
    }
    if (response.isSuccess) {
      if (!this.props.isAsync) {
        message.success('Trips Created');
        downloadFileData(response.data, 'consignments');
        this.props.history.push(getRetailTripManagerRoute(), {
          activeBucket:'created',
        });
      } else {
        message.success('Auto allocation request created');
        this.props.history.push(getAutoAllocateRequestsPageRoute());
      }
      this.props.handleModalClose(true);
    } else if (response?.failures?.length) {
      this.setState({
        failures : response.failures,
      });
      message.error(response.errorMessage);
    } else {
      message.error(response.errorMessage);
      this.props.handleModalClose(false);
    }
    this.setState({
      confirmLoading: false,
    });
  };
  handleSampleFileDownload = async() => {
    let response;
    if(this.props.tripIsConsolidatedPlanningType)
    {
      const params = {
        planning_type : 'consolidated_planning',
      };
      response = await downloadPlanningSampleCSV(params);
    }
    else
    {
      response = await downloadSampleCSV();
    }
    if (response.isSuccess) {
      downloadFileData(response.data, 'sample', '.xls');
    }
  };
  handleModalClose = () => {
    // this.props.resetBulkUpload();
    this.props.handleModalClose(false);
  };
  handleFtlTripCheck = (e) => {
    this.setState({ isFTL: e.target.checked });
  };
  handleParsedData = async (data) => {
    // const allReferenceNumbers = this.props.consignments.map((x:any) =>
    // x.reference_number.toString());
    // const allReferenceNumbersSet = new Set(allReferenceNumbers);
    const { action, enablePieceScanning, selectedHub } = this.props;
    const { piecesList } = this.state;
    const failures:any = [];
    const toBeUploaded:any = [];
    const tasksToBeUploaded:any = [];
    const fieldName = action === 'create_secondary_trips' ? 'tripReferenceNumber' :
      'referenceNumber';
    if(this.props.tripIsConsolidatedPlanningType)
    {
      data.forEach((x) => {
        const type = x['taskType']?.toString()?.toUpperCase();
        const value = x[fieldName]?.toString();
        tasksToBeUploaded.push(value);
      });
    }
    else {
      data.forEach((x) => {
        toBeUploaded.push(x[fieldName].toString());
      });
    }
    if (enablePieceScanning) {
      this.setState({
        validationLoader: true,
      });

      const res = await validateInScannedConsignments({
        hub_id: selectedHub?.id,
        consignments: toBeUploaded,
      });

      if (!res.isSuccess) {
        this.setState({
          validationLoader: false,
        });
        message.error(res.errorMessage);
        return;
      }

      const validConsignments = res.data?.valid_consignments || [];
      const partialConsignments = res.data?.partial_scanned_consignments || [];

      const uniqueValidConsignments = lodash
        .uniqBy(validConsignments, 'parent_reference_number')
        .map((item: any) => item.parent_reference_number);
      const uniqueCompletePieces = lodash.uniqBy(
        validConsignments,
        'reference_number',
      );
      const uniquePartialPieces = lodash.uniqBy(
        partialConsignments,
        'reference_number',
      );
      const uniquePartialCN = lodash.uniqBy(
        partialConsignments,
        'parent_reference_number',
      );

      this.setState({
        toBeUploaded: uniqueValidConsignments,
        piecesList: {
          ...piecesList,
          completePieces: uniqueCompletePieces,
          partialPieces: uniquePartialPieces,
          partialCN: uniquePartialCN,
        },
        validationLoader: false,
      });
      return;
    }
    this.setState({ failures, toBeUploaded, tasksToBeUploaded, isProcessed:true });
  };
  onTimeSlotChange = (val) => {
    const timeSlot = val.split('_');
    if (timeSlot.length && timeSlot.length > 2) {
      this.setState({
        startTime: timeSlot[0],
        endTime: timeSlot[1],
        prettyName: timeSlot[2],
      });
    }
  };
  disabledDate = (current) => {
    return current && current <= moment().subtract(1, 'days');
  };
  render() {
    const { classes, theme, isVisible, timeSlots, action, isAsync, onCustomTripModalClose,
      selectedHub, filters, viewType, bucket, showTripStartTimeForOptimisation,
      showTripStartTime, tripIsConsolidatedPlanningType, enablePieceScanning,
      tableData, manualFreightType,
    } = this.props;
    const { toBeUploaded, tasksToBeUploaded, piecesList, showErrorModal, isFTL } = this.state;
    const columns = [
      {
        title:'Consignments',
        dataIndex : 'reference_number',
      },
      {
        title: 'Reason',
        dataIndex: 'reason',
      },
    ];
    const tripFailureColumns = [
      {
        title: 'Trip #',
        dataIndex : 'trip_reference_number',
      },
      {
        title: 'Reason',
        dataIndex: 'reason',
      },
    ];
    let defaultMapping = { referenceNumber:  'CN#'};
    if(this.props.tripIsConsolidatedPlanningType)
    {
      const otherMappings = { taskType : 'type' };
      defaultMapping = {
        ...defaultMapping,
        ...otherMappings,
      };
    }
    const tripMapping = { tripReferenceNumber: 'Trip No' };
    const type = tripIsConsolidatedPlanningType? 'task' : 'consignment';
    const selectionType = action === 'create_secondary_trips' ? 'trip' : type;
    const calculateTasks = tasksToBeUploaded ? tasksToBeUploaded.length : 0;
    const calculateConsignments = toBeUploaded ? toBeUploaded.length : 0;
    const countCheck = tripIsConsolidatedPlanningType ? (calculateTasks + calculateConsignments) :
                       calculateConsignments;
    const uploadLayout = <div>
      <ExcelUpload
        mapping = {action === 'create_secondary_trips' ? tripMapping : defaultMapping}
        headerText={'Upload a csv file to upload multiple shipments.'}
        onDataParsed={this.handleParsedData}
        sampleApi = {action === 'create_secondary_trips' ? undefined :
          this.handleSampleFileDownload}
        sampleLink = {action === 'create_secondary_trips' ?
        'https://shipsy-public-assets.s3-us-west-2.amazonaws.com/retail/sample-download/' +
        'sample_optimise_secondary_trips.xlsx' : undefined}
      />
    </div>;
    const { failures } = this.state;
    const shipmentErrorLayout = (
      failures.length ?
      <div>
        <div className={classes.row}>
          <div className={classes.uploadCol} style={{ color: theme.errorColor }}>
            <ExclamationCircleFilled />
            <span style={{ paddingLeft: '15px' }}>
              {failures.length} errors were found</span>
          </div>
        </div>
          <div className={classes.tableLayout}>
            <SizeMe monitorHeight>
              { ({ size }) => {
                return (<Table
                  bordered
                  pagination={{
                    simple: true,
                    hideOnSinglePage: true,
                    position: ['topRight'],
                    pageSize: 7,
                  }}
                  columns={action === 'create_secondary_trips' ? tripFailureColumns : columns}
                  dataSource={failures}
                  className={classes.tableMain}
                  locale={{ emptyText: 'No errors found' }}
                  size="middle"
                ></Table>);
              }}
            </SizeMe>
          </div>
      </div> :
      <div>
      {action === 'create_custom_trip' ?
      <CustomTripCreation
        isAsync={isAsync}
        consignments={toBeUploaded}
        tasks={tasksToBeUploaded}
        isVisible={isVisible}
        tripIsConsolidatedPlanningType={tripIsConsolidatedPlanningType}
        tripCreationType="upload"
        handleModalClose={onCustomTripModalClose}
        hubId={selectedHub.id}
        filters={filters}
        action={action}
        viewType={viewType}
        bucket={bucket}
        allConsignments={tableData} /> :
      (countCheck ?
      <React.Fragment>
       <p style={{ margin:'20px' }}>{countCheck} {selectionType}
       {countCheck > 1 ? 's are ' : ' is '}
       selected for route optimisation. Continue ?
       </p>
       <div className={classes.selectContainer}>
          <span>CN Time Slot</span>
          <Select
            allowClear
            className={classes.selectStyle}
            placeholder="Select Time Slot"
            onChange={this.onTimeSlotChange}
            suffixIcon={<CalendarOutlined />}
          >
            {generateTimeSlotsOptionsFromList(timeSlots)}
          </Select>
        </div>
        {showTripStartTimeForOptimisation && showTripStartTime ? (
          <div className={classes.selectContainer}>
            <span>Trip Start Time</span>
            <DatePicker
              className={classes.selectStyle}
              placeholder="Select Start Date"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              disabledDate={this.disabledDate}
              onChange={(value) => this.setState({ tripStartTime: value })}
            />
          </div>
          ) : null}
      </React.Fragment>
       :
        <p style={{ margin:'20px' }}>{`No ${selectionType}s to upload`}</p>
      )}
      </div>
      );

      if (showErrorModal) {
        return (
          <ScanErrorModal
            isVisible={showErrorModal}
            data={piecesList.missingPieces}
            onModalClose={() => this.setState({
              showErrorModal: false,
              })
            }
          />
        );
      }

    return (<Modal
      maskClosable={false}
      centered
      onCancel={ this.handleModalClose }
      visible={isVisible}
      footer={failures.length === 0 && (this.state.toBeUploaded.length > 0 ||
          this.state.tasksToBeUploaded.length > 0) ?
        [
          <Button disabled = {this.state.confirmLoading}
          onClick={() => this.props.handleModalClose(false)}>No</Button>,
          <Button type="primary"
            onClick={this.handleCreateRouteSubmit} loading={this.state.confirmLoading}>Yes</Button>]
            : null
      }
      zIndex={1}
      destroyOnClose={true}
      width={330}
      title={'Bulk Upload'}
      >
        <>
        {(action === 'create_custom_trip' && manualFreightType)? (
          <Checkbox onChange={this.handleFtlTripCheck}>
            Is FTL trip
          </Checkbox>
        ) : null}
        {enablePieceScanning && (
          <div className={classes.piecesDetailContainer}>
            <div>
              Parent CN Selected:{' '}
              <b>
                {toBeUploaded.length} / {toBeUploaded.length + piecesList.partialCN.length}
              </b>
            </div>
            <div>
              Pieces Scanned:{' '}
              <b>
                {piecesList.partialPieces.length +
                  piecesList.completePieces.length}
              </b>
            </div>
          </div>
        )}
        {
          !this.state.isProcessed ? uploadLayout : shipmentErrorLayout
        }
      </>
    </Modal>);
  }
}

const mapStateToProps = ({ masterData }, ownProps) => {
  const { viewType } = ownProps;
  return {
    showTripStartTimeForOptimisation:
      masterData?.ops_dashboard_config?.parts_to_show?.etaConfig
        ?.input_start_time_for_optimisation || false,
    enablePieceScanning:
      masterData.ops_dashboard_config?.parts_to_show?.flm_trip_config
        ?.enable_piece_level_scanning,
    manualFreightType:
        masterData.ops_dashboard_config?.parts_to_show?.flm_trip_config
        ?.enable_manual_freight_type || false,
  };
};

const MultiShipmentUploadStyled: any = withStyles(styles, {
  injectTheme: true,
})(MultiShipmentUpload);
const MultiShipmentUploadWithRouter = withRouter(
  MultiShipmentUploadStyled,
) as React.ComponentType<any>;
export default connect(mapStateToProps)(MultiShipmentUploadWithRouter);

import * as React from 'react';
import withStyles from 'react-jss';
import { CssType,
  ThemeType} from '../../theming/jssTypes';
import SearchTagsInput from './SearchSelectTagsInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { searchHubWithrealFilter, searchHubDataWithPermission } from '../../api/dashboard';
import { Input, Select, message } from 'antd';
import {
  setCurrHub,
} from '../../actions/cachedDataActions';
import { getSelectStyleForSelectWithInput } from '../../theming/cssSnippets/commonSnippets';
import { trackGaEvents } from 'src/utils/googleAnalyticsHelper';
import { HUB_SEARCH, gaType } from 'src/utils/gaConstants';
import { withTranslation } from 'react-i18next';

const Option = Select.Option;
const styles = (theme: ThemeType): CssType => ({
  selectStyleHubType : {
    ...getSelectStyleForSelectWithInput({height:'28px', theme}),
    '& .ant-select-selector': {
      borderTopRightRadius: '0 !important',
      borderBottomRightRadius: '0 !important',
      backgroundColor: `${theme.colors.textOnDarkBg}1A !important`,
      color: theme.colors.textOnDarkBg
    },
    '& .ant-select-arrow': {
      color: theme.colors.textOnDarkBg,
    },
  },
  selectStyleDark: {
    minWidth: 160,
    maxWidth: 180,
    fontFamily: theme.fonts.fontFamily,
    fontSize: theme.sizes.bodyText,
    '& .ant-select-selector': {
      border: `1px solid ${theme.colors.borderColor}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '28px !important',
      borderTopRightRadius: '0 !important',
      borderBottomRightRadius: '0 !important',
      backgroundColor: `${theme.colors.textOnDarkBg}1A !important`,
      color: theme.colors.textOnDarkBg,
    },
    '& .ant-select-arrow': {
      color: theme.colors.textOnDarkBg,
    },
  },
  selectStyle: {
    minWidth: 160,
    maxWidth: 180,
    fontFamily: theme.fonts.fontFamily,
    fontSize: theme.sizes.bodyText,
    '& .ant-select-selector': {
      border: `1px solid ${theme.colors.borderColor}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '28px !important',
      borderTopRightRadius: '0 !important',
      borderBottomRightRadius: '0 !important',
      backgroundColor: `${theme.colors.textOnDarkBg}1A !important`,
    },
    '& .ant-select-arrow': {
      color: theme.colors.textOnDarkBg,
    },
  },
  dropdownMenuClass: {
    backgroundColor: theme.colors.globalHeader,
    color: theme.colors.textOnDarkBg,
    '& .ant-select-item-option': {
      backgroundColor: theme.colors.globalHeader,
      color: theme.colors.textOnDarkBg,
    },
    '& .ant-select-item-option:hover': {
      backgroundColor: `${theme.colors.hyperlink}4D !important`,
    },
    '& .ant-select-item-empty': {
      backgroundColor: theme.colors.globalHeader,
      color: theme.colors.textOnDarkBg,
      fontWeight: 'normal',
      fontSize: theme.sizes.bodyText,
      fontFamily: theme.fonts.fontFamily,
    },
    '& .ant-select-item-option-content': {
      fontWeight: 'normal',
      fontSize: theme.sizes.bodyText,
      fontFamily: theme.fonts.fontFamily,
    }
  },
  lightDropdown: {
  },
});


class HubSearch extends React.PureComponent<any, any> {

  minSearchLength = this.props.minSearchLength || 2;

  state:any = {
    searchedHubs:[],
    tempHubType : this.props.defaultHubType ? this.props.defaultHubType : this.props.hubType,
    selectedHub : null,
  };

  componentDidMount = () => {
    this.setState({
      searchedHubs : this.props.opsHub.extra_hubs ? [] :
                      this.props.opsHub.hub_list.map((hub: any) => ({
                        hub, key: hub.id, label: `${hub.name}, ${hub.code}`,
                      })),
    });
    if (this.props.dontUpdateHub) {
      this.props.onChange && this.props.onChange(this.props.value || this.props.currHub);
      this.setState({ selectedHub: this.props.value || this.props.currHub });
    }
  };

  handleSearchHubChange = (values, options) => {
    const selectedHub = this.state.searchedHubs.filter(x => x.key === values.key)[0];
    trackGaEvents({actionType: HUB_SEARCH}, {values});
    if(!this.props.dontUpdateHub){
      this.props.setCurrHub({ currHub: selectedHub.hub });
    }else{
      this.props.onChange && this.props.onChange(selectedHub.hub);
      this.setState({
        selectedHub : selectedHub.hub,
      });
    }
    this.props.onHubChange && this.props.onHubChange(selectedHub.hub);
    this.props.opsHub.extra_hubs ? this.setState({ searchedHubs: [] }) : null;
  };

  handleSearchedHubsearch = async (queryString: string) => {
    const { tempHubType } = this.state;
    if (!queryString.trim()) {
      this.setState({ searchedhubs: [] });
      return;
    }
    if (queryString.length >= this.minSearchLength) {
      let response;
      if (this.props.withPermission) {
        response =  await searchHubDataWithPermission(queryString);
      } else if (this.props.searchHubData) {
        response = await this.props.searchHubData(queryString);
      } else if (this.props.withType) {
        response = await searchHubWithrealFilter(true)(queryString, tempHubType);
      } else if (this.props.withoutRealFilter) {
        response = await searchHubWithrealFilter(false)(queryString);
      } else {
        response = await searchHubWithrealFilter(true)(queryString);
      }
      if (!response.isSuccess) {
        message.error(response.errorMessage);
        return;
      }
      let searchedHubs = response.data;
      if (this.props.withType) {
        searchedHubs = searchedHubs.filter(x => x.hub_type === this.state.tempHubType);
      }
      searchedHubs = searchedHubs ? searchedHubs.map((hub: any) => ({
        hub, key: hub.id, label: `${hub.name}, ${hub.code}`,
      })) : [],
        this.setState({ searchedHubs });
    }
  };

  generateOptionsFromKeyValue = (optionsList: any) => {
    const x:any = [];
    if (optionsList) {
      optionsList.forEach((ele:any) => {
        x.push(<Option key={ele.key} value = {ele.key}>{ele.val}</Option>);
      });
    }
    return x;
  };
  generateOptionsFromList = (list) => {
    const optionsList: any = [];
    if (list) {
      list.forEach((ele) => {
        optionsList.push(<Option key={ele.id} value={ele.key}>{`${ele.name},${ele.code}`}</Option>);
      });
    }
    return optionsList;
  };
  onHubTypeChange = (value) => {
    this.setState({  tempHubType : value });
    const hubsearchElem: any = document.getElementById('hubSearch');
    if (hubsearchElem) {
      hubsearchElem.click();
    }
  };
  static defaultProps: { propsDropdownClassName: string }; //setting default props as any and making it optional
  render() {
    const { classes, opsHub, currHub, withType, hubType, searchText, placeholder,
      defaultHubType, hubTypeOptions, searchStyle, dontUpdateHub,
      disabled, t, propsDropdownClassName, ...otherProps} = this.props;
    const { selectedHub } = this.state;

    return (
      <React.Fragment>
        {withType ? <Select
            {...otherProps}
            onChange={this.onHubTypeChange}
            className={classes.selectStyleHubType}
            defaultValue={defaultHubType ? defaultHubType : hubType}
            disabled={disabled}
            dropdownClassName={propsDropdownClassName ? classes.lightDropdown : classes.dropdownMenuClass}
            >
              {this.generateOptionsFromKeyValue(hubTypeOptions)}
          </Select> : null}
        {opsHub.extra_hubs ?  <SearchTagsInput
            {...otherProps}
            id = "hubSearch"
            inputRef = {this.props.inputRef}
            labelInValue
            size={'default'}
            allowClear={false}
            classname={classes.searchBar}
            dropdownClassName={propsDropdownClassName ? classes.lightDropdown : classes.dropdownMenuClass}
            placeholder={placeholder ? placeholder : 'Select Hub'}
            value={!selectedHub && currHub ? {
              key: currHub.id,
              value: currHub.id,
              label: `${currHub.name}, ${currHub.code}`,
            }: selectedHub? {
              key: selectedHub.id,
              label: `${selectedHub.name}, ${selectedHub.code}`,
              value: selectedHub.id,
            }: {}}
            thresholdLength={this.minSearchLength}
            searchText={searchText ? searchText : t('search_for_hubs')}
            options={this.state.searchedHubs}
            onChange={this.handleSearchHubChange}
            searchFunction={this.handleSearchedHubsearch}
            className={searchStyle || (propsDropdownClassName ? classes.selectStyle : classes.selectStyleDark)}
            disabled={disabled}
          /> :

          <Select
            {...otherProps}
            id = "hubSearch"
            showSearch
            labelInValue
            placeholder={placeholder ? placeholder : 'Select Hub'}
            optionFilterProp="children"
            className={propsDropdownClassName ? classes.selectStyle : classes.selectStyleDark}
            dropdownClassName={propsDropdownClassName ? classes.lightDropdown : classes.dropdownMenuClass}
            value={!selectedHub ? {
              key: currHub.id,
              label: `${currHub.name}, ${currHub.code}`,
            } : {
              key: selectedHub.id,
              label: `${selectedHub.name}, ${selectedHub.code}`,
            }}
            onChange={this.handleSearchHubChange}
            filterOption={(input, option: any) =>
               option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={disabled}
          >
            {this.generateOptionsFromList(withType ?
            this.props.opsHub.hub_list.filter(hub => hub.hub_type === this.state.tempHubType) :
            this.props.opsHub.hub_list,
            )}
          </Select>
        }
      </React.Fragment>
    );
  }
}

const HubSearchStyled = withStyles(styles, { injectTheme: true })(HubSearch);

const mapStateToProps = ({ masterData, cachedData }, ownProps) => {
  return {
    hubTypeOptions : ownProps.hubTypeOptions ? ownProps.hubTypeOptions :  [
            { key:'dc', val: ownProps.t('planning_hub') },
            { key:'upcountry', val: ownProps.t('upcountry_hub')  },
    ],
    currHub: cachedData.currHub,
    hubType : cachedData.hubType,
    opsHub: masterData.ops_hub,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setCurrHub,
  }, dispatch);
};

HubSearch.defaultProps = {
  propsDropdownClassName: undefined, // Setting the default value of propsDropdownClassName to undefined
};

export default  withTranslation('translation')
      (connect(mapStateToProps, mapDispatchToProps)(HubSearchStyled));


const PlanningHubSVG = () => {
  return `<svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <!-- Generator: Sketch 58 (84663) - https://sketch.com -->
  <title>Primar_Hub</title>
  <desc>Created with Sketch.</desc>
  <defs>
      <circle id="path-1" cx="16" cy="16" r="16"></circle>
      <filter x="-22.2%" y="-22.2%" width="150.6%" height="150.6%" filterUnits="objectBoundingBox" id="filter-2">
          <feMorphology radius="0.1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
          <feOffset dx="1" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
          <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
          <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.597344882 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
      </filter>
  </defs>
  <g id="Primar_Hub" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Hub" transform="translate(4.000000, 4.000000)">
          <g id="Oval-Copy-5">
              <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
              <use stroke="#595959" stroke-width="0.2" fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-1"></use>
          </g>
      </g>
      <g id="warehouse-with-boxes" transform="translate(10.000000, 10.000000)" fill="#F7209C" fill-rule="nonzero">
          <path d="M19.8193469,5.49089796 L10.174,0.0607755102 C10.0659592,-4.08163265e-05 9.93408164,-4.08163265e-05 9.82604082,0.0607755102 L0.180653065,5.49089796 C0.0690612281,5.5537551 -1.44573343e-08,5.67191837 -1.44573343e-08,5.8 L-1.44573343e-08,18.1637551 C-1.44573343e-08,18.2227347 0.047795922,18.2705714 0.106734697,18.2705714 L2.2349796,18.2705714 C2.29391837,18.2705714 2.34171429,18.2227347 2.34171429,18.1637551 L2.34171429,7.67089796 L17.6582449,7.67089796 L17.6582449,18.1637959 C17.6582449,18.2227755 17.7060408,18.2705714 17.7649796,18.2705714 L19.8932245,18.2705714 C19.9521633,18.2705714 20,18.2227755 20,18.1637959 L20,5.79991837 C20,5.67187755 19.9309796,5.5537551 19.8193469,5.49089796 Z" id="Path"></path>
          <g id="Group" transform="translate(3.632653, 10.163265)">
              <g transform="translate(0.000000, 4.775510)" id="Path">
                  <g transform="translate(4.693878, 0.000000)">
                      <path d="M7.6715102,0.0183265306 L5.06734694,0.0183265306 C4.87146939,0.0183265306 4.71273469,0.177102041 4.71273469,0.372938776 L4.71273469,2.97718367 C4.71273469,3.17306122 4.8715102,3.33179592 5.06734694,3.33179592 L7.6715102,3.33179592 C7.86738776,3.33179592 8.02616327,3.17306122 8.02616327,2.97718367 L8.02616327,0.372938776 C8.02616327,0.177061224 7.86738776,0.0183265306 7.6715102,0.0183265306 Z"></path>
                      <path d="M2.9755102,0.0183265306 L0.371346939,0.0183265306 C0.175469388,0.0183265306 0.0166938776,0.177102041 0.0166938776,0.372938776 L0.0166938776,2.97718367 C0.0166938776,3.17306122 0.175469388,3.33179592 0.371346939,3.33179592 L2.9755102,3.33179592 C3.17138776,3.33179592 3.33012246,3.17306122 3.33012246,2.97718367 L3.33012246,0.372938776 C3.33016327,0.177061224 3.17138776,0.0183265306 2.9755102,0.0183265306 Z"></path>
                  </g>
                  <path d="M2.97342857,0.0183265306 L0.369265306,0.0183265306 C0.173428571,0.0183265306 0.0146530612,0.177102041 0.0146530612,0.372938776 L0.0146530612,2.97718367 C0.0146530612,3.17306122 0.173469388,3.33179592 0.369265306,3.33179592 L2.97342857,3.33179592 C3.16926531,3.33179592 3.32804082,3.17306122 3.32804082,2.97718367 L3.32804082,0.372938776 C3.32804082,0.177061224 3.16922449,0.0183265306 2.97342857,0.0183265306 Z"></path>
              </g>
              <g id="Path">
                  <g transform="translate(4.693878, 0.000000)">
                      <path d="M7.6715102,0.00265306122 L5.06734694,0.00265306122 C4.87146939,0.00265306122 4.71273469,0.161428571 4.71273469,0.357265306 L4.71273469,2.9615102 C4.71273469,3.15734694 4.8715102,3.31612245 5.06734694,3.31612245 L7.6715102,3.31612245 C7.86738776,3.31612245 8.02616327,3.15734694 8.02616327,2.9615102 L8.02616327,0.357265306 C8.02616327,0.161387755 7.86738776,0.00265306122 7.6715102,0.00265306122 Z"></path>
                      <path d="M2.9755102,0.00265306122 L0.371346939,0.00265306122 C0.175469388,0.00265306122 0.0166938776,0.161428571 0.0166938776,0.357265306 L0.0166938776,2.9615102 C0.0166938776,3.15734694 0.175469388,3.31612245 0.371346939,3.31612245 L2.9755102,3.31612245 C3.17138776,3.31612245 3.33012246,3.15734694 3.33012246,2.9615102 L3.33012246,0.357265306 C3.33016327,0.161387755 3.17138776,0.00265306122 2.9755102,0.00265306122 Z"></path>
                  </g>
                  <path d="M2.97342857,0.00265306122 L0.369265306,0.00265306122 C0.173428571,0.00265306122 0.0146530612,0.161428571 0.0146530612,0.357265306 L0.0146530612,2.9615102 C0.0146530612,3.15734694 0.173469388,3.31612245 0.369265306,3.31612245 L2.97342857,3.31612245 C3.16926531,3.31612245 3.32804082,3.15734694 3.32804082,2.9615102 L3.32804082,0.357265306 C3.32804082,0.161387755 3.16922449,0.00265306122 2.97342857,0.00265306122 Z"></path>
              </g>
          </g>
      </g>
  </g>
</svg>`;
};

export default PlanningHubSVG;

import { SET_MASTER_DATA, DASHBOARD_SET_COLUMNS,
  GENERIC_DASHBOARD_SET_COLUMNS, GENERIC_DASHBOARD_SET_TRIP_COLUMNS,
  GENERIC_DASHBOARD_SET_CUSTOM_FILTERS, GENERIC_DASHBOARD_SET_CURRENT_CUSTOM_FILTERS,
  GENERIC_DASHBOARD_SET_CUSTOM_ACTIONS, SET_VIEW_TYPE,
  GENERIC_DASHBOARD_SET_CUSTOM_UPDATES, SET_DISPATCH_SCREEN_FOR_HUB,
  GENERIC_DASHBOARD_SET_TASK_COLUMNS,
} from '../actions/constants';

const defaultState: any = {
  columns_to_show :[],
  full_column_list: [],
  crm_permission:[],
  ops_hub: {
    hub_list: [],
  },
  ltl_masterdata:[],
  ops_filter_list_by_bucket:[],
  ops_current_filter_list_by_bucket:[],
  ops_actions_list_by_bucket: [],
};

export default function (state: any = defaultState, action) {
  switch (action.type) {
    case SET_MASTER_DATA: {
      return {
        ...state,
        ...action.data,
        ops_current_filter_list_by_bucket: action.data.ops_filter_list_by_bucket,
        //show_enhanced_dispatch_screen: true, // TODO: remove, kept only for dev testing
      };
    }
    case SET_VIEW_TYPE: {
      return {
        ...state,
        viewType: action.data,
      };
    }
    case DASHBOARD_SET_COLUMNS: {
      return{
        ...state,
        columns_to_show:action.data,
      };
    }
    case GENERIC_DASHBOARD_SET_COLUMNS: {
      return{
        ...state,
        ops_consignment_column_list: {
          ...state.ops_consignment_column_list,
          [action.bucket]: action.data,
        },
      };
    }
    case GENERIC_DASHBOARD_SET_TRIP_COLUMNS: {
      return{
        ...state,
        ops_trip_column_list: {
          ...state.ops_trip_column_list,
          [action.bucket]: action.data,
        },
      };
    }
    case GENERIC_DASHBOARD_SET_TASK_COLUMNS: {
      return {
        ...state,
        ops_consolidated_column_list: {
          ...state.ops_consolidated_column_list,
          [action.bucket]: {
            ...state.ops_consolidated_column_list[action.bucket],
            [action.subBucket]: action.data,
          },
        },
      };
    }
    case GENERIC_DASHBOARD_SET_CUSTOM_FILTERS: {
      return{
        ...state,
        ops_filter_list_by_bucket: {
          ...state.ops_filter_list_by_bucket,
          [action.bucket]: action.data,
        },
      };
    }
    case GENERIC_DASHBOARD_SET_CURRENT_CUSTOM_FILTERS: {
      return{
        ...state,
        ops_current_filter_list_by_bucket: {
          ...state.ops_current_filter_list_by_bucket,
          [action.bucket]: action.data,
        },
      };
    }
    case GENERIC_DASHBOARD_SET_CUSTOM_ACTIONS: {
      return{
        ...state,
        ops_actions_list_by_bucket: {
          ...state.ops_actions_list_by_bucket,
          [action.bucket]: action.data,
        },
      };
    }
    case GENERIC_DASHBOARD_SET_CUSTOM_UPDATES: {
      const obj = {};
      action.buckets.forEach(bucket => {
        obj[bucket] = action.data?.updates;
      });
      return{
        ...state,
        ops_keys_list_by_bucket: {
          ...state.ops_keys_list_by_bucket,
          ...obj,
        },
      };
    }
    case SET_DISPATCH_SCREEN_FOR_HUB: {
      return {
        ...state,
        show_enhanced_dispatch_screen: action.data,
      };
    }
    default: return state;
  }
}

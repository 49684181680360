import {
  GENERIC_DASHBOARD_UPDATE_BUCKET_DATA,
GENERIC_DASHBOARD_ENABLE_BUCKET_LOADING,
GENERIC_DASHBOARD_DISABLE_BUCKET_LOADING,
GENERIC_DASHBOARD_UPDATE_GLOBAL_FILTER,
GENERIC_DASHBOARD_UPDATE_FILTER_IN_ALL_BUCKETS,
GENERIC_DASHBOARD_RESET_BUCKET_FILTER,
GENERIC_DASHBOARD_UPDATE_BUCKET_PAGINATION,
GENERIC_DASHBOARD_RESET_BUCKET_PAGINATION,
GENERIC_DASHBOARD_UPDATE_SUMMARY_METRICS_DATA,
GENERIC_DASHBOARD_UPDATE_SUMMARY_METRICS_DATA_BUCKET_SPECIFIC,
GENERIC_DASHBOARD_SET_BUCKET_FILTER,
GENERIC_DASHBOARD_APPLY_BUCKET_FILTER,
GENERIC_DASHBOARD_APPLY_OTHER_BUCKET_FILTER,
GENERIC_DASHBOARD_CANCEL_BUCKET_FILTER,
GENERIC_DASHBOARD_SET_ACTIVE_BUCKET,
GENERIC_DASHBOARD_RESET_ALL_BUCKETS_EXCEPT_FILTERS,
GENERIC_DASHBOARD_UPDATE_SUB_BUCKET_DATA,
GENERIC_DASHBOARD_SET_COLUMNS,
GENERIC_DASHBOARD_BUCKET_RELOAD,
GENERIC_DASHBOARD_UPDATE_BUCKET_TABLE_DATA,
GENERIC_DASHBOARD_SET_BUCKET_SELECTED_ROW_KEYS,
GENERIC_DASHBOARD_APPLY_BUCKET_SORT,
GENERIC_DASHBOARD_APPLY_VIEW_SORT,
GENERIC_DASHBOARD_SET_TABLE_DATA_EMPTY,
GENERIC_DASHBOARD_SET_TRIP_COLUMNS,
GENERIC_DASHBOARD_SET_CUSTOM_FILTERS, GENERIC_DASHBOARD_SET_CURRENT_CUSTOM_FILTERS,
GENERIC_DASHBOARD_SET_CUSTOM_ACTIONS,
GENERIC_DASHBOARD_SET_CUSTOM_UPDATES,
GENERIC_DASHBOARD_SET_TASK_COLUMNS,
} from './constants';
import { getAllConsignmentsData, getSummaryMetricsData, updateUserColumnList, requestDownload,
  getSubBucketData, updateUserFilters, updateUserActions,
  updateUserUpdates } from '../api/genericConsignmentView';
import { applicationType, DOMESTIC_STATUS_FETCH_POLLING_FREQUENCY } from '../globals';
import { getViewControl, getViewBuckets, getDefaultSubBuckets } from '../utils/genericConfig/utils';
import { message } from 'antd';
import { MAP_EDIT_BUCKETS, MAP_EDIT_VIEW_TYPE } from 'src/components/pages/retail/trip/TripManagerMapEdit/TripManagerMapEditUtils';
export const fetchConsignments = (viewType, disableLoader?: boolean) => bucket =>
async (dispatch, getState) => {
  if(!disableLoader) {
    dispatch({
      viewType,
      bucket,
      type: GENERIC_DASHBOARD_ENABLE_BUCKET_LOADING,
    });
  }
  const { globalAppliedFilters, buckets }
    = getState().genericConsignmentReducer[viewType];
  const masterData = getState().masterData;
  const { appliedFilters, pagination, sort } = buckets[bucket];
  const currHub = getState().cachedData.currHub;
  const bucketConfig = getViewBuckets(viewType)[bucket];
  const crmCNSearch = viewType === 'crm' && globalAppliedFilters.searchType &&
  globalAppliedFilters.searchValue?.length;
  const filtersRevamp = ['logistics_express', 'retailPlanning', 'retailUpcountry',
    'retailStore', MAP_EDIT_VIEW_TYPE].includes(viewType);
  const genericFiltersRevamp =
  ['thirdParty', 'crm', 'consolidated_planning'].includes(viewType) ||
  ['ondemand_all_orders'].includes(bucket);
  const body = {
    viewType,
    bucket,
    currHub,
    sort,
    appliedFilters,
    globalAppliedFilters,
    pagination,
    subBucket : appliedFilters.subBucket ?? getDefaultSubBuckets(viewType, masterData),
    filtersRevamp,
    genericFiltersRevamp,
    crmCNSearch,
  };
  const response = bucketConfig.fetchData ? await bucketConfig.fetchData(body) :
  await getViewControl(viewType).fetchData(body);

  if (response.isSuccess) {
    dispatch({
      viewType,
      bucket,
      type: GENERIC_DASHBOARD_UPDATE_BUCKET_DATA,
      data: response.data,
    });
  } else {
    message.error(response.errorMessage);
  }
  dispatch({
    viewType,
    bucket,
    type: GENERIC_DASHBOARD_DISABLE_BUCKET_LOADING,
  });
};

export const fetchSummaryMetrics = (viewType:any, changeAllBucketCount?:any) =>
       (showCurrentBucketCount?:boolean) => async (dispatch, getState) => {
  const masterData = getState().masterData;
  let isUserChoiceAllowed;
  const viewTypesToAllowSingleBucketCount = ['logistics_express'];
  if(viewTypesToAllowSingleBucketCount.indexOf(viewType) > -1 &&
     masterData.show_single_bucket_count_option){
        isUserChoiceAllowed = true;
  }
  const { summaryMetrics, activeBucket } = getState().genericConsignmentReducer[viewType];
  let showBucketCount;
  if(showCurrentBucketCount!== undefined) {
    showBucketCount = showCurrentBucketCount;
  }
  else {
    showBucketCount = summaryMetrics?.[activeBucket]? true: false;
  }
  const viewTypesToHideSummaryCount = ['retailPlanning', 'retailUpcountry', 'logistics_express'];
  const viewTypesToHideSummaryCountThirdParty = ['domestic', 'thirdParty'];
  if(isUserChoiceAllowed) {
    if(changeAllBucketCount) {
      dispatch({
        viewType,
        type: GENERIC_DASHBOARD_UPDATE_SUMMARY_METRICS_DATA,
        data: {},
      });
      return;
    }
    if(!showBucketCount){
    dispatch({
          viewType,
          type: GENERIC_DASHBOARD_UPDATE_SUMMARY_METRICS_DATA_BUCKET_SPECIFIC,
          bucket: activeBucket,
          data: undefined,
        });
    }
  }
  if(viewTypesToHideSummaryCountThirdParty.indexOf(viewType) > -1 &&
   masterData.do_not_fetch_consignment_bucket_count_third_party_tracking){
    return;
  }
  if(viewTypesToHideSummaryCount.indexOf(viewType) > -1 &&
      masterData.do_not_fetch_consignment_bucket_count){
    return;
  }
  if (!getViewControl(viewType).fetchSummaryMetrics) {
    return;
  }
  const globalAppliedFilters = getState().genericConsignmentReducer[viewType].globalAppliedFilters;
  const currHub = getState().cachedData.currHub;
  const callCenterViewtypes = ['calls_overview', 'my_calls'];
  let body = callCenterViewtypes.includes(viewType) ?
  {
    action_type: viewType === 'my_calls' ? 'my_calls' : undefined,
    timeFilters: [{
      fromDateString: globalAppliedFilters.startDate,
      toDateString: globalAppliedFilters.endDate,
      columnName: globalAppliedFilters.dateFilterType,
    }],
    viewType,
  } :
  {
    globalAppliedFilters,
    currHub,
    viewType,
  };
  if(isUserChoiceAllowed){
      const paramsToSend = {
        bucket: [activeBucket],
        showCurrentBucketCount: showBucketCount,
        isUserChoiceAllowed,
      };
      body = {...body,...paramsToSend};
      const response = await getViewControl(viewType).fetchSummaryMetrics(body);
      if (response.isSuccess) {
        dispatch({
          viewType,
          type: GENERIC_DASHBOARD_UPDATE_SUMMARY_METRICS_DATA_BUCKET_SPECIFIC,
          bucket: activeBucket,
          data: response.data,
        });
      }
  }
  else {
  const response = await getViewControl(viewType).fetchSummaryMetrics(body);
  if (response.isSuccess) {
    dispatch({
      viewType,
      type: GENERIC_DASHBOARD_UPDATE_SUMMARY_METRICS_DATA,
      data: response.data,
    });
  }
  }
};

export const fetchSubBucket = viewType => bucket => () => async(dispatch, getState) => {
  if (!getViewBuckets(viewType)[bucket].isSubBucket) {
    return;
  }
  const { globalAppliedFilters, buckets, activeBucket }
    = getState().genericConsignmentReducer[viewType];
  const masterData = getState().masterData;
  const { appliedFilters } = buckets[bucket];
  const currHub = getState().cachedData.currHub;
  const body = {
    viewType,
    activeBucket,
    currHub,
    appliedFilters,
    globalAppliedFilters,
    noCount: masterData.do_not_fetch_consignment_bucket_count_third_party_tracking ? true : false,
    subBucket : appliedFilters.subBucket,
  };
  const response = await getViewControl(viewType).fetchSubBucket(body);
  const data = viewType === 'consolidated_planning'? response.data : response.data.data;
  if (response.isSuccess) {
    dispatch({
      viewType,
      bucket,
      type: GENERIC_DASHBOARD_UPDATE_SUB_BUCKET_DATA,
      data,
    });
  }
};

export const createDownloadRequest = viewType => bucket =>
  (options:any = {}) => async (dispatch, getState) => {
    const { globalAppliedFilters, buckets, activeBucket }
      = getState().genericConsignmentReducer[viewType];
    const { appliedFilters, pagination, selectedRowKeys, sort } = buckets[bucket];
    const currHub = getState().cachedData.currHub;
    const viewConfig = getViewControl(viewType);
    const genericFilterViewTypes =
    ['crm', 'thirdParty', 'logistics_express'].includes(viewType) ||
    ['ondemand_all_orders'].includes(bucket);
    const body = {
      viewType,
      globalAppliedFilters,
      appliedFilters,
      selectedRowKeys,
      currHub,
      bucket : activeBucket,
      subBucket : appliedFilters.subBucket,
      requiredColumns : options.requiredColumns,
      childDownloadData: options?.downloadHus,
      genericFilterViewTypes,
    };
    const response = viewConfig.requestDownload ? await viewConfig.requestDownload(body) :
                     await requestDownload(body);
    return response;
  };

const getRTOvalue = (value) => {
  if (value) {
    if (value.includes('Forward') && value.includes('RTO')) {
      return;
    } if (value.includes('Forward')) {
      return { isRto:false };
    } if (value.includes('RTO')) {
      return { isRto : true };
    }
  }
  return;
};
const getKeyArrayFromObject = (input) => {
  if (input && Array.isArray(input) && input.length) {
    return input.map(x => x.key);
  }
  return [];
};
const getLastSortedColumnValue = (pagination) => {
  switch (pagination.nextOrPrev) {
    case 'prev':
      return pagination.lastSortedColumnValuePrev;
    case 'next':
      return pagination.lastSortedColumnValueNext;
    default:
      return null;
  }
};

const getLastRowId = (pagination) => {
  switch (pagination.nextOrPrev) {
    case 'prev':
      return pagination.lastItemIdPrev;
    case 'next':
      return pagination.lastItemIdNext;
    default:
      return null;
  }
};

export const setBucketFilters = viewType => bucket => data => async (dispatch, getState) => {
  dispatch({
    viewType,
    bucket,
    data,
    type: GENERIC_DASHBOARD_SET_BUCKET_FILTER,
  });
};
/** For Filter Popup */
export const applyBucketFilters = viewType => bucket => data => async (dispatch, getState) => {
  dispatch({
    viewType,
    bucket,
    data,
    type: GENERIC_DASHBOARD_APPLY_BUCKET_FILTER,
  });
  dispatch({
    viewType,
    bucket,
    type: GENERIC_DASHBOARD_RESET_BUCKET_PAGINATION,
  });
  dispatch(setSelectedRowKeys(viewType)(bucket)([])); // reset selected rows
  dispatch(fetchConsignments(viewType)(bucket));
  dispatch(fetchSubBucket(viewType)(bucket)());
};
/** For Sort By filter */
export const applyOtherBucketFilters = viewType => bucket => data => async (dispatch, getState) => {
  dispatch({
    viewType,
    bucket,
    data,
    type: GENERIC_DASHBOARD_APPLY_OTHER_BUCKET_FILTER,
  });
  dispatch({
    viewType,
    bucket,
    type: GENERIC_DASHBOARD_RESET_BUCKET_PAGINATION,
  });
  dispatch(fetchConsignments(viewType)(bucket));
  dispatch(fetchSubBucket(viewType)(bucket)());
};

export const applyGlobalFilters = viewType => data => async (dispatch, getState) => {
  dispatch({
    viewType,
    data,
    type: GENERIC_DASHBOARD_UPDATE_GLOBAL_FILTER,
  });
  dispatch({
    viewType,
    type: GENERIC_DASHBOARD_RESET_ALL_BUCKETS_EXCEPT_FILTERS,
  });
  dispatch(fetchSummaryMetrics(viewType,true)());
  dispatch(
    fetchConsignments(viewType)(
      getState().genericConsignmentReducer[viewType].activeBucket),
      );
  dispatch(
    fetchSubBucket(viewType)(getState().genericConsignmentReducer[viewType].activeBucket)());
};

export const applyFilterToAllBuckets = viewType => data => async (dispatch, getState) => {
  dispatch({
    viewType,
    data,
    type: GENERIC_DASHBOARD_UPDATE_FILTER_IN_ALL_BUCKETS,
  });
};

export const updateBucketPagination = viewType => bucket => pageData => async (dispatch) => {
  dispatch({
    viewType,
    bucket,
    type: GENERIC_DASHBOARD_UPDATE_BUCKET_PAGINATION,
    data: pageData,
  });
  dispatch(fetchConsignments(viewType)(bucket));
  dispatch(fetchSubBucket(viewType)(bucket)());
};

export const resetBucketPagination = viewType => bucket => () => async (dispatch) => {
  dispatch({
    viewType,
    bucket,
    type: GENERIC_DASHBOARD_RESET_BUCKET_PAGINATION,
  });
  dispatch(fetchConsignments(viewType)(bucket));
  dispatch(fetchSubBucket(viewType)(bucket)());
};

export const resetBucketFilters = viewType => bucket => () => async (dispatch) => {
  dispatch({
    viewType,
    bucket,
    type: GENERIC_DASHBOARD_RESET_BUCKET_FILTER,
  });
  dispatch(fetchConsignments(viewType)(bucket));
  dispatch(fetchSubBucket(viewType)(bucket)());
};
// No need for this - to be deprecated
export const cancelBucketFilter = viewType => bucket => () => (dispatch) => {
  // Sets value from main to temp
  dispatch({
    viewType,
    bucket,
    type:GENERIC_DASHBOARD_CANCEL_BUCKET_FILTER,
  });
};

export const setActiveBucket = viewType => (bucket, fetchData = true) => (dispatch, getState) => {
  dispatch({
    viewType,
    type:GENERIC_DASHBOARD_SET_ACTIVE_BUCKET,
    data:bucket,
  });
  const shouldUpdateBucket = fetchData &&
    getState()?.genericConsignmentReducer[viewType]?.buckets[bucket]?.shouldUpdate;
  if (shouldUpdateBucket) {
    dispatch(fetchConsignments(viewType)(bucket));
    dispatch(fetchSubBucket(viewType)(bucket)());
  }
  if (getViewControl(viewType).polling) {

    dispatch(stopPolling());
    dispatch(poller(viewType)(bucket)());
  }
  dispatch(checkForAutoRefresh(viewType)(bucket)());
};
export const checkForAutoRefresh =
viewType => bucket => (disableResetSelectedRowKeys = true) => (dispatch, getState) => {
  dispatch(stopRefresh());
  const opsUpdates = getState().masterData.ops_keys_list_by_bucket?.[bucket];
  const pollConsignments = Array.isArray(opsUpdates) &&
  opsUpdates.some(e => e.id === 'auto_refresh_duration_in_sec' && e.isActive);
  if(pollConsignments){
    const update = opsUpdates.find(e => e.id === 'auto_refresh_duration_in_sec');
    const pollingFrequnecy = update.value ? parseInt(update.value, 10) * 1000 : 10000;
    dispatch(autoRefresh(viewType)(bucket)(pollingFrequnecy, disableResetSelectedRowKeys));
  }
};

export const setColumns = viewType => bucket =>  data => async (dispatch, getState) => {
  const viewConfig = getViewControl(viewType);
  const bucketConfig = getViewBuckets(viewType)[bucket];
  const tripViewTypes = ['retailTripsPrimary', 'retailTripsSecondary'];
  const taskViewTypes = ['consolidated_planning'];

  const currentSubBucket = getState().genericConsignmentReducer[viewType].buckets[bucket]
  .appliedFilters?.subBucket;
  const defaultSubBucket = getState().genericConsignmentReducer[viewType].buckets[bucket]
  .subBuckets[0]?.id;
  const subBucket = currentSubBucket ? currentSubBucket : defaultSubBucket;

  const getRequestType = () => {
    if (tripViewTypes.includes(viewType) ||
    (viewType === MAP_EDIT_VIEW_TYPE && bucket === MAP_EDIT_BUCKETS.MAP_TRIPS)) {
      return GENERIC_DASHBOARD_SET_TRIP_COLUMNS;
    }
    if (taskViewTypes.includes(viewType)) {
      return GENERIC_DASHBOARD_SET_TASK_COLUMNS;
    }
    return GENERIC_DASHBOARD_SET_COLUMNS;
  };

  const getRequestBody = () => {
    let body = {};
    if (viewType === 'consolidated_planning') {
      body = {
        ops_consolidated_planning_column_list: data,
        sub_bucket: subBucket,
      };
    } else {
      body = { ops_consignment_column_list: data };
    }
    return {
      bucket,
      ...body,
    };
  };

   dispatch({
    data,
    viewType,
    bucket,
    subBucket,
    type: getRequestType(),
  });

  if (bucketConfig.updateUserColumnList) {
    await bucketConfig.updateUserColumnList(getRequestBody());
  } else if (viewConfig.updateUserColumnList) {
    await viewConfig.updateUserColumnList(getRequestBody());
  } else {
    await updateUserColumnList(getRequestBody());
  }
};

export const reloadBucket =
  (viewType) =>
  (bucket) =>
  (
    changeAllBucketCount?: boolean,
    disableLoader?: boolean,
    disableResetSelectedRowKeys: boolean = false,
    resetOtherBuckets: boolean = false,
  ) =>
  async (dispatch, getState) => {
    dispatch({
      viewType,
      bucket,
      resetOtherBuckets,
      type: GENERIC_DASHBOARD_BUCKET_RELOAD,
    });
    dispatch(fetchConsignments(viewType, disableLoader)(bucket));
    dispatch(fetchSummaryMetrics(viewType, changeAllBucketCount)());
    dispatch(fetchSubBucket(viewType)(bucket)());
    if(!disableResetSelectedRowKeys){
      dispatch(setSelectedRowKeys(viewType, disableResetSelectedRowKeys)(bucket)([]));
    }
  };
export const setSelectedRowKeys =
  (viewType, disableResetSelectedRowKeys = false) =>
  bucket => keys => async(dispatch, getState) => {
    if(!disableResetSelectedRowKeys){
      dispatch({
        viewType,
        bucket,
        data:keys,
        type:GENERIC_DASHBOARD_SET_BUCKET_SELECTED_ROW_KEYS,
      });
    }
};
export const applyBucketSort = viewType => bucket => data => async (dispatch, getState) => {
  dispatch({
    viewType,
    bucket,
    data,
    type: GENERIC_DASHBOARD_APPLY_BUCKET_SORT,
  });
  dispatch({
    viewType,
    bucket,
    type: GENERIC_DASHBOARD_RESET_BUCKET_PAGINATION,
  });
  dispatch(setSelectedRowKeys(viewType)(bucket)([])); // reset selected rows
  dispatch(fetchConsignments(viewType)(bucket));
  dispatch(fetchSubBucket(viewType)(bucket)());
};
export const applyGlobalSort = viewType => data => async (dispatch, getState) => {
  const { activeBucket } = getState().genericConsignmentReducer[viewType];
  dispatch({
    viewType,
    data,
    type: GENERIC_DASHBOARD_APPLY_VIEW_SORT,
  });
  dispatch({
    viewType,
    type: GENERIC_DASHBOARD_RESET_ALL_BUCKETS_EXCEPT_FILTERS,
  });
  dispatch(fetchSummaryMetrics(viewType,true)());
  dispatch(
    fetchConsignments(viewType)(
      activeBucket),
      );
  dispatch(
    fetchSubBucket(viewType)(activeBucket)());
};
export const getToBePolled = (data:any) => {
  // data should always be from redux store
  const toBePolled:any = {
  };
  if (data) {
    data.forEach((x:any, index) => {
      if (Boolean(x.status === 'tracking_pending')) {
        toBePolled[`${x.reference_number}`] = index;
      }
    });
  }
  return toBePolled;
};

let pollingAgent:any = null;

export const poller = viewType => bucket => () => (dispatch, getState) => {
  if (!pollingAgent) {
    pollingAgent = setInterval(() => {
      const toBePolled = getToBePolled(
        getState().genericConsignmentReducer[viewType].buckets[bucket].data);
      dispatch(pollLTLStatusApi(viewType)(bucket)(toBePolled));
    }, DOMESTIC_STATUS_FETCH_POLLING_FREQUENCY);
  }
};

export const stopPolling = () => (dispatch) => {
  if (pollingAgent) {
    clearInterval(pollingAgent);
    pollingAgent = undefined;
  }
};

let refreshAgent:any = null;

export const autoRefresh =
(viewType) => bucket => (pollingFrequnecy, disableResetSelectedRowKeys) => (dispatch) => {
  if (!refreshAgent) {
    refreshAgent = setInterval(() => {
      dispatch(reloadBucket(viewType)(bucket)(false, true, disableResetSelectedRowKeys));
    }, pollingFrequnecy);
  }
};

export const stopRefresh = () => (dispatch) => {
  if (refreshAgent) {
    clearInterval(refreshAgent);
    refreshAgent = undefined;
  }
};

export const pollLTLStatusApi =
  viewType => bucket => (toBePolled:any) => async(dispatch, getState) => {
    const reference_numbers = Object.keys(toBePolled);
    if (reference_numbers.length === 0) {
      return;
    }
    const response:any = await getAllConsignmentsData({ referenceNumberList:reference_numbers });
    if (response.isSuccess) {
      const data = getState().genericConsignmentReducer[viewType].buckets[bucket].data;
      if (response.isSuccess) {
        response.data.page_data && response.data.page_data.forEach((x:any) => {
          const index:any = toBePolled[x.reference_number];

          if (data && data.length > 0 && data[index].reference_number === x.reference_number) {
            data[index] = { ...data[index], ...x };
          }else {
            console.log('Not Matching');
          }
        });
        dispatch({
          bucket,
          viewType,
          data,
          type: GENERIC_DASHBOARD_UPDATE_BUCKET_TABLE_DATA,
        });
      }
    }
  };
export const setTableDataEmpty = viewType => bucket => () => (dispatch, getState) => {
  dispatch({
    viewType,
    bucket,
    type: GENERIC_DASHBOARD_ENABLE_BUCKET_LOADING,
  });
  dispatch({
    bucket,
    viewType,
    type: GENERIC_DASHBOARD_SET_TABLE_DATA_EMPTY,
  });
  dispatch({
    viewType,
    bucket,
    type: GENERIC_DASHBOARD_DISABLE_BUCKET_LOADING,
  });

};
export const setCustomFilters = viewType => bucket => data => async(dispatch) => {
  const viewConfig = getViewControl(viewType);
  dispatch({
    data,
    viewType,
    bucket,
    type: GENERIC_DASHBOARD_SET_CUSTOM_FILTERS,
  });
  const body = {
    bucket,
    ops_filter_list: data,
  };
  await viewConfig.updateUserFilters ?
    viewConfig.updateUserFilters(body) : updateUserFilters(body);
};
export const setCurrentCustomFilters = viewType => bucket => data => async(dispatch) => {
  dispatch({
    data,
    viewType,
    bucket,
    type: GENERIC_DASHBOARD_SET_CURRENT_CUSTOM_FILTERS,
  });
};
export const setCustomActions = viewType => bucket => data => async(dispatch) => {
  dispatch({
    data,
    viewType,
    bucket,
    type: GENERIC_DASHBOARD_SET_CUSTOM_ACTIONS,
  });
  const body = {
    bucket,
    ops_action_list: data,
  };
  await updateUserActions(body);
};
export const setCustomUpdates = viewType => bucket => data => async(dispatch, getState) => {
  const buckets = data.enableUpdateForAll ?
    Object.keys(getState().genericConsignmentReducer[viewType].buckets) : [bucket];
  dispatch({
    data,
    viewType,
    buckets,
    type: GENERIC_DASHBOARD_SET_CUSTOM_UPDATES,
  });
  dispatch(checkForAutoRefresh(viewType)(bucket)());
  const body = {
    bucket_list: buckets,
    ops_keys_list: data.updates,
  };
  await updateUserUpdates(body);
};

import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '../../../../theming/jssTypes';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { bindActionCreators } from 'redux';
import {
  Button,
  Form,
  Input,
  Radio,
  Checkbox,
  DatePicker,
  TimePicker,
  Slider,
} from 'antd';
import {
  setCurrentCustomFilters,
  applyBucketFilters,
} from '../../../../actions/genericConsignmentActions';
import {
  filterFormItemLayout,
  filterTailLayout,
  defaultTokenSeperators,
  sanitiseObject,
} from './utils';
import TagsInput from '../../../common/TagsInput';
import Select from '../../../common/Select';
import CustomColumnsModal from '../../dashboard/CustomColumnsModal';
import MultiSelectSearchFilter from './MultiSelectSearchFilter';
import { trackGaEvents } from 'src/utils/googleAnalyticsHelper';
import { INPUT_FILTER, gaType } from 'src/utils/gaConstants';
import { withTranslation } from 'react-i18next';
const Option = Select.Option;

const styles = (theme: ThemeType): CssType => ({
  mainDiv: {
    background: theme.colors.surfaceBg,
    position: 'absolute',
    zIndex: 10,
    width: 'inherit',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
    '& .ant-calendar-picker-input': {
      padding: 0,
    },
    '& .ant-form-item': {
      marginBottom: 10,
    },
    '& .ant-legacy-form-item': {
      marginBottom: 10,
    },
  },
  formContainer: {
    '& .ant-form-item-label': {
      textAlign: 'left',
    },
    '& .ant-select, .ant-input, .ant-picker, .ant-picker-range': {
      minWidth: '225px',
      width: 'auto',
    },
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '25px',
    '& .ant-btn-primary': {
      backgroundColor: theme.colors.primaryColor,
      color: theme.colors.textOnDarkBg,
    },
    '& .ant-btn-link': {
      color: theme.colors.hyperlink,
    },
  },
});

const FilterPopup = (props: any) => {
  const {
    classes,
    onFilterClose,
    viewType,
    bucket,
    applyBucketFilters,
    filtersToShow,
    existingFilters,
    setCurrentCustomFilters,
    selectedFilters,
    hubId,
    hideActions,
    columnsToShow,
    fullColumnList,
    t,
  } = props;
  const [searchFilterValues, setSearchFilterValues] = React.useState({});
  const [isCustomiseModalVisible, setCustomiseModalVisible] =
    React.useState(false);

  const [form] = Form.useForm();

  React.useEffect(() => {
    form.setFieldsValue(selectedFilters);
  }, [selectedFilters]);

  const onCustomModalClose = () => {
    setCustomiseModalVisible(false);
    onFilterClose();
  };

  const onFinish = (values) => {
    const appliedFilterValues = sanitiseObject({
      ...selectedFilters,
      ...values,
      ...searchFilterValues,
    });
    applyBucketFilters(appliedFilterValues);
    const appliedFilterIds = Object.keys(appliedFilterValues);
    const customFilters = existingFilters.map((ele) => {
      return {
        ...ele,
        show_on_main_dashboard: !ele.show_on_main_dashboard
          ? appliedFilterIds.includes(ele.id)
          : ele.show_on_main_dashboard,
      };
    });
    trackGaEvents({actionType: INPUT_FILTER}, {values, bucket, filtersToShow});
    setCurrentCustomFilters(customFilters);
    onFilterClose();
  };

  const generateOptionsFromList = (list) => {
    const optionsList: any = [];
    if (list) {
      list.forEach((element) => {
        optionsList.push(
          <Option key={element.value} value={element.value}>
            {element.label}
          </Option>,
        );
      });
    }
    return optionsList;
  };

  const renderMultipleInput = (filter) => {
    const tokenSeparators = new Set([
      ...defaultTokenSeperators,
      ...(filter.tokenSeparators || []),
    ]);
    return (
      <TagsInput
        notFoundContent={''}
        className={classes.selectStyle}
        placeholder={filter.placeholder || ''}
        tokenSeparators={Array.from(tokenSeparators)}
        maxHeight={100}
      />
    );
  };

  const renderSingleInput = (filter) => {
    return (
      <Input
        placeholder={filter.placeholder || ''}
        className={classes.selectStyle}
      />
    );
  };

  const renderSelect = (filter) => {
    if(filter.type === 'select' && Array.isArray(filter.options) && filter.options.length > 0) {
      filter.options.forEach((i) => {
        i.label = t(i.label);
      });
    }
    return (
      <Select
        allowClear
        showSearch
        mode={filter.mode === 'multiple' ? 'multiple' : 'single'}
        placeholder={filter.placeholder || ''}
        className={classes.selectStyle}
        optionFilterProp="children"
        filterOption={(input, option: any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {generateOptionsFromList(filter.options)}
      </Select>
    );
  };

  const handleSearchChange = (filterId, value) => {
    setSearchFilterValues({
      ...searchFilterValues,
      [filterId]: value,
    });
  };

  const renderSlider = (filter) => {
    return (
      <Slider
        style={{ width: '60%' }}
        range
        defaultValue={[
          (filter.defaultMinValue as number) ?? 0,
          (filter.defaultMaxValue as number) ?? 0,
        ]}
        min={filter.minValue || 0}
        max={filter.maxValue || 1}
      />
    );
  };

  const renderSearch = (filter) => {
    return (
      <MultiSelectSearchFilter
        filter={filter}
        viewType={viewType}
        bucket={bucket}
        hubId={hubId}
        onSearchChange={handleSearchChange}
        filterLoc="inside"
      />
    );
  };

  const renderRadio = (filter) => {
    if(Array.isArray(filter.options) && filter.options.length > 0) {
      filter.options.forEach((i) => {
        i.label = t(i.label);
      });
    }
    return <Radio.Group name={filter.id} options={filter.options} />;
  };

  const renderCheckbox = (filter) => {
    if(Array.isArray(filter.options) && filter.options.length > 0) {
      filter.options.forEach((i) => {
        i.label = t(i.label);
      });
    }
    return <Checkbox.Group name={filter.id} options={filter.options} />;
  };

  const getShowTime = (showTime, timeFormat) => {
    return showTime ? { format: timeFormat || 'HH:mm:ss' } : false;
  };

  const renderDate = (filter) => {
    if (filter.mode === 'range') {
      return (
        <DatePicker.RangePicker
          className={classes.selectStyle}
          format={filter.date_format || 'YYYY-MM-DD'}
          ranges={{
            Today: [moment(), moment()],
            'Last 7 days': [moment().subtract(6, 'day'), moment()],
            'Last 30 days': [moment().subtract(29, 'day'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
          }}
          showTime={getShowTime(filter.show_time, filter.time_format)}
        />
      );
    }
    return (
      <DatePicker
        className={classes.selectStyle}
        format={filter.date_format || 'YYYY-MM-DD'}
        showTime={getShowTime(filter.show_time, filter.time_format)}
      />
    );
  };

  const renderTime = (filter) => {
    if (filter.mode === 'range') {
      return (
        <TimePicker.RangePicker
          className={classes.selectStyle}
          format={filter.time_format || 'HH:mm:ss'}
        />
      );
    }
    return (
      <TimePicker
        format={filter.time_format || 'HH:mm:ss'}
        className={classes.selectStyle}
      />
    );
  };

  const renderFilter = (filter) => {
    if (typeof filter.type !== 'string') {
      return null;
    }

    switch (filter.type.toLowerCase()) {
      case 'input':
        return filter.mode === 'multiple'
          ? renderMultipleInput(filter)
          : renderSingleInput(filter);
      case 'select':
        return renderSelect(filter);
      case 'search':
        return renderSearch(filter);
      case 'radio':
        return renderRadio(filter);
      case 'checkbox':
        return renderCheckbox(filter);
      case 'date':
        return renderDate(filter);
      case 'time':
        return renderTime(filter);
      case 'slider_range':
        return renderSlider(filter);
      default:
        return null;
    }
  };

  const getInitialValues = () => {
    const defaultValues = {};
    filtersToShow.forEach((ele) => {
      defaultValues[ele.id] = ele.default;
    });
    return defaultValues;
  };

  const getRule = (validationKey, validationObj) => {
    switch (validationKey) {
      case 'min':
        return { min: validationObj.value, message: validationObj.message };
      case 'max':
        return { max: validationObj.value, message: validationObj.message };
      case 'regex':
        return {
          pattern: new RegExp(validationObj.value),
          message: validationObj.message,
        };
      case 'type':
        return { type: validationObj.value, message: validationObj.message };
      default:
        return undefined;
    }
  };

  const getRules = (filter) => {
    let validationsObj = filter.validation;
    const rules: any = [];
    if (
      validationsObj instanceof Object &&
      Object.keys(validationsObj).length
    ) {
      if (validationsObj.input_length instanceof Object) {
        validationsObj = {
          ...validationsObj,
          ...validationsObj.input_length,
        };
        delete validationsObj['input_length'];
      }
      const validationKeys = Object.keys(validationsObj);
      validationKeys.forEach((ele) => {
        const rule = getRule(ele, validationsObj[ele]);
        rule && rules.push(rule);
      });
      return rules;
    }
    return [];
  };

  return (
    <div className={classes.mainDiv}>
      <Form
        onFinish={onFinish}
        {...filterFormItemLayout}
        className={classes.formContainer}
        form={form}
        initialValues={getInitialValues()}
      >
        <Form.Item {...filterTailLayout}>
          <div className={classes.actionButtons}>
            <div>
              <Button
                type="primary"
                htmlType="submit"
                style={{ margin: '0px 10px' }}
              >
                {t('apply_filter')}
              </Button>
              <Button onClick={onFilterClose} className={classes.cancelButton}>
                {t('cancel')}
              </Button>
            </div>
            <div>
              <Button
                type="link"
                onClick={() => setCustomiseModalVisible(true)}
              >
                {t('customise_filters')}
              </Button>
            </div>
          </div>
        </Form.Item>
        <div style={{ maxHeight: '260px', overflow: 'auto' }}>
          {filtersToShow.map((filter) => {
            return (
              <Form.Item
                label={filter.label}
                name={filter.id}
                rules={getRules(filter)}
                colon={false}
                labelCol={{ span: 8 }}
              >
                {renderFilter(filter)}
              </Form.Item>
            );
          })}
        </div>
      </Form>
      {isCustomiseModalVisible ? (
        <CustomColumnsModal
          viewType={viewType}
          bucket={bucket}
          isVisible={isCustomiseModalVisible}
          onModalClose={onCustomModalClose}
          showFiltersAndActions
          defaultTab="filter"
          hideActions={hideActions}
          fullColumnList={fullColumnList}
          columnsToShow={columnsToShow}
        ></CustomColumnsModal>
      ) : null}
    </div>
  );
};

const FilterPopupStyled = withStyles(styles, { injectTheme: true })(
  FilterPopup,
);

const mapStateToProps = (
  { genericConsignmentReducer, masterData },
  ownProps,
) => {
  const { viewType, bucket, t } = ownProps;
  const appliedFilters =
    genericConsignmentReducer[viewType].buckets[bucket].appliedFilters;
  const filtersMetadata = Array.isArray(masterData.ops_filter_metadata)
    ? masterData.ops_filter_metadata
    : [];
  const existingFilters = Array.isArray(
    masterData.ops_current_filter_list_by_bucket?.[bucket],
  )
    ? masterData.ops_current_filter_list_by_bucket[bucket]
    : [];

  const showMoreFilters = existingFilters
  .filter((ele) => !ele.show_on_main_dashboard)
  .map((ele) => {
    return {
      ...filtersMetadata.filter((item) => item.id === ele.id)[0],
      show_on_main_dashboard: ele.show_on_main_dashboard,
    };
  });

  if(showMoreFilters.length > 0) {
    showMoreFilters.forEach((i) => {
      i.label = t(i.id);
      i.placeholder = t(i.id);
    });
  }

  return {
    selectedFilters: appliedFilters,
    existingFilters,
    filtersToShow: showMoreFilters ,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { viewType, bucket } = ownProps;
  return bindActionCreators(
    {
      applyBucketFilters: applyBucketFilters(viewType)(bucket),
      setCurrentCustomFilters: setCurrentCustomFilters(viewType)(bucket),
    },
    dispatch,
  );
};

export default withTranslation('translation')(connect(mapStateToProps, mapDispatchToProps)(FilterPopupStyled));

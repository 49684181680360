import * as React from 'react';
import withStyles from 'react-jss';
import { Modal, Table, Image } from 'antd';

import { CssType, ThemeType } from '@theming/jssTypes';
import { formatDateTime } from '@utils/utils';
import { getOrderStatusPrettyName } from '@components/pages/FoodDashboard/utils';

const styles = (theme: ThemeType): CssType => ({
  tableContainer: {
    flex: '1 1 0',
    overflow: 'auto',
    height: 300,
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
    '& .ant-table-tbody > tr > td, .ant-table-thead > tr > th': {
      padding: 9,
    },
  },
  image: {
    maxHeight: '450px',
    maxWidth: '450px',
  },
});

const TableModal = (props) => {
  const {
    columnsToShow,
    dataSource,
    isVisible,
    customGenerateDataColumns,
    useCustomGenerateDataColumns = false,
    classes,
    header,
    handleModalClose,
    showDescription = false,
    descriptionText,
    width = 900,
  } = props;
  const generateDataColumns = () => {
    const columnData: any = [
      {
        title: 'S.No.',
        key: 'index',
        render: (text, record, index) => index + 1,
        width: 20,
      },
    ];
    const indexList = columnsToShow;
    indexList.forEach((item) => {
      const itemToPush = {};
      itemToPush['title'] = item.pretty_name;
      itemToPush['dataIndex'] = item.column_id;
      itemToPush['width'] = item?.width || 50;
      itemToPush['key'] = item.column_id;

      if (item.column_id === 'delivery_image' || item.column_id === 'image') {
        itemToPush['render'] = (x, record) => {
          return x ? (
            <Image.PreviewGroup>
              <Image className={classes.image} src={x} placeholder={true} />
            </Image.PreviewGroup>
          ) : ('Not Available');
        };
      }
      if (item.column_id === 'assigned_time') {
        itemToPush['render'] = (x, record) => {
          return <span>{formatDateTime(x)}</span>;
        };
      }

      if (item.pretty_name === 'Order Status') {
        itemToPush['render'] = (x, record) => {
          return <span>{getOrderStatusPrettyName(x)}</span>;
        };
      }
      columnData.push(itemToPush);
    });
    return columnData;
  };
  return (
    <Modal
      title={header}
      width={width}
      visible={isVisible}
      footer={null}
      onCancel={() => handleModalClose(false)}
      destroyOnClose
    >
      {
        showDescription && descriptionText ?
          <div style={{ marginBottom: '10px' }}>
            {<span>{descriptionText}</span>}
          </div> : null
      }
      <div className={classes.tableContainer}>
        <Table
          locale={{ emptyText: `No ${header}` }}
          pagination={false}
          dataSource={dataSource}
          columns={useCustomGenerateDataColumns ?
            customGenerateDataColumns(columnsToShow) : generateDataColumns()}
        />
      </div>
    </Modal>
  );
};

const StyledTableModal = withStyles(styles, { injectTheme: true })(TableModal);
export default StyledTableModal;

import apiProvider from './provider';
import {
  getAuthenticationHeaders,
  getErrorMessage,
  generateQueryString,
  getErrorForBlob,
} from './utils';
import { API_BASE_URL, ROUTE_OPTIMISATION_BASE_URL } from '../globals';
import {
  GET_ALL_RETAIL_CONSIGNMENT_DATA, CREATE_ROUTE,
  DOWNLOAD_SAMPLE_CSV_RETAIL, CREATE_ROUTE_ASYNC,
   CREATE_ROUTE_ALL_ASYNC, CREATE_ROUTE_ALL,
   GET_RETAIL_METABASE_DASHBOARD_LINK,
   CREATE_ROUTE_ASYNC_ADD_TO_TRIP, CREATE_ROUTE_ADD_TO_TRIP, CREATE_ROUTE_ALL_ASYNC_ADD_TO_TRIP,
   CREATE_ROUTE_ALL_ADD_TO_TRIP, CREATE_MANUAL_TRIP, DOWNLOAD_SAMPLE_MANUAL_CSV,
   GET_VEHICLE_MAKES_BY_HUB_ID,
   UPDATE_GEOLOCATION,
} from './routes';
import { GET, POST } from './apiHandler';

export const getVehicleMakeDataForHub = async (hubId) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_VEHICLE_MAKES_BY_HUB_ID}?hub_id=${hubId}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getAllConsignmentsData = async (
  params,
) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_ALL_RETAIL_CONSIGNMENT_DATA}?${generateQueryString(params)}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const createRoute = async(body, isAsync) => {
  try {
    let response;
    if (isAsync) {
      response = await apiProvider.post(
        `${ROUTE_OPTIMISATION_BASE_URL}${CREATE_ROUTE_ASYNC}`, body,
        {
          headers: await getAuthenticationHeaders(),
        },
      );
    }else {
      response = await apiProvider.post(
          `${ROUTE_OPTIMISATION_BASE_URL}${CREATE_ROUTE}`, body,
        {
          headers: await getAuthenticationHeaders(),
          responseType:'blob',
        },
      );
    }
    return {
      fileName : response.headers['Content-Disposition'],
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    let error;
    if (isAsync) {
      error = err.response && err.response.data ? err.response.data.error : undefined;
    }else {
      error = await getErrorForBlob(err);
    }
    return {
      failures : error && error.failures,
      errorMessage : error && error.message,
      isSuccess: false,
    };
  }
};

export const createRouteAll = async(body, isAsync) => {
  try {
    let response;
    if (isAsync) {
      response = await apiProvider.post(
        `${ROUTE_OPTIMISATION_BASE_URL}${CREATE_ROUTE_ALL_ASYNC}`, body,
        {
          headers: await getAuthenticationHeaders(),
        },
      );
    }else {
      response = await apiProvider.post(
          `${ROUTE_OPTIMISATION_BASE_URL}${CREATE_ROUTE_ALL}`, body,
        {
          headers: await getAuthenticationHeaders(),
          responseType:'blob',
        },
        );
    }
    if (response.status === 'ERROR') {
      return {
        isSuccess : false,
        failures : response.failures,
      };
    }
    return {
      fileName : response.headers['Content-Disposition'],
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    let error;
    if (isAsync) {
      error = err.response && err.response.data ? err.response.data.error : undefined;
    }else {
      error = await getErrorForBlob(err);
    }
    return {
      failures : error && error.failures,
      errorMessage : error && error.message,
      isSuccess: false,
    };
  }
};

export const downloadSampleCSV = async() => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${DOWNLOAD_SAMPLE_CSV_RETAIL}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const downloadSampleManualCSV = async(params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_SAMPLE_MANUAL_CSV}`, params, 'blob');
}

export const getRetailMetabaseDashboardUrl = async(dashboardType) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_RETAIL_METABASE_DASHBOARD_LINK}?dashboard_type=${dashboardType}`,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const createRouteAddToTrip = async(body, isAsync) => {
  try {
    let response;
    if (isAsync) {
      response = await apiProvider.post(
        `${ROUTE_OPTIMISATION_BASE_URL}${CREATE_ROUTE_ASYNC_ADD_TO_TRIP}`, body,
        {
          headers: await getAuthenticationHeaders(),
        },
      );
    }else {
      response = await apiProvider.post(
          `${ROUTE_OPTIMISATION_BASE_URL}${CREATE_ROUTE_ADD_TO_TRIP}`, body,
        {
          headers: await getAuthenticationHeaders(),
          responseType:'blob',
        },
      );
    }
    return {
      fileName : response.headers['Content-Disposition'],
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    let error;
    if (isAsync) {
      error = err.response.data.error;
    }else {
      error = await getErrorForBlob(err);
    }
    return {
      failures : error.failures,
      errorMessage : error.message,
      isSuccess: false,
    };
  }
};

export const createRouteAllAddToTrip = async(body, isAsync) => {
  try {
    let response;
    if (isAsync) {
      response = await apiProvider.post(
        `${ROUTE_OPTIMISATION_BASE_URL}${CREATE_ROUTE_ALL_ASYNC_ADD_TO_TRIP}`, body,
        {
          headers: await getAuthenticationHeaders(),
        },
      );
    }else {
      response = await apiProvider.post(
          `${ROUTE_OPTIMISATION_BASE_URL}${CREATE_ROUTE_ALL_ADD_TO_TRIP}`, body,
        {
          headers: await getAuthenticationHeaders(),
          responseType:'blob',
        },
        );
    }
    if (response.status === 'ERROR') {
      return {
        isSuccess : false,
        failures : response.failures,
      };
    }
    return {
      fileName : response.headers['Content-Disposition'],
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    let error;
    if (isAsync) {
      error = err.response && err.response.data ? err.response.data.error : undefined;
    }else {
      error = await getErrorForBlob(err);
    }
    return {
      failures : error && error.failures,
      errorMessage : error && error.message,
      isSuccess: false,
    };
  }
};

export const createManualTrip = async(body, isAsync, isBlob = true) => {
  let response;
  if (isAsync) {
    response = await POST(`${API_BASE_URL}${CREATE_MANUAL_TRIP}`, body);
  } else {
    response = await POST(`${API_BASE_URL}${CREATE_MANUAL_TRIP}`, body, await getAuthenticationHeaders(),
    isBlob);
  }
  return response;
};

export const downloadPlanningSampleCSV = async(params) => {
  return GET(`${API_BASE_URL}${DOWNLOAD_SAMPLE_CSV_RETAIL}`,params);
};

export const updateGeoLocation = async(body) => {
  return POST(`${API_BASE_URL}${UPDATE_GEOLOCATION}`, body);
};

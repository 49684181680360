import { GlobalAppliedFilters } from '../components/pages/FoodDashboard/DispatchScreenPickily/types';
import {
  DISPATCH_SCREEN_ENABLE_LOADING,
  DISPATCH_SCREEN_DISABLE_LOADING,
  DISPATCH_SCREEN_UPDATE_DATA,
  DISPATCH_SCREEN_SET_ACTIVE_BUCKET,
  DISPATCH_SCREEN_SET_ACTIVE_BUCKET_FOR_RIDER,
  DISPATCH_SCREEN_UPDATE_FILTERED_ORDER_DATA,
  DISPATCH_SCREEN_SET_GLOBAL_FILTERS,
  DISPATCH_SCREEN_APPLY_GLOBAL_FILTERS,
  DISPATCH_SCREEN_RESET_GLOBAL_FILTERS,
  DISPATCH_SCREEN_ANALYTICS_UPDATE_DATA,
 } from '../actions/constants';
import {
  dispatchScreenBuckets,
  dispatchScreenBucketsPickily,
  dispatchScreenPaginationSizes,
} from '../components/pages/FoodDashboard/utils';

const getDefaultBuckets = () => {
  const buckets = {};
  const bucketsList = [...dispatchScreenBucketsPickily, ...dispatchScreenBuckets];
  bucketsList.forEach((x) => {
    buckets[x.id] = { ...defaultBucketState };
  });
  return buckets;
};
const defaultBucketState = {
  filteredOrderData : [],
  data : [],
};
const defaultState = {
  isLoading: false,
  dispatchScreenData:{
    analyticsData: [],
    orderData: { ...getDefaultBuckets() },
    riderData: [],
    activeBucket: 'in_store',
    weatherForecast: [],
    currentWeather: {},
    summaryMetrics: {},
    isNextPresent: true,
    isRiderNextPagePresent: true,
    cancelledToAcknowledge: [],
    summaryMetricsRider: {},
    doNotShowBucketCountsDispatch: false,
  },
  globalAppliedFilters: {
    searchOrderBy: 'orderNumber',
    searchValue:'',
    slotTimeRange: undefined,
    orderTimeRange: undefined,
    orderType: undefined,
    breachStatus: undefined,
    breachDuration: undefined,
    customerName: undefined,
    customerPhoneNumber: undefined,
    riderName: undefined,
    riderPhoneNumber: undefined,
    pageNumber: 1,
    pageSize: dispatchScreenPaginationSizes[0],
    sortBy: undefined,
    descendingOrder: true,
    workerPageNumber: 1,
    workerPageSize: dispatchScreenPaginationSizes[0],
    consignmentType: undefined,
  } as GlobalAppliedFilters,
};

function setDataInBuckets(orderData, filter, prevActiveBucket) {
  const buckets = {};
  const activeBucket = prevActiveBucket;
  const bucketsList = dispatchScreenBucketsPickily;
  const cancelled_orders_acknowledgement = [];
  bucketsList.forEach((currentBucket) => {
    const currentBucketData = orderData;
    const filteredData = getFilteredData(currentBucketData, filter);
    const cancelled = currentBucketData?.filter(elem => !elem.is_cancel_acknowledged && elem.order_status === 'cancelled');
      cancelled_orders_acknowledgement.push(...cancelled);
    buckets[currentBucket.id] = {
      filteredOrderData: currentBucket.id === prevActiveBucket ?
        (filteredData ? filteredData : currentBucketData) : [],
      data : currentBucket.id === prevActiveBucket ? currentBucketData : [],
    };
  });
  return { activeBucket, orderData : buckets,
    cancelledToAcknowledge: cancelled_orders_acknowledgement };
}

function convertOrderData(orderData) {
  const modifiedOrderData = {};
  Object.keys(orderData).forEach(key => modifiedOrderData[key] = orderData[key].data);
  return modifiedOrderData;
}

function getFilteredData(bucketData, filter: GlobalAppliedFilters) {
  let filteredData = [];
  const {
    searchValue,
    searchOrderBy,
    breachDuration,
    breachStatus,
    orderTimeRange,
    orderType,
    slotTimeRange,
  } = filter;
  const searchKeyMap: Record<GlobalAppliedFilters['searchOrderBy'], string> = {
    orderNumber: 'order_number',
    customerName: 'customer_name',
    customerPhone: 'customer_phone',
    workerName: 'worker_name',
    workerPhone: 'worker_phone',
    serviceTypeName: 'servicetype_name',
  };
  const searchKey = searchKeyMap[searchOrderBy] || 'order_number';
  // TODO - can create a function to check if there is any active filter
  if (!searchValue) {
    return false;
  }
  filteredData = bucketData.filter(d => String(d[searchKey]).toLowerCase()
    .includes(String(searchValue).toLowerCase()));
  return filteredData;
}

export default function (state:any = defaultState, action) {
  switch (action.type) {

    case DISPATCH_SCREEN_UPDATE_DATA: {
      const { orderData, cancelledToAcknowledge } = setDataInBuckets(
        action.data.order_data,
        state.globalAppliedFilters,
        state.dispatchScreenData.activeBucket,
      );
      
      let isNextPagePresent = false;
      if (action.data.is_next_present !== undefined) {
        isNextPagePresent = action.data.is_next_present;
      } else {
        const resultPerPageToSend = Number(state.globalAppliedFilters.pageSize);
        const summaryMetricsData = action.data.summary_metrics[`${state.dispatchScreenData.activeBucket}_count`];
        if (summaryMetricsData) {
          const totalOrderCount = Number(summaryMetricsData);
          const lastPageNo = Math.ceil(totalOrderCount / resultPerPageToSend);
          isNextPagePresent = (state.globalAppliedFilters.pageNumber < lastPageNo) ? true : false;
        }
      }
      
      const workerResultPerPage = Number(state.globalAppliedFilters.workerPageSize);
      let isNextWorkerPagePresent = false;
      const summaryMetricsWorkerData = action.data.worker_summary_metrics[`${state.dispatchScreenData.activeworkerBucket}_count`];
      if (summaryMetricsWorkerData) {
        const totalWorkerCount = Number(summaryMetricsWorkerData);
        const workerLastPageNo = Math.ceil(totalWorkerCount / workerResultPerPage);
        isNextWorkerPagePresent = (state.globalAppliedFilters.workerPageNumber < workerLastPageNo) ? true : false;
      }

      return {
        ...state,
        dispatchScreenData: {
          ...state.dispatchScreenData,
          orderData,
          cancelledToAcknowledge,
          analyticsData: action.data.analytics_data ? [...action.data.analytics_data] : [],
          riderData: [...action.data.rider_data],
          // weatherForecast: [...action.data.weather_forecast],
          // currentWeather: {...action.data.current_weather},
          summaryMetrics: {...action.data.summary_metrics} || {},
          isNextPresent: isNextPagePresent,
          isManualModeOn: action.data.is_manual_mode_on,
          manualModeHubCode: action.data.manual_mode_hub_code,
          isRiderNextPagePresent: isNextWorkerPagePresent,
          summaryMetricsRider: {...action.data.worker_summary_metrics} || {},
          doNotShowBucketCountsDispatch: action.data.do_not_show_count || false,
        },
      };
    }
    case DISPATCH_SCREEN_ENABLE_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case DISPATCH_SCREEN_DISABLE_LOADING: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case DISPATCH_SCREEN_SET_ACTIVE_BUCKET: {
      return {
        ...state,
        dispatchScreenData: {
          ...state.dispatchScreenData,
          activeBucket: action.data,
        },
        globalAppliedFilters: {
          ...state.globalAppliedFilters,
        },
      };
    }
    case DISPATCH_SCREEN_SET_ACTIVE_BUCKET_FOR_RIDER: {
      return {
        ...state,
        dispatchScreenData: {
          ...state.dispatchScreenData,
          activeworkerBucket: action.data,
        },
        globalAppliedFilters: {
          ...state.globalAppliedFilters,
        },
      };
    }
    case DISPATCH_SCREEN_UPDATE_FILTERED_ORDER_DATA: {
      return {
        ...state,
        dispatchScreenData: {
          ...state.dispatchScreenData,
          filteredOrderData: [...action.data],
        },
      };
    }
    case DISPATCH_SCREEN_SET_GLOBAL_FILTERS: {
      const currentGlobalAppliedFilters = {
        ...state.globalAppliedFilters,
        ...action.data,
      };
      return {
        ...state,
        globalAppliedFilters: currentGlobalAppliedFilters,
      };
    }
    case DISPATCH_SCREEN_RESET_GLOBAL_FILTERS: {
      const currentGlobalAppliedFilters = {
        ...state.globalAppliedFilters,
        ...defaultState.globalAppliedFilters,
      };
      return {
        ...state,
        globalAppliedFilters: currentGlobalAppliedFilters,
      };
    }
    case DISPATCH_SCREEN_APPLY_GLOBAL_FILTERS: {
      const currentGlobalAppliedFilters = {
        ...state.globalAppliedFilters,
        ...action.data,
      };
      const prevOrderData =
        state.dispatchScreenData.orderData[state.dispatchScreenData.activeBucket]?.data;
      const { orderData, activeBucket, cancelledToAcknowledge } = setDataInBuckets(
        prevOrderData,
        currentGlobalAppliedFilters,
        state.dispatchScreenData.activeBucket,
      );
      return {
        ...state,
        globalAppliedFilters: currentGlobalAppliedFilters,
        cancelledToAcknowledge,
        dispatchScreenData: {
          ...state.dispatchScreenData,
          activeBucket,
          orderData,
        },
      };
    }
    case DISPATCH_SCREEN_ANALYTICS_UPDATE_DATA: {
      return {
        ...state,
        dispatchScreenAnalyticsData: [...action.data.analytics_data] || [],
      };
    }
    default: return state;

  }
}

import * as React from 'react';
import { Layer, Source, useMap } from 'react-map-gl';
import type { LayerProps } from 'react-map-gl';

import { IUIMapPolyline } from '@components/common/UIMap/utils/types';

export const lineLayer: LayerProps = {
  type: 'line',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#1990FF',
    'line-width': 6,
  },
};

const UIPolyline = (props: IUIMapPolyline) => {
  const { layerId, sourceId, mapKey, data, layerProps, ...rest } = props;
  const { current: map } = useMap();

  const layer = {
    ...lineLayer,
    ...layerProps,
  };

  const queryRenderedFeatures = (e) => {
    return map.getMap().queryRenderedFeatures(e.point);
  };

  const onClickCallback = (e) => {
    const features = queryRenderedFeatures(e);

    // line click handler
    if (features.length > 0 && features?.[0]?.source === sourceId) {
      const idFromProps = features[0].properties?.id;

      if (idFromProps && Array.isArray(data.features) && data.features.length) {
        const featureData = data.features.find(
          (d) => d.properties?.id === idFromProps,
        );

        if (featureData && featureData.onClick) {
          const shiftKey = e.originalEvent.shiftKey;
          const altKey = e.originalEvent.altKey;
          const ctrlKey = e.originalEvent.ctrlKey;

          featureData.onClick(
            {
              shiftKey,
              altKey,
              ctrlKey,
            },
            idFromProps,
          );
        }
      }
    }
  };

  React.useEffect(() => {
    map.getMap().on('click', onClickCallback);

    return () => {
      // cleanup

      map.getMap().off('click', onClickCallback);
    };
  }, [data]);

  return (
    <Source id={sourceId} key={mapKey} type="geojson" data={data} {...rest}>
      <Layer {...layer} />
    </Source>
  );
};

export default UIPolyline;

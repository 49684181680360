import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { getFormattedColumns, getMapping } from './utils';
import { Tooltip } from 'antd';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    height: '100%',
    overflow: 'scroll',
  },
  value: {
    color: '#262626',
    fontSize: '28px',
    fontWeight: 'bold',
    textAlign: 'center',
    letterSpacing: '-0.05em',
    minWidth: '48px',
    padding: '8px 18px',
    borderRadius: '4px',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    height: '48px',
    display: 'flex',
    backgroundColor: 'transparent',
  },
  title: {
    color: '#595959',
    fontSize: '14px',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  numberTile: {
    borderRadius: '0.25rem',
    padding: '0.5rem',
    width: '30%',
    minWidth: '15rem',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '0.5%',
    gap: '.5em',
    alignSelf: 'center',
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%', // Take full width of the main container
    flexDirection: 'row',
    padding: '12px',
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.20)',
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: '#ffffff',
    margin: '6px 12px',
    height: '64px !important',
  },
  leftContainerText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '70%',
    textAlign: 'left',
    color: '#595959',
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    justifyContent: 'center',
    width: '30%',
    textAlign: 'center',
    color: '#262626',
  },
  rightContainerValue: {
    borderRadius: '25%',
    minWidth: '35px',
    fontWeight: '600',
  },
});

const EmptyRow = ({height}) => (
  <div
    style={{
      height,
      backgroundColor: 'transparent',
    }}
  ></div>
);

const NumberViz = (props) => {
  const {
    chartData,
    chartConfig,
    formatting,
    formData,
    visualizeMetaData,
    singleNumber = false,
  } = props;
  const classes = useStyles();
  const data = chartConfig;
  const showLabel = data?.graphic?.label?.data ?? true;
  const showTooltip = data?.graphic?.tooltip?.data ?? true;
  const showNumberVertically = data?.graphic?.verticalRepresentation?.data ?? false;
  const commaSeparatorType = data?.graphic?.commaSeparator?.data ?? 'international';
  const getFill = (type) => {
    const formattedColumns = getFormattedColumns({
      formatting,
      formData,
    });
    const mapping: any = [];
    Object.entries(formattedColumns).map(([key, value]) => {
      const map = getMapping({
        visualizeMetaData,
        formData,
        formatting,
        totalData: chartData,
        fieldKey: key,
        fieldName: 'value',
        type,
        isNumberChart: true,
      });
      mapping.push(map);
    });
    return mapping;
  };

  const textColor = getFill('textColor');
  const background = getFill('backgroundColor');

  return (
    <div className={classes.container}>
      {showNumberVertically && <EmptyRow height={5} />}
      {chartData.map((dataPoint) => {
        const metric = dataPoint.metric;
        formatting.map((condition, i) => {
          if (condition?.rules[0]?.conditions[0]?.columns[0] === metric) {
            dataPoint.mapIndex = i;
          }
        });
        let fillColor = '#000000';
        let backgroundColor = '#F6F2F1';
        if (dataPoint.mapIndex !== undefined) {
          fillColor = textColor[dataPoint.mapIndex]?.mapping(
            dataPoint.value,
            dataPoint,
          )?.fill;
          backgroundColor = background[dataPoint.mapIndex]?.mapping(
            dataPoint.value,
            dataPoint,
          )?.fill;
        }
        const locale =
          commaSeparatorType === 'international'
            ? 'en-US'
            : commaSeparatorType === 'indian'
            ? 'en-IN'
            : null;
        return (
          <>
            {showNumberVertically ? (
              <div className={classes.mainContainer}>
                <div className={classes.leftContainer}>
                  <Tooltip title={dataPoint.title}>
                    <span className={classes.leftContainerText}>
                      {dataPoint.title}
                    </span>
                  </Tooltip>
                </div>
                <div className={classes.rightContainer}>
                  <span
                    className={classes.rightContainerValue}
                    style={{ color: fillColor, backgroundColor }}
                  >
                    {locale
                      ? dataPoint.value.toLocaleString(locale)
                      : dataPoint.value}
                  </span>
                </div>
              </div>
            ) : (
              <div
                className={classes.numberTile}
                style={{
                  paddingTop: singleNumber ? '0rem' : '0.5rem',
                }}
                title={showTooltip ? dataPoint.title : null}
              >
                <div
                  className={classes.value}
                  style={{
                    color: fillColor,
                    backgroundColor,
                    fontFamily: 'Open Sans',
                  }}
                >
                  <span>
                    {locale
                      ? dataPoint.value.toLocaleString(locale)
                      : dataPoint.value}
                  </span>
                </div>
                {showLabel && (
                  <div className={classes.title} style={{ color: fillColor }}>
                    {dataPoint.title}
                  </div>
                )}
              </div>
            )}
          </>
        );
      })}
      {showNumberVertically && <EmptyRow height={5} />}
    </div>
  );
};

export default NumberViz;

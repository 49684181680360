import { applicationType, DEFAULT_ORG } from '@src/globals';
import { logout } from '@api/logout';
import { message } from 'antd';
import { isNativeApp } from 'src/utils/utils';
import { LocalStorageKeys, LocalStorageService } from 'src/services/localStorage';
import Cookies from 'universal-cookie';
const AUTHENTICATION_ERROR="AUTHENTICATION_ERROR"

export const getAuthenticationHeaders = async () => {
  return {
    'user-id': await LocalStorageService.get(LocalStorageKeys.___USER_ID__),
    'organisation-id': await LocalStorageService.get(LocalStorageKeys.__ORGANISATION_ID__),
    'access-token': await LocalStorageService.get(LocalStorageKeys.__AUTH_TOKEN__),
    'application-type': applicationType,
  };
};

export const isAuthenticationHeadersPresent = () => {
  return (
    LocalStorageService.exists(LocalStorageKeys.___USER_ID__)
    && LocalStorageService.exists(LocalStorageKeys.__ORGANISATION_ID__)
    && LocalStorageService.exists(LocalStorageKeys.__AUTH_TOKEN__)
  );
};

export const getErrorMessage = (err) => {
  let errorMessage = '';
  if (!err.response) {
    errorMessage = 'Could not connect. Please check your internet connection';
  } else {
    const responseData = err.response.data;
    if (responseData && responseData.error) {
      errorMessage = responseData.error.message;
    } else {
      errorMessage = `Something went wrong\n${err.message}`;
    }
  }
  return errorMessage;
};

export const getErrorMessageForBlob = async (err) => {
  let errorMessage = '';
  if (!err.response) {
    errorMessage = 'Could not connect. Please check your internet connection';
  } else {
    const responseData = await new Response(err.response.data).json();
    console.log(responseData);
    if (responseData && responseData.error) {
      errorMessage = responseData.error.message;
    } else {
      errorMessage = `Something went wrong\n${err.message}`;
    }
  }
  return errorMessage;
};
export const getErrorForBlob = async (err) => {
  let error: any = {};
  if (!err.response) {
    error.message = 'Could not connect. Please check your internet connection';
  } else {
    const responseData = await new Response(err.response.data).json();
    console.log(responseData);
    if (responseData && responseData.error) {
      error = responseData.error;
    } else {
      error.message = `Something went wrong\n${err.message}`;
    }
  }
  return error;
};

export const generateQueryString = (paramsArray) => {
  const queryString = paramsArray
    .map(({ key, val, isArray }) => {
      if (isArray) {
        return val
          .map((x) => `${key}=${encodeURIComponent(x.label)}`)
          .filter((elem: any) => elem !== null)
          .join('&');
      }
      if (val || val === 0) {
        return `${key}=${encodeURIComponent(val)}`;
      }
      return null;
    })
    .filter((elem: any) => elem !== null)
    .join('&');
  return queryString;
};
export const generateQueryStringFromObject = (obj) => {
  if (!obj) {
    return '';
  }
  const keys = Object.keys(obj);
  const x = keys.map((key) => {
    return { key, val: obj[key], isArray: Array.isArray(obj[key]) };
  });
  return generateQueryString(x);
};
export const generateObjectFromList = (key, list) => {
  const generatedList: any = [];
  if (list) {
    list.forEach((element) => {
      generatedList.push({ key, val: element });
    });
  }
  return generatedList;
};

export const getFileExtension = (file) => {
  return file.name.split('.').pop();
};

export const dataHeaders = {
  JPG: 'image/*',
  JPEG: 'image/*',
  PNG: 'image/*',
  WEBP: 'image/*',
  PDF: 'application/*',
  DOCX: 'application/*',
  XLSX: 'application/*',
  CSV: 'text/csv',
  XLS: 'application/*',
  DOC: 'application/*',
};

export const clearUserCredentialFromCache= () => {
  const cookies = new Cookies();
  cookies.remove('is_authenticated', { path: '/' });
  LocalStorageService.remove(LocalStorageKeys.___USER_ID__);
  LocalStorageService.remove(LocalStorageKeys.__AUTH_TOKEN__);
  LocalStorageService.remove(LocalStorageKeys.__ORGANISATION_ID__);
};

export const logoutUserAndClearCache = async () => {
  const response = await logout();
    if (response.isSuccess) {
      clearUserCredentialFromCache();
      message.success('User Logged out succesfully');
      window.location.href = '/login';
    } else if(response.errCode==AUTHENTICATION_ERROR) {
      clearUserCredentialFromCache();
      window.location.href = '/login';
    } else{
      message.error('Logout failed. Please try again');
    } 
};

export const isAuthenticationFlowPage = () => {
  return (
    [
      '/login',
      '/forgot-password',
      '/login/',
      '/forgot-password/',
      '/ssoRedirect',
      '/ssoRedirect/',
      '/signInFromInternal/',
      '/signInFromInternal',
    ].includes(window.location.pathname) ||
    window.location.pathname?.includes('/oauth/oidc')
  );
};

export const redirectToLoginPage = () => {
  if (!isAuthenticationFlowPage()) {
    window.location.href = '/login';
  }
};

export const getURLConfigs = () => {
  const origin = window.location.hostname;
  let organisationPrettyName = '';

  if (origin.indexOf('.') > 0) {
    organisationPrettyName = origin.substring(0, origin.indexOf('.'));
  } else {
    organisationPrettyName = DEFAULT_ORG;
  }

  return {
    origin,
    organisationPrettyName,
  };
};

export const redirectToUrl = (organisationDetails) => {
  const redirectToFromOrgDetails = organisationDetails.ops_login_redirect_to;
  const redirectToFromLogin = LocalStorageService.getRaw(LocalStorageKeys.__REDIRECT_TO_VIEW_TYPE__);
  const redirectTo = redirectToFromLogin && redirectToFromLogin !== 'undefined' ? redirectToFromLogin : redirectToFromOrgDetails;

  switch (redirectTo) {
      case 'landingPageNew':
        return '/ops/home';
      case 'manage':
          return '/dashboard/#!/manage';
      case 'setup':
          return '/dashboard/#!/setup';
      case 'ltl':
          return '/ops/domestic/track';
      case 'retail':
        if(isNativeApp()){
          return '/ops/analytics';
        }
        return '/ops/consignments';
      case 'opsv2':
          return '/ops/consignments';
      case 'ondemand':
          return '/ops/od/reporting';
      case 'thirdParty':
          return '/ops/third-party';
      case 'hybridView':
          return '/ops/v1/consignments-list';
      default:
          return '/';
  }
};

export const getDestinationUrl = () => {
  let destinationUrl = '';

  // decode location query string
  const decodeUrlQueryString = decodeURIComponent(window.location.search.substring(1));

  // list of query params
  const queryParamList = decodeUrlQueryString.split('&');

  // value of destination url (query field name is 'destUrl')
  queryParamList.forEach(queryParam => {
      const field = queryParam.substring(0, queryParam.indexOf('='));
      if (field === 'destUrl') {
          destinationUrl = queryParam.substring(queryParam.indexOf('=') + 1);
      }
  });

  return destinationUrl;
};

export const setLocalStorageData = async (organisationDetails, loginDetails, stayLoggedIn = false) => {
  const cookies = new Cookies();
  const validation = loginDetails.parts_to_show || { setup:true, manage:true, analyse:true };
  const logoURL = loginDetails.logo_url || '';
  const bookingVisibleString = (loginDetails.parts_to_show && loginDetails.parts_to_show.show_agent_booking) ? 'true' : 'false';

  const itemsToRemoveFromLocalStorage = [
    LocalStorageKeys.__NAME__,
    LocalStorageKeys.___USER_ID__,
    LocalStorageKeys.__AUTH_TOKEN__,
    LocalStorageKeys.__ORGANISATION_ID__,
    LocalStorageKeys.__LOGO_URL__,
    LocalStorageKeys.__BOOKING_VISIBLE__,
    LocalStorageKeys.__PERMISSION__,
    LocalStorageKeys.__REDIRECT_TO_VIEW_TYPE__,
    LocalStorageKeys.__REDIRECT_TO_HUB__,
    LocalStorageKeys.__CURRENT_HUB_ID__,
    LocalStorageKeys.__CURRENT_HUB_CODE__,
    LocalStorageKeys.__CURRENT_HUB_NAME__,
    LocalStorageKeys.__STATE__,
    LocalStorageKeys.__CURRENT_HUB__,
  ];
  itemsToRemoveFromLocalStorage.forEach(elem => LocalStorageService.remove(elem));

  const itemsToSet = {
    [LocalStorageKeys.__PERMISSION__]: JSON.stringify(validation),
    [LocalStorageKeys.__NAME__]: loginDetails.employee.full_name,
    [LocalStorageKeys.__LOGO_URL__]: logoURL,
    [LocalStorageKeys.__BOOKING_VISIBLE__]: bookingVisibleString,
    [LocalStorageKeys.__EMPLOYEE_PHONE__]: loginDetails.employee.phone,
    [LocalStorageKeys.__REDIRECT_TO_VIEW_TYPE__]: loginDetails.ops_login_redirect_to,
    [LocalStorageKeys.__REDIRECT_TO_HUB__]: JSON.stringify(loginDetails.ops_login_hub),
    [LocalStorageKeys.___USER_ID__]: loginDetails.employee.id,
    [LocalStorageKeys.__AUTH_TOKEN__]: loginDetails.access_token.id,
    [LocalStorageKeys.__ORGANISATION_ID__]: loginDetails.organisation_id,
  };

  if (loginDetails.favicon_url) {
    itemsToSet[LocalStorageKeys.__FAVICON_URL__] = loginDetails.favicon_url;
  }

  for (const key in itemsToSet) {
    await LocalStorageService.set(key, itemsToSet[key]);
  }

  cookies.set('is_authenticated', 'true', {
    path:'/',
    expires: new Date((new Date().getTime()) + (1000 * 60 * 60 * 24 * 365)),
    secure: true, sameSite: 'lax',
  });

  window.location.href = redirectToUrl(organisationDetails);
};

export const getOidcCallbackQueryParams = (params) => {
  const { code, error } = params;

  if (code) {
    return `code=${code}`;
  }

  return `error=${error}`;
};

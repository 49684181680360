import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Navbar from '@components/navbar/Navbar';
import { useSelector } from 'react-redux';
import { CaretRightOutlined, ReloadOutlined } from '@ant-design/icons';
import PinLocationMap from 'src/components/common/PinLocationMap/PinLocationMapRider';
import { Input, DatePicker, Table, Button } from 'antd';
import moment from 'moment';
import { getNavbarFilters } from 'src/components/pages/GenericConsignment/NavbarFilters';
import { useHistory, useLocation } from 'react-router-dom';
import { getRiderForHub } from 'src/api/dashboard';
import 'react-loading-skeleton/dist/skeleton.css';

const { Search } = Input;
const useStyles = createUseStyles({
  slotContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '20px',
  },
  table: {
    '& .ant-table-cell': {
      padding: '1rem 0.5rem',
      backgroundColor: 'inherit',
    },
    '& .ant-table-row': {
      borderBottom: '1px solid #D9D9D9',
    },
    '& .ant-table-thead': {
      backgroundColor: '#F2F7FC',
      fontSize: '0.625rem',
      padding: '6px',
      fontFamily: 'Open Sans',
      fontWeight: 400,
    },
    '& .ant-table-tbody': {
      fontSize: '0.75rem',
      padding: '6px',
      fontFamily: 'Open Sans',
      fontWeight: 500,
    },
    '& .ant-spin-nested-loading': {
      '& .ant-spin-container': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '630px',
      },
    },
    fontSize: '10x',
    height: 'calc(100vh-100px)',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
  },
  filtersBody: {
    padding: '1rem',
    display: 'flex',
    border: '1px solid #D9D9D9',
    boxShadow: '0px 1px 4px 0px rgba(0,0,0,0.2)',
    justifyContent: 'space-between',
  },
  dateText: {
    padding: '4px 22px',
    border: '1px solid #D9D9D9',
    borderRight: '0px',
  },
  mainBody: {
    display: 'flex',
    flexDirection: 'column',
    width: '21.875rem',
    borderRight: '1px solid #D9D9D9',
  },
  divBody: {
    padding: '0.625rem',
    borderBottom: '1px solid #D9D9D9',
    width: '21.875rem',
  },
});

const RiderTrackingHistory = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const currHub = useSelector((state) => state.cachedData.currHub);
  const viewType = useSelector((state) => state.masterData.viewType);
  const [selectedDate, setSelectedDate] = useState(
    location.state?.date
      ? moment(location.state?.date)
      : localStorage.getItem('dateRiderHistory')
      ? moment(localStorage.getItem('dateRiderHistory'))
      : moment(),
  );
  const [ridersData, setRidersData] = useState([]);
  const [totalRiderCount, setTotalRiderCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const fetchData = async () => {
    const response = await getRiderForHub({
      hubId: currHub.id,
      date: selectedDate.format('YYYY-MM-DD'),
      offset: 0,
      limit: 10,
    });
    setLoaded(true);
    if (pageNo === 1 && response?.response?.totalCount) {
      setTotalRiderCount(response.response.totalCount);
    }
    setRidersData(response.data);
  };
  useEffect(() => {
    if (currHub?.code && selectedDate) {
      fetchData();
    }
  }, [currHub, selectedDate]);
  const onChangeDatePicker = (e) => {
    setSelectedDate(e);
    localStorage.setItem('dateRiderHistory', e.format('YYYY-MM-DD'));
  };

  const onSearchRider = async (e) => {
    const response = await getRiderForHub({
      hubId: currHub.id,
      date: selectedDate.format('YYYY-MM-DD'),
      offset: (pageNo - 1) * 10,
      limit: 10,
      searchedRiderName: e,
    });
    setRidersData(response.data);
  };
  const onCaretClick = (e) => {
    history.push({
      pathname: `/manage/tracking/v2/${e.id}`,
      state: { date: selectedDate.format('YYYY-MM-DD') },
    });
  };
  const columns = [
    {
      title: 'Rider Code',
      dataIndex: 'code',
      key: 'code',
      width: 75,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => <div style={{ color: '#006EC3' }}>{text}</div>,
    },
    {
      title: 'Rider Name',
      dataIndex: 'name',
      key: 'name',
      width: 75,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: 'GPS Distance',
      dataIndex: 'gps_distance',
      key: 'gps_distance',
      render: (text) => <div>{`${(text / 1000).toFixed(2)} km`}</div>,
    },
    {
      title: 'Details',
      dataIndex: '',
      key: 'x',
      render: (text, record) => (
        <CaretRightOutlined
          onClick={() => onCaretClick(record)}
          style={{ color: '#006EC3' }}
        />
      ),
    },
  ];
  const onChangePagination = async (data) => {
    setPageNo(data.current);
    const response = await getRiderForHub({
      hubCode: currHub.code,
      date: selectedDate.format('YYYY-MM-DD'),
      offset: (data.current - 1) * 10,
      limit: 10,
    });
    setRidersData(response.data);
  };
  const onClickRefresh = async () => {
    setLoaded(false);
    if (currHub.code && selectedDate) {
      fetchData();
    }
  };
  return (
    <div className={classes.body}>
      <Navbar
        hideMenuBar={true}
        pageTitle={'Rider Tracking History'}
        leftComponents={getNavbarFilters(['hub_search_with_type'], viewType)}
        hideNotificationIcon={true}
      />
      <div className={classes.filtersBody}>
        <div style={{ display: 'flex' }}>
          <div className={classes.dateText}> Date </div>
          <DatePicker onChange={onChangeDatePicker} value={selectedDate} />
        </div>
        <Button icon={<ReloadOutlined />} onClick={onClickRefresh}>
          Refresh
        </Button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className={classes.mainBody}>
          <>
            <div className={classes.divBody}>
              <Search
                placeholder="Search Rider"
                allowClear
                onSearch={onSearchRider}
                style={{
                  width: 200,
                }}
              />
            </div>
            <Table
              onChange={onChangePagination}
              className={classes.table}
              columns={columns}
              dataSource={ridersData}
              loading={!loaded}
              rowKey="code"
              pagination={{
                total: totalRiderCount,
                responsive: true,
                showQuickJumper: false,
                showSizeChanger: false,
                showLessItems: true,
                size: 'small',
              }}
            />
          </>
        </div>
        <div style={{ width: '100%', height: '700px' }}>
          <PinLocationMap
            initialLat={currHub?.hub_loc?.[0]}
            initialLng={currHub?.hub_loc?.[1]}
            hubLocation={currHub?.hub_loc}
            onLocationChange={() => {}}
            coordinates={[]}
          />
        </div>
      </div>
    </div>
  );
};

export default RiderTrackingHistory;

import { ONLINE_BOOKING_BASE_URL } from '../globals';
import {
  SKU_MASTER_DOWNLOAD_DUMP,
  SKU_MASTER_SAMPLE_CSV_DOWNLOAD,
  SKU_MASTER_UPDATE,
  PROMOCODES_DOWNLOAD_DUMP,
  PROMOCODES_SAMPLE_CSV_DOWNLOAD,
  PROMOCODES_UPDATE,
} from './routes';

import { GET, POST } from './apiHandler';

export const downloadSkuMastersData = async () => {
  return GET(
    `${ONLINE_BOOKING_BASE_URL}${SKU_MASTER_DOWNLOAD_DUMP}`,
    'blob',
  );
};

export const downloadPromocodesData = async () => {
  return GET(
    `${ONLINE_BOOKING_BASE_URL}${PROMOCODES_DOWNLOAD_DUMP}`,
    'blob',
  );
};

export const downloadSampleCsvSkuMaster = async () => {
  return GET(
    `${ONLINE_BOOKING_BASE_URL}${SKU_MASTER_SAMPLE_CSV_DOWNLOAD}`,
    'blob',
  );
};

export const downloadSampleCsvPromocodes = async () => {
  return GET(
    `${ONLINE_BOOKING_BASE_URL}${PROMOCODES_SAMPLE_CSV_DOWNLOAD}`,
    'blob',
  );
};

export const bulkUploadSkuMasters = async (body) => {
    return POST(
        `${ONLINE_BOOKING_BASE_URL}${SKU_MASTER_UPDATE}`, 
        body
    );
};

export const bulkUploadPromocodes = async (body) => {
  return POST(
      `${ONLINE_BOOKING_BASE_URL}${PROMOCODES_UPDATE}`, 
      body
  );
};
import { Tooltip } from 'antd';
import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from 'src/theming/jssTypes';

const styles = (theme: ThemeType): CssType => ({
    tooltip: {
        '& .ant-tooltip-inner > div': {
            color: '#FFFFFF !important',
        },
        '& .ant-tooltip-inner > div > div': {
            color: '#FFFFFF !important',
        },
        '& .ant-tooltip-inner > div > div > a': {
            color: '#FFFFFF !important',
        },
        '& .ant-tooltip-inner > div > div > div': {
            color: '#FFFFFF !important',
        },
        '& .ant-tooltip-inner > span': {
          color: '#FFFFFF !important',
        },
        '& .ant-tooltip-inner > button': {
            color: '#FFFFFF !important',
        },
    },
});

const CustomTableCell = (props: any) => {
    const { classes, children, sameTooltipAsElem, colWidth, ...restProps } = props;
    const childRef = React.useRef(null);
    const [isOverflowing, setIsOverflowing] = React.useState(false);

    React.useEffect(() => {
        if (childRef.current) {
            setIsOverflowing(childRef.current.scrollWidth > childRef.current.clientWidth);
        }
    }, [children]);

    const cellContent = <div ref={childRef} style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        cursor: 'default',
    }}>{children}</div>;

    const cellContentWithOverflow = <Tooltip
        overlayClassName={sameTooltipAsElem ? null : classes.tooltip}
        title={children}
    >
        {cellContent}
    </Tooltip>;

    return <td {...restProps}>
         {isOverflowing ? cellContentWithOverflow : cellContent}
    </td>;
};

export default React.memo(withStyles(styles, {
    injectTheme: true,
})(CustomTableCell));

import * as React from 'react';
import {
  Map,
  MapProvider,
  NavigationControl,
  FullscreenControl,
} from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

import {
  DYNAMIC_SVG_ICONS,
  MAP_DEFAULT_CONFIG,
} from '@components/common/UIMap/utils/constants';
import { IUIMap } from '@components/common/UIMap/utils/types';
import { loadImages } from '@components/common/UIMap/utils/utils';
import { getUrlFromSVG } from '@utils/utils';

const UIMap = (props: IUIMap) => {
  const {
    mapRef,
    children,
    interactiveLayerIds,
    id = MAP_DEFAULT_CONFIG.id,
    mapStyle = MAP_DEFAULT_CONFIG.mapStyle,
    imagesToLoad = MAP_DEFAULT_CONFIG.images,
    initialViewState = MAP_DEFAULT_CONFIG.initialViewState,
    onClick = () => {},
    onMouseLeave = () => {},
    onMouseEnter = () => {},
    onMouseMove = () => {},
    onZoom = () => {},
    onMapLoad = () => {},
    onDrag = () => {},
    ...rest
  } = props;

  const loadMissingImage = (image) => {
    if (image.length && image[0] && image[1]) {
      const imageId = image[0];
      const color = image[1];

      const icon = DYNAMIC_SVG_ICONS[imageId];
      if (icon) {
        const imageSrc = icon.src;
        loadImages(mapRef.current.getMap() as any, [
          {
            src: getUrlFromSVG(imageSrc({ color: `#${color}` })),
            name: `${imageId}#${color}`,
            isSdf: icon.isSdf,
            imageHeight: icon.imageHeight,
            imageWidth: icon.imageWidth,
            maintainRatio: icon.maintainRatio,
          },
        ]);
      }
    }
  };

  const onLoad = (e) => {
    if (mapRef?.current) {
      loadImages(mapRef.current.getMap() as any, imagesToLoad);
    }
    onMapLoad(e);
    const resizeObserver = new ResizeObserver(() => {
      if (mapRef.current) {
        mapRef.current.resize();
      }
    });
    // Observe the map div container.
    resizeObserver.observe(document.getElementById(id));
  };

  React.useEffect(() => {
    if (mapRef.current) {
      // load missing images
      mapRef.current.getMap().on('styleimagemissing', (e) => {
        const id = e.id;
        const image = id.split('#');
        loadMissingImage(image);
      });
    }
  }, [mapRef.current]);

  return (
    <MapProvider>
      <Map
        id={id}
        ref={mapRef}
        mapLib={maplibregl}
        attributionControl={false}
        interactiveLayerIds={interactiveLayerIds}
        initialViewState={initialViewState}
        style={{ width: '100% !important', height: '100% !important' }}
        mapStyle={mapStyle}
        onLoad={onLoad}
        onMouseEnter={onMouseEnter}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        onZoom={onZoom}
        onDrag={onDrag}
        {...rest}
      >
        <NavigationControl />
        <FullscreenControl position="top-left" />
        {children}
      </Map>
    </MapProvider>
  );
};

export default UIMap;

/* eslint-disable react/no-unused-prop-types */
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useControl } from 'react-map-gl';
import React from 'react';
import type { MapRef, ControlPosition } from 'react-map-gl';

import { UIMAP_EDITOR_STYLES } from '@components/common/UIMap/utils/constants';

type UIMapEditorProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;
  features?: any;
  displayControlsDefault?: any;
  userProperties?: boolean;
  styles?: any;
  controls?: any;
  setDrawManager?: any;
  onCreate?: (evt: { features: object[] }, drawManager: any) => void;
  onUpdate?: (
    evt: { features: object[]; action: string },
    drawManager: any,
  ) => void;
  onDelete?: (evt: { features: object[] }, drawManager: any) => void;
  onSelect?: (evt: { features: object[] }, drawManager: any) => void;
  keybindings?: boolean;
};

export default function UIMapEditor(props: UIMapEditorProps) {
  const {
    features,
    setDrawManager,
    position,
    onCreate,
    onUpdate,
    onDelete,
    onSelect,
  } = props;
  const functionMap = React.useRef({} as any);
  const drawRef = React.useRef({} as any);
  const mapRef = React.useRef({} as any);
  const getCallBackFunction = (fn, type) => {
    functionMap.current[type] = fn;
    return fn;
  };
  React.useEffect(() => {
    const map = mapRef.current;
    if (map) {
      if (Object.keys(functionMap.current).length !== 4) {
        return;
      }
      map.off('draw.create', functionMap.current['CREATE']);
      map.off('draw.update', functionMap.current['UPDATE']);
      map.off('draw.delete', functionMap.current['DELETE']);
      map.off('draw.selectionchange', functionMap.current['SELECT']);

      map.on(
        'draw.create',
        getCallBackFunction((e) => onCreate(e, drawRef.current), 'CREATE'),
      );
      map.on(
        'draw.update',
        getCallBackFunction((e) => onUpdate(e, drawRef.current), 'UPDATE'),
      );
      map.on(
        'draw.delete',
        getCallBackFunction((e) => onDelete(e, drawRef.current), 'DELETE'),
      );
      map.on(
        'draw.selectionchange',
        getCallBackFunction((e) => onSelect(e, drawRef.current), 'SELECT'),
      );
    }
  }, [onCreate, onUpdate, onDelete, onSelect]);

  useControl<MapboxDraw>(
    () => {
      drawRef.current = new MapboxDraw(props);
      return drawRef.current;
    },
    ({ map }: { map: MapRef }) => {
      mapRef.current = map;
      map.on(
        'draw.create',
        getCallBackFunction((e) => onCreate(e, drawRef.current), 'CREATE'),
      );
      map.on(
        'draw.update',
        getCallBackFunction((e) => onUpdate(e, drawRef.current), 'UPDATE'),
      );
      map.on(
        'draw.delete',
        getCallBackFunction((e) => onDelete(e, drawRef.current), 'DELETE'),
      );
      map.on(
        'draw.selectionchange',
        getCallBackFunction((e) => onSelect(e, drawRef.current), 'SELECT'),
      );
      if (features) {
        drawRef.current.add(features);
      }
      if (setDrawManager) {
        setDrawManager(drawRef.current);
      }
    },
    ({ map }: { map: MapRef }) => {
      map.off('draw.create', functionMap.current['CREATE']);
      map.off('draw.update', functionMap.current['UPDATE']);
      map.off('draw.delete', functionMap.current['DELETE']);
      map.off('draw.selectionchange', functionMap.current['SELECT']);
    },
    {
      position,
    },
  );
  return null;
}

UIMapEditor.defaultProps = {
  onCreate: () => {},
  onUpdate: () => {},
  onDelete: () => {},
  onSelect: () => {},
  position: 'top-left',
  features: null,
  displayControlsDefault: true,
  userProperties: false,
  styles: UIMAP_EDITOR_STYLES,
  controls: undefined,
  setDrawManager: undefined,
  keybindings: undefined,
};

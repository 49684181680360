import * as React from 'react';
import withStyles from 'react-jss';
import { CssType,
  ThemeType} from '../../../../theming/jssTypes';
import { Modal, message, Table, Input, Button, Row, Col } from 'antd';
import { bulkDeleteTripConsignment } from '../../../../api/trips';
import Loader from '../../../common/Loader';
import FailureArrayHandler from '../../../common/FailureArrayHandler';
import { bulkDeleteFailureColumns } from './masterData';
const styles = (theme: ThemeType): CssType => ({
  addCNModal : {
    display: 'flex',
    '& label': {
      display: 'inline-block',
      border: '1px solid #d9d9d9',
      padding: '4px 11px',
      height: '32px',
      borderRadius: '4px 0 0 4px',
    },
    '& input': {
      width: '230px',
      borderRadius: '0 4px 4px 0',
    },
    '& button': {
      marginLeft: 10,
      float: 'right',
    },
  },
  tableContainer: {
    flex: '1 1 0',
    overflow: 'auto',
    height: 300,
    marginTop: 15,
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
    '& .ant-table-tbody > tr > td, .ant-table-thead > tr > th' : {
      padding: 9,
    },
    '& button': {
      float: 'right',
    },
  },
});

class AddCNModal extends React.Component<any, any> {

  state: any = {
    selectedWorkerId: '',
    riderOptions: [],
    inputValue : '',
    cnList: new Set(),
    confirmLoading: false,
    failureArray: [],
    isErrorModalVisible: false,
    notes: '',
  };

  handleFailureModalClose = () => {
    const { closeAllModals, handleModalClose } = this.props;
    this.setState({
      isErrorModalVisible: false,
    });
    if(closeAllModals){
      handleModalClose(false);
    }
  };

  handleDeleteSubmit = async() => {
    this.setState({
      confirmLoading: true,
    });
    const { consignments, dataSource, taskType } = this.props;
    if (consignments.length === 0) {
      message.warning('No consignments to unassign');
      this.setState({
        confirmLoading: false,
      });
      return;
    }
    const consignmentsToSend : any = [];
    consignments.map((x) => {
      const record = dataSource?.filter((record) => record.reference_number === x);
      consignmentsToSend.push({ referenceNumber:x,
                                taskType: record?.[0]?.task_type });
    });

    const { notes } = this.state;

    const body = {
      consignments:consignmentsToSend,
      notes,
    };
    const response = await bulkDeleteTripConsignment(body);
    if (response.isSuccess) {
      const data = response.data.data;
      if (data && data.failureArray &&
        data.failureArray.length > 0) {
        this.setState({
          isErrorModalVisible : true,
          failureArray: data.failureArray,
        });
      } else {
        message.success(`${taskType} deleted from trip`);
        this.props.handleModalClose(true);
      }
    } else {
      message.error(response.errorMessage);
      this.props.handleModalClose(false);
    }
    this.setState({
      confirmLoading: false,
    });
  };

  render() {
    const { isVisible, classes, taskType, dataSource } = this.props;
    const { cnList, confirmLoading, isLoading, inputValue,
      isErrorModalVisible, failureArray, notes } = this.state;
    if (isLoading) {
      return <Loader zIndex={100}/>;
    }
    const header = 'Unassign '+ taskType;
    return (
        <Modal
        confirmLoading = {confirmLoading}
        title= {header}
        width={330}
        visible={isVisible}
        onCancel={() => this.props.handleModalClose(false)}
        onOk={this.handleDeleteSubmit}
        destroyOnClose
        >
        <div>
          Are you sure you want to unassign {this.props.consignments?.length+' '+taskType} from trip?
        </div>
        <div style={{marginTop: '20px'}}>
          <Row align="middle">
            <Col span = {4}>
              Notes:
            </Col>
            <Col span = {20}>
              <Input
                placeholder="Enter Notes"
                value={notes}
                onChange={(e) => {
                    e.preventDefault();
                    this.setState({
                      notes: e.target.value,
                    });
                }}
              />
            </Col>
          </Row>
        </div>
        { isErrorModalVisible ?
          <FailureArrayHandler
            failureArray={failureArray}
            isVisible={isErrorModalVisible}
            failureColumns={bulkDeleteFailureColumns}
            onModalClose={this.handleFailureModalClose} >
          </FailureArrayHandler> : null
        }
        </Modal>
    );
  }
}

export default withStyles(styles, { injectTheme: true })(AddCNModal);

const UpcountryHubSVG = () => {
  return `<svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\
  <defs>
      <circle id="path-1" cx="16" cy="16" r="16"></circle>
      <filter x="-22.2%" y="-22.2%" width="150.6%" height="150.6%" filterUnits="objectBoundingBox" id="filter-2">
          <feMorphology radius="0.1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
          <feOffset dx="1" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
          <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
          <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.597344882 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
      </filter>
  </defs>
  <g id="Secondary_Hub" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Hub" transform="translate(4.000000, 4.000000)">
          <g id="Oval-Copy-5">
              <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
              <use stroke="#595959" stroke-width="0.2" fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-1"></use>
          </g>
      </g>
      <g id="warehouse" transform="translate(10.000000, 10.000000)" fill="#1C81E8" fill-rule="nonzero">
          <path d="M8.96911621,16.6952515 L6.55441285,16.6952515 C6.30859375,16.6952515 6.10931398,16.8945312 6.10931398,17.1401977 L6.10931398,19.5550537 C6.10931398,19.8007202 6.30859375,20 6.55441285,20 L8.96911621,20 C9.21478273,20 9.4140625,19.8007202 9.4140625,19.5550537 L9.4140625,17.1401977 C9.4140625,16.8945312 9.21478273,16.6952515 8.96911621,16.6952515 Z" id="Path"></path>
          <path d="M13.4455871,16.6952515 L11.0308838,16.6952515 C10.7852173,16.6952515 10.5859375,16.8945312 10.5859375,17.1401977 L10.5859375,19.5550537 C10.5859375,19.8007202 10.7852173,20 11.0308838,20 L13.4455871,20 C13.6914063,20 13.8905334,19.8007202 13.8905334,19.5550537 L13.8905334,17.1401977 C13.8905334,16.8945312 13.6914063,16.6952515 13.4455871,16.6952515 L13.4455871,16.6952515 Z" id="Path"></path>
          <path d="M11.6523743,15.2033996 L11.6523743,12.7886963 C11.6523743,12.5430298 11.4530945,12.34375 11.207428,12.34375 L8.79272461,12.34375 C8.54690551,12.34375 8.34777832,12.5430298 8.34777832,12.7886963 L8.34777832,15.2033996 C8.34777832,15.4492188 8.54690551,15.648346 8.79272461,15.648346 L11.207428,15.648346 C11.4530945,15.6484985 11.6523743,15.4492188 11.6523743,15.2033996 L11.6523743,15.2033996 Z" id="Path"></path>
          <path d="M18.4794617,8.25439453 L10.3205871,2.9197693 C10.1257324,2.7923584 9.874115,2.7923584 9.67926023,2.9197693 L1.52053832,8.25439453 C1.35498047,8.36273195 1.25518797,8.54705813 1.25518797,8.74481203 L1.25518797,19.4140625 C1.25518797,19.7377014 1.51748656,20 1.84112547,20 L4.35150148,20 C4.67514039,20 4.93743898,19.7377014 4.93743898,19.4140625 L4.93743898,9.95834352 L15.0624084,9.95834352 L15.0624084,19.4140625 C15.0624084,19.7377014 15.3248596,20 15.6483459,20 L18.1588745,20 C18.4823609,20 18.744812,19.7377014 18.744812,19.4140625 L18.744812,8.74481203 C18.744812,8.54705813 18.6450195,8.36257934 18.4794617,8.25439453 Z" id="Path"></path>
          <path d="M19.9885559,6.74713133 C19.9581909,6.59469605 19.8683166,6.46072387 19.7390747,6.37451172 L10.3250122,0.0984191797 C10.226593,0.0329589844 10.1132202,0.000152587891 10,0.000152587891 L10,0 C9.88677977,0 9.77340699,0.0328064063 9.67498781,0.0984191797 L0.260925312,6.37451172 C0.131683359,6.46072387 0.0419616797,6.59469605 0.0114441016,6.74713133 C-0.0190734766,6.89941406 0.0122070312,7.05780031 0.0984191797,7.18704223 L0.434417734,7.69104004 C0.5555725,7.52975465 0.704650859,7.38769531 0.879211406,7.27355957 L9.03793336,1.93893434 C9.62249758,1.55700684 10.3775024,1.55700684 10.9617615,1.93878172 L19.1207886,7.27355957 C19.2951965,7.38769531 19.4444275,7.52960203 19.5655823,7.69104004 L19.9015808,7.18704223 C19.987793,7.05780031 20.0190735,6.89956664 19.9885559,6.74713133 Z" id="Path"></path>
      </g>
  </g>
</svg>`;
};

export default UpcountryHubSVG;

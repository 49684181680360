import Spiderfy from '@components/common/UIMap/MarkerIcons/SpiderfySVG';
import DropoffSVG from '@components/common/UIMap/MarkerIcons/DropoffSVG';
import BlankLocationSVG from '@components/common/UIMap/MarkerIcons/BlankLocationSVG';
import CarSVG from '@components/common/UIMap/MarkerIcons/CarSVG';
import tileStyle from '@components/common/UIMap/utils/tileStyle';
import defaultTheme from '@mapbox/mapbox-gl-draw/src/lib/theme';
import DropoffSVGShadow from '../MarkerIcons/DropoffSVGShadow';
import BlankLocationSVGShadow from '../MarkerIcons/BlankLocationSVGShadow';
import PickupSVG from '@components/common/UIMap/MarkerIcons/PickupSVG';

const HubIcon = require('@components/common/UIMap/MarkerIcons/od_hub.png');

export const DEFAULT_ICONS = [
  {
    src: HubIcon,
    name: 'od_organisation_logo',
    isSdf: false,
    imageHeight: 200,
    imageWidth: 200,
    maintainRatio: true,
  },
];

export const DYNAMIC_SVG_ICONS = {
  spiderfy: {
    id: 'spiderfy',
    src: Spiderfy,
    isSdf: false,
    imageHeight: 31,
    imageWidth: 31,
    maintainRatio: true,
  },
  car_icon: {
    id: 'car_icon',
    src: CarSVG,
    isSdf: false,
    imageHeight: 200,
    imageWidth: 200,
    maintainRatio: true,
  },
  od_delivery_location: {
    id: 'od_delivery_location',
    src: DropoffSVG,
    isSdf: false,
    imageHeight: 39,
    imageWidth: 39,
    maintainRatio: true,
  },
  blank_location: {
    id: 'blank_location',
    src: BlankLocationSVG,
    imageHeight: 39,
    imageWidth: 39,
    maintainRatio: true,
  },
  od_delivery_location_shadow: {
    id: 'od_delivery_location_shadow',
    src: DropoffSVGShadow,
    isSdf: false,
    imageHeight: 39,
    imageWidth: 39,
    maintainRatio: true,
  },
  blank_location_shadow: {
    id: 'blank_location_shadow',
    src: BlankLocationSVGShadow,
    imageHeight: 39,
    imageWidth: 39,
    maintainRatio: true,
  },
  od_pickup_location: {
    id: 'od_pickup_location',
    src: PickupSVG,
    isSdf: false,
    imageHeight: 39,
    imageWidth: 39,
    maintainRatio: true,
  },
};

export const MAP_DEFAULT_CONFIG = {
  id: 'map',
  mapStyle: tileStyle,
  initialViewState: {
    latitude: 37.8,
    longitude: -122.4,
    zoom: 14,
  },
  images: DEFAULT_ICONS,
};

export const SPIDERFY_DEFAULT_CONFIG = {
  minZoomLevel: 12,
  zoomIncrement: 2,
  closeOnLeafClick: false,
  circleSpiralSwitchover: 1,
  circleOptions: {
    leavesSeparation: 30,
    leavesOffset: [10, 10],
  },
  zoomLevel: 3,
  spiralOptions: {
    legLengthStart: 50,
    legLengthFactor: 2.2,
    leavesSeparation: 50,
    leavesOffset: [0, 0],
  },
  spiderLegsAreHidden: false,
  spiderLegsWidth: 2,
  spiderLegsColor: 'black',
  spiderLeavesLayout: {
    'icon-image': [
      'concat',
      ['coalesce', ['get', 'icon'], 'od_delivery_location'],
      ['coalesce', ['get', 'color'], '#000'],
    ],
    'icon-size': ['coalesce', ['get', 'size'], 1],
    'icon-allow-overlap': true,
  },
  spiderLeavesPaint: {
    'icon-color': ['coalesce', ['get', 'color'], '#000'],
  },
};

export const DEFAULT_SOURCE =
  'https://raw.githubusercontent.com/nazka/map-gl-js-spiderfy/dev/demo/json/sample-data.json';

export const UIMAP_EDITOR_STYLES = [
  ...defaultTheme,
  {
    id: 'gl-draw-polygon-color-picker',
    type: 'fill',
    filter: ['all', ['==', '$type', 'Polygon'], ['has', 'user_color']],
    paint: {
      'fill-color': ['get', 'user_color'],
      'fill-outline-color': ['get', 'user_color'],
      'fill-opacity': 0.5,
    },
  },
];

import React from 'react';
import BulkUploadConsignment from 'src/components/pages/retail/dashboard/BulkUploadConsignment';
import ScanSelectedModal from 'src/components/pages/GenericConsignment/CustomActionBar/ScanSelectedModal';
import BulkUploadModal from 'src/components/pages/GenericConsignment/CustomActionBar/BulkUploadModal';
import InventoryInscanModal from 'src/components/pages/retail/dashboard/InventoryInscanModal/InventoryInscanModal'
import QcCheckModal from 'src/components/pages/retail/dashboard/QcCheckModal/QcCheckModal';
import OutscanModal from 'src/components/pages/GenericConsignment/actions/OutscanModal';
import CreateRouteModal from 'src/components/pages/retail/dashboard/CreateRouteModal';
import BulkCreateRouteModal from 'src/components/pages/retail/trip/BulkCreateRouteModal';
import { getAutoAllocateRequestsPageRoute, getCustomTripRequestsRoute, getPrintLabelRequestsRoute } from 'src/routing/utils';
import {
  arrivedAtReturnHub,
  arrivedInCountry,
  bulkCNLabelPrint,
  consignmentStatusUpdate,
  inscanAtHub,
  markArrival,
  markConsignmentAsRTO,
  markDelivered,
  markNpr,
  markPickupAndDelivery,
  outscanAtHub,
  pickedUpInscanAtHub,
  printManifestDRS,
  printPOD,
  ptlConsignmentInscan,
  releaseOnhold,
  revokePickupAndDelivery,
  setOnhold,
} from 'src/api/genericConsignmentView';
import { applyExceptionOnConsignments, markCancel, markCnEligibleForLmPlanning, markConsignmentAsPickupCompleted, releaseExceptionOnConsignments } from 'src/api/dashboard';
import DownloadsModal from './components/DownloadsModal';
import CustomTripCreation from 'src/components/pages/retail/dashboard/CustomTripCreationModal';
import BulkTripUploadModal from 'src/components/pages/retail/trip/BulkTripUploadModal';
import { getTMSCreateBagRoute, getTMSDeBagRoute } from 'src/components/pages/TMSDashboard/routes';
import { bulkManualTrip } from 'src/api/trips';

const createRouteChildren = (props) => [
  {
    id: 'all',
    label: 'all',
    modalComponent: CreateRouteModal,
    extraProps: {
      isAll: true,
      ...props,
    },
  },
  {
    id: 'upload',
    label: 'upload',
    modalComponent: BulkCreateRouteModal,
    extraProps: {
      ...props,
    },
  },
  {
    id: 'selected',
    label: 'Selected',
    modalComponent: CreateRouteModal,
    extraProps: {
      ...props,
    },
    requireRows: true,
  },
  {
    id: 'all_filtered',
    label: 'All Filtered',
    modalComponent: CreateRouteModal,
    extraProps: {
      isAll: true,
      isFiltered: true,
      ...props,
    },
  },
  {
    id: 'view_requests',
    label: 'View Requests',
    type: 'route',
    route: getAutoAllocateRequestsPageRoute,
  },
];

const defaultSuccessMessage = 'Selected consignments have been';

export const defaultSampleLink =
  'https://shipsy-public-assets.s3-us-west-2.amazonaws.com/shipsy/' +
  'sample-downloads/ops/bulk_scan_sample.xlsx';

export const sampleLinkWithNotes =
  'https://shipsy-public-assets.s3-us-west-2.amazonaws.com/shipsy/' +
  'sample-downloads/ops/bulk_update_with_notes.xlsx';

const genericBulkScanActions = (
    title,
    parentId,
    status = '',
    actionId?: any,
) => {
    return {
        id: parentId + '_selected',
        label: 'Selected',
        modalComponent: ScanSelectedModal,
        title,
        successMessage: defaultSuccessMessage + status,
        extraProps: {
            actionStatus: actionId,
        },
    };
};

const genericBulkUploadActions = (
    title,
    parentId,
    status = '',
    actionId?: any,
  ) => {
    return {
      id: parentId + '_bulk_upload',
      label: 'Bulk Upload',
      modalComponent: BulkUploadModal,
      title,
      successMessage: defaultSuccessMessage + status,
      extraProps: {
        actionStatus: actionId,
      },
    };
  };

const actionConfig = {
    addCN: {
        id: 'addCN',
        label: 'add',
        modalComponent: BulkUploadConsignment, // for modal components
    },
    cn_label: {
        id: 'cn_label',
        label: 'print_label',
        children: [
            {
                ...genericBulkScanActions('Print Label', 'cn_label'),
                actionApiCall: bulkCNLabelPrint,
                successMessage:
                    'Print consignment label request generated successfully',
                routeOnSuccess: getPrintLabelRequestsRoute(),
                extraProps: {
                    bulkLabelType: 'THIRD_PARTY_CONSIGNMENT_LABEL',
                }
            },
            {
                ...genericBulkUploadActions('Print Label', 'cn_label'),
                actionApiCall: bulkCNLabelPrint,
                successMessage:
                    'Print consignment label request generated successfully',
                routeOnSuccess: getPrintLabelRequestsRoute(),
                sampleLink:
                    'https://shipsy-public-assets.s3.us-west-2.amazonaws.com/shipper-dashboard' +
                    '/sample_consignment_list.xlsx',
                extraProps: {
                    bulkLabelType: 'THIRD_PARTY_CONSIGNMENT_LABEL',
                },
            },
        ],
    },
    inventory_inscan: {
        id: 'inventory_inscan',
        label: 'inventory_inscan',
        modalComponent: InventoryInscanModal,
    },
    qc_check: {
        id: 'qc_check',
        label: 'qc_check',
        children: [
            {
                ...genericBulkScanActions('CN Verification', 'qc_check'),
                modalComponent: QcCheckModal,
            },
            {
                ...genericBulkUploadActions('CN Verification', 'qc_check'),
                modalComponent: BulkUploadConsignment,
                extraProps: {
                    cnVerificationViaExcel: true,
                },
            },
        ],
    },
    unassign: {
        ...genericBulkScanActions('Unassign Consignments', 'unassign'),
        id: 'unassign',
        label: 'unassign',
        okProps: {
            isBulkDeleteCNModalVisible: true,
        },
    },
    pickedup_consignment_inscan: {
        ...genericBulkScanActions(
            'Inscan at Hub',
            'pickedup_consignment_inscan',
            ' marked inscan at hub',
        ),
        id: 'pickedup_consignment_inscan',
        label: 'pickedup_consignment_inscan',
        actionApiCall: pickedUpInscanAtHub,
    },
    arrival: {
        id: 'arrival',
        label: 'arrival',
        children: [
            {
            ...genericBulkScanActions('Arrival', 'arrival', ' added to hub'),
            actionApiCall: markArrival,
            },
            {
            ...genericBulkUploadActions('Arrival', 'arrival', ' added to hub'),
            actionApiCall: markArrival,
            sampleLink: defaultSampleLink,
            },
        ],
    },
    outscan: {
        id: 'outscan',
        label: 'outscan_at_hub_tms_status',
        children: [
          {
            ...genericBulkScanActions('', 'outscan'),
            modalComponent: OutscanModal,
          },
          {
            ...genericBulkUploadActions('OutScan at hub', 'outscan', ' outscaned'),
            actionApiCall: outscanAtHub,
            sampleLink: defaultSampleLink,
          },
        ],
      },
    inscan: {
        id: 'inscan',
        label: 'Inscan at hub',
        children: [
          {
            ...genericBulkScanActions(
              'Inscan at Hub',
              'inscan',
              ' marked inscan at hub',
            ),
            actionApiCall: inscanAtHub,
          },
          {
            ...genericBulkUploadActions(
              'Inscan at hub',
              'inscan',
              ' marked inscan at hub',
              'inscan_at_hub',
            ),
            showNotes: true,
            actionApiCall: inscanAtHub,
          },
        ],
      },
    arrived_in_country: {
        id: 'arrived_in_country',
        label: 'Arrived In Country',
        children: [
          {
            ...genericBulkScanActions(
              'Mark Arrived In Country',
              'arrived_in_country',
              ' marked arrived in country',
            ),
            actionApiCall: arrivedInCountry,
          },
          {
            ...genericBulkUploadActions(
              'Mark Arrived In Country',
              'arrived_in_country',
              ' marked arrived in country',
              'arrived_in_country',
            ),
            actionApiCall: arrivedInCountry,
            sampleLink: defaultSampleLink,
          },
        ],
      },
    arrived_at_return_hub: {
        id: 'arrived_at_return_hub',
        label: 'Arrived At Return Hub',
        children: [
          {
            ...genericBulkScanActions(
              'Mark Arrived At Return Hub',
              'arrived_at_return_hub',
              ' marked arrived at return hub',
            ),
            actionApiCall: arrivedAtReturnHub,
          },
          {
            ...genericBulkUploadActions(
              'Mark Arrived At Return Hub',
              'arrived_at_return_hub',
              ' marked arrived at return hub',
              'arrived_at_return_hub',
            ),
            actionApiCall: arrivedAtReturnHub,
            sampleLink: defaultSampleLink,
          },
        ],
      },
    createRoute: {
      id: 'createRoute',
      label: 'createRoute',
      children: createRouteChildren({
        actionType: 'create_route',
      }),
    },
    addToTrip: {
      id: 'addToTrip',
      label: 'addToTrip',
      children: createRouteChildren({
        actionType: 'add_to_existing',
      }),
    },
    mark_delivered: {
      id: 'mark_delivered',
      label: 'mark_delivered',
      children: [
        {
          ...genericBulkScanActions(
            'Mark Delivered',
            'mark_delivered',
            ' marked delivered',
          ),
          actionApiCall: markDelivered,
          showPodModal: true,
        },
        {
          ...genericBulkUploadActions(
            'Mark Delivered',
            'mark_delivered',
            ' marked delivered',
          ),
          actionApiCall: markDelivered,
          useSampleApiForSampleFile: true
        },
      ],
    },
    mark_rto: {
      id: 'mark_rto',
      label: 'Mark RTO',
      children: [
        {
          ...genericBulkScanActions(
            'Mark RTO',
            'mark_rto',
            ' marked as RTO',
            'rto',
          ),
          showNotes: true,
          actionApiCall: markConsignmentAsRTO,
        },
        {
          ...genericBulkUploadActions(
            'Mark RTO',
            'mark_rto',
            ' marked as RTO',
            'rto',
          ),
          showNotes: true,
          actionApiCall: markConsignmentAsRTO,
        },
      ],
    },
    mark_onhold: {
      id: 'mark_onhold',
      label: 'Mark OnHold',
      children: [
        {
          ...genericBulkScanActions(
            'Mark OnHold',
            'mark_onhold',
            ' marked as Onhold',
            'mark_on_hold',
          ),
          showNotes: true,
          actionApiCall: setOnhold,
        },
        {
          ...genericBulkUploadActions(
            'Mark OnHold',
            'mark_onhold',
            ' marked as Onhold',
            'mark_on_hold',
          ),
          showNotes: true,
          actionApiCall: setOnhold,
        },
      ],
    },
    release_onhold: {
      id: 'release_onhold',
      label: 'release_onhold_dashboard_menu',
      children: [
        {
          ...genericBulkScanActions(
            'Release OnHold',
            'release_onhold',
            ' released from onhold',
            'release_on_hold',
          ),
          showNotes: true,
          actionApiCall: releaseOnhold,
        },
        {
          ...genericBulkUploadActions(
            'Release OnHold',
            'release_onhold',
            ' released from onhold',
            'release_on_hold',
          ),
          showNotes: true,
          actionApiCall: releaseOnhold,
        },
      ],
    },
    mark_cn_eligible_for_lm_planning: {
      id: 'mark_cn_eligible_for_lm_planning',
      label: 'Mark Consignment Eligibility for LM Planning',
      children: [
        {
          ...genericBulkUploadActions(
            'Mark Consignment Eligibility for LM Planning',
            'mark_cn_eligible_for_lm_planning',
            ' marked as according to eligiblity status for last mile planning',
            'mark_cn_eligible_for_lm_planning',
          ),
          actionApiCall: markCnEligibleForLmPlanning,
          useSampleApiForSampleFile: true,
        },
      ],
    },
    consignment_sieze: {
      id: 'consignment_sieze',
      label: 'Consignment Sieze',
      children: [
        {
          ...genericBulkScanActions(
            'Consignment Sieze',
            'consignment_sieze',
            ' marked as Seized',
            'seized',
          ),
          showNotes: true,
          actionApiCall: consignmentStatusUpdate,
        },
        {
          ...genericBulkUploadActions(
            'Consignment Sieze',
            'consignment_sieze',
            ' marked as Seized',
            'seized',
          ),
          showNotes: true,
          actionApiCall: consignmentStatusUpdate,
        },
      ],
    },
    mark_clearance_origin: {
      id: 'mark_clearance_origin',
      label: 'Mark Clearance at Origin',
      children: [
        {
          ...genericBulkScanActions(
            'Mark Clearance at Origin',
            'mark_clearance_origin',
            ' marked cleared at origin',
            'mark_clearance_at_origin',
          ),
          showNotes: true,
          actionApiCall: consignmentStatusUpdate,
        },
        {
          ...genericBulkUploadActions(
            'Mark Clearance at Origin',
            'mark_clearance_origin',
            ' marked cleared at origin',
            'mark_clearance_at_origin',
          ),
          showNotes: true,
          actionApiCall: consignmentStatusUpdate,
        },
      ],
    },
    mark_clearance_destination: {
      id: 'mark_clearance_destination',
      label: 'Mark Clearance at Destination',
      children: [
        {
          ...genericBulkScanActions(
            'Mark Clearance at Destination',
            'mark_clearance_destination',
            ' marked cleared at destination',
            'mark_clearance_at_destination',
          ),
          showNotes: true,
          actionApiCall: consignmentStatusUpdate,
        },
        {
          ...genericBulkUploadActions(
            'Mark Clearance at Destination',
            'mark_clearance_destination',
            ' marked cleared at destination',
            'mark_clearance_at_destination',
          ),
          showNotes: true,
          actionApiCall: consignmentStatusUpdate,
        },
      ],
    },
    inscan_at_gateway: {
      id: 'inscan_at_gateway',
      label: 'inscan_at_gateway',
      children: [
        {
          ...genericBulkScanActions(
            'Inscan at Gateway',
            'inscan_at_gateway',
            ' marked inscan at gateway',
            'inscan_at_gateway',
          ),
          showNotes: true,
          actionApiCall: consignmentStatusUpdate,
        },
        {
          ...genericBulkUploadActions(
            'Inscan at Gateway',
            'inscan_at_gateway',
            ' marked inscan at gateway',
            'inscan_at_gateway',
          ),
          showNotes: true,
          actionApiCall: consignmentStatusUpdate,
        },
      ],
    },
    mark_npr: {
      id: 'mark_npr',
      label: 'mark_npr',
      children: [
        {
          ...genericBulkScanActions('Mark NPR', 'mark_npr', ' marked as NPR'),
          actionApiCall: markNpr,
          requireReason: true,
        },
        {
          ...genericBulkUploadActions('Mark NPR', 'mark_npr', ' marked as NPR'),
          actionApiCall: markNpr,
          sampleLink: defaultSampleLink,
          requireReason: true,
        },
      ],
    },
    cancel: {
      id: 'cancel',
      label: 'cancel',
      children: [
        {
          ...genericBulkScanActions(
            'Cancel',
            'cancel',
            ' cancelled successfully',
          ),
          requireReason: true,
          actionApiCall: markCancel,
        },
        {
          ...genericBulkUploadActions(
            'Cancel',
            'cancel',
            ' cancelled successfully',
          ),
          requireReason: true,
          actionApiCall: markCancel,
          sampleLink: defaultSampleLink,
        },
      ],
    },
    cn_copy_label: {
      id: 'cn_copy_label',
      label: 'Print Consignment Copy Label',
      modalComponent: ScanSelectedModal,
      extraProps: {
        bulkLabelType: 'CONSIGNMENT_COPY',
      },
      title: 'Print Label',
      actionApiCall: bulkCNLabelPrint,
      successMessage: 'Print consignment label request generated successfully',
      routeOnSuccess: getPrintLabelRequestsRoute(),
    },
    download: {
      id: 'download',
      label: 'Download',
      modalComponent: DownloadsModal,
      extraProps: {
        objectData: true,
      },
    },
    moveFailedCns: {
      ...genericBulkScanActions('Move to Unplanned', 'moveFailedCns'),
      id: 'moveFailedCns',
      label: 'Move to Unplanned',
      okProps: {
        isMoveCNModalVisible: true,
      },
    },
    print_manifest_drs: {
      id: 'print_manifest_drs',
      label: 'print_manifest_tms_action',
      children: [
        {
          ...genericBulkScanActions('Print Manifest Drs', 'print_manifest_drs'),
          actionApiCall: printManifestDRS,
          successMessage: 'Print manifest drs generated successfully',
          downloadData: true,
        },
      ],
    },
    ptl_consignment_inscan: {
      ...genericBulkScanActions(
        'Inscan at Hub',
        'ptl_consignment_inscan',
        ' marked inscan at hub',
      ),
      id: 'ptl_consignment_inscan',
      label: 'PTL Consignment Inscan',
      actionApiCall: ptlConsignmentInscan,
    },
    create_bag: {
      id: 'create_bag',
      label: 'create_bag',
      type: 'route',
      route: getTMSCreateBagRoute,
    },
    de_bag: {
      id: 'de_bag',
      label: 'de_bag',
      type: 'route',
      route: getTMSDeBagRoute,
    },
    createCustomTrip: {
      id: 'createCustomTrip',
      label: 'createCustomTrip',
      children: [
        {
          id: 'all',
          label: 'all',
          modalComponent: CustomTripCreation,
          extraProps: {
            action: 'create_custom_trip',
            tripCreationType: 'all',
          },
        },
        {
          id: 'filtered',
          label: 'All Filtered',
          modalComponent: CustomTripCreation,
          extraProps: {
            action: 'create_custom_trip',
            tripCreationType: 'filtered',
          },
        },
        {
          id: 'selected',
          label: 'Scan/Selected',
          modalComponent: CustomTripCreation,
          extraProps: {
            action: 'create_custom_trip',
            tripCreationType: 'selected',
          },
        },
        {
          id: 'upload',
          label: 'upload',
          modalComponent: BulkCreateRouteModal,
          extraProps: {
            action: 'create_custom_trip',
            tripCreationType: 'upload',
          },
        },
        {
          id: 'view_requests',
          label: 'View Requests',
          type: 'route',
          route: getCustomTripRequestsRoute,
          extraProps: {
            action: 'create_custom_trip',
          },
        },
        {
          id: 'bulk_trip',
          label: 'Bulk Trip',
          modalComponent: BulkTripUploadModal,
          requireOption: true,
          actionApiCall: bulkManualTrip,
          extraProps: {
            action: 'create_custom_trip',
            tripCreationType: 'bulk',
          },
        },
      ],
    },
    credit_note: {
      id: 'credit_note',
      label: 'credit_note',
      children: [
        {
          ...genericBulkScanActions('Credit Note', 'credit_note'),
          actionApiCall: bulkCNLabelPrint,
          successMessage: 'Print credit note request generated successfully',
          routeOnSuccess: getPrintLabelRequestsRoute(),
          extraProps: {
            bulkLabelType: 'BULK_CREDIT_NOTE',
          },
        },
        {
          ...genericBulkUploadActions('Credit Note', 'credit_note'),
          actionApiCall: bulkCNLabelPrint,
          successMessage: 'Print credit note request generated successfully',
          routeOnSuccess: getPrintLabelRequestsRoute(),
          sampleLink:
            'https://shipsy-public-assets.s3.us-west-2.amazonaws.com/shipper-dashboard' +
            '/sample_consignment_list.xlsx',
          extraProps: {
            bulkLabelType: 'BULK_CREDIT_NOTE',
          },
        },
      ],
    },
    print_pod: {
      id: 'print_pod',
      label: 'Print POD',
      children: [
        {
          ...genericBulkScanActions('Print POD', 'print_pod', '', 'print_pod'),
          actionApiCall: printPOD,
          successMessage: 'Print POD generated successfully',
          downloadData: true,
        },
      ],
    },
    apply_exception: {
      id: 'apply_exception',
      label: 'apply_exception_dashboard_menu',
      children: [
        {
          ...genericBulkScanActions(
            'Apply Exception',
            'apply_exception',
            ' marked as Exception',
            'apply_exception',
          ),
          actionApiCall: applyExceptionOnConsignments,
          showExceptionCodeWithNotes: true,
          showNotes: true,
        },
        {
          ...genericBulkUploadActions(
            'Apply Exception',
            'apply_exception',
            ' marked as Exception',
            'apply_exception',
          ),
          actionApiCall: applyExceptionOnConsignments,
          showExceptionCodeWithNotes: true,
          sampleLink:
            'https://shipsy-public-assets.s3-us-west-2.amazonaws.com/shipsy/' +
            'sample-downloads/ops/bulk_scan_sample_with_exception_code.xlsx',
        },
      ],
    },
    release_exception: {
      id: 'release_exception',
      label: 'release_exception',
      children: [
        {
          ...genericBulkScanActions(
            'Release Exception',
            'release_exception',
            ' marked as Exception Released',
            'release_exception',
          ),
          actionApiCall: releaseExceptionOnConsignments,
          showNotes: true,
        },
        {
          ...genericBulkUploadActions(
            'Release Exception',
            'release_exception',
            ' marked as Exception Released',
            'release_exception',
          ),
          actionApiCall: releaseExceptionOnConsignments,
          showNotes: true,
        },
      ],
    },
    pickup_completed: {
      id: 'pickup_completed',
      label: 'Pickup Completed',
      children: [
        {
          ...genericBulkScanActions(
            'Pickup Completed',
            'pickup_completed',
            ' marked a pickup completed',
          ),
          actionApiCall: markConsignmentAsPickupCompleted,
        },
        {
          ...genericBulkUploadActions(
            'Pickup Completed',
            'pickup_completed',
            ' marked a pickup completed',
          ),
          actionApiCall: markConsignmentAsPickupCompleted,
          sampleLink: defaultSampleLink,
        },
      ],
    },
    pickup_and_delivery: {
      id: 'pickup_and_delivery',
      label: 'Mark Hyperlocal',
      children: [
        {
          ...genericBulkScanActions(
            'Mark Hyperlocal',
            'pickup_and_delivery',
            ' marked as Hyperlocal',
            'pickup_and_delivery',
          ),
          showNotes: true,
          actionApiCall: markPickupAndDelivery,
        },
        {
          ...genericBulkUploadActions(
            'Mark Hyperlocal',
            'pickup_and_delivery',
            ' marked as Hyperlocal',
            'pickup_and_delivery',
          ),
          showNotes: true,
          actionApiCall: markPickupAndDelivery,
        },
      ],
    },
    revoke_pickup_and_delivery: {
      id: 'revoke_pickup_and_delivery',
      label: 'Revoke Hyperlocal',
      children: [
        {
          ...genericBulkScanActions(
            'Revoke Hyperlocal',
            'revoke_pickup_and_delivery',
            ' Revoked as Hyperlocal',
            'revoke_pickup_and_delivery',
          ),
          showNotes: true,
          actionApiCall: revokePickupAndDelivery,
        },
        {
          ...genericBulkUploadActions(
            'Revoke Hyperlocal',
            'revoke_pickup_and_delivery',
            ' Revoked as Hyperlocal',
            'revoke_pickup_and_delivery',
          ),
          showNotes: true,
          actionApiCall: revokePickupAndDelivery,
        },
      ],
    },
};

export default actionConfig;

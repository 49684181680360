import * as React from 'react';
import { Layer, Source } from 'react-map-gl';

import { IUIMapPolygon } from '@components/common/UIMap/utils/types';

const UIMapPolygon = (props: IUIMapPolygon) => {
  const { sourceId, mapKey, data } = props;

  const outlineLayerProps: any = {
    type: 'line',
    source: sourceId,
    layout: {},
    paint: {
      'line-color': '#000',
      'line-width': 1,
    },
  };

  const polygonProps: any = {
    type: 'fill',
    source: sourceId,
    layout: {},
    paint: {
      'fill-color': ['get', 'color'],
      'fill-opacity': ['coalesce', ['get', 'opacity'], 0.5],
    },
  };

  return (
    <Source id={sourceId} key={mapKey} type="geojson" data={data}>
      <Layer id={sourceId + '-outline-layer'} {...outlineLayerProps} />
      <Layer id={sourceId + '-polygon-layer'} {...polygonProps} />
    </Source>
  );
};

export default UIMapPolygon;

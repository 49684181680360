import { API_PLAYGROUND_CONFIG, QUERYBUILDER_CONFIG, LIA_CO_PILOT_CONFIG } from '../../../globals';
import { LocalStorageKeys, LocalStorageService } from 'src/services/localStorage';
interface IAppMetadataInfo {
  iframeId: string;
  origin: string;
  url: string;
  passCredentials?: boolean;
  basePath: string;
}

interface IAppMetaData {
  [x: string]: IAppMetadataInfo;
}
export const API_PLAYGROUND_BASE_PATH = '/apidocs';
export const LIA_CO_PILOT_BASE_PATH = '/co-pilot';
export enum IframeApps {
  OpsDashboard = 'ops_dashboard',
  AnalyticsDashboard = 'analytics_dashboard',
  GENERIC_ANALYTICS = 'GENERIC_ANALYTICS',
  API_PLAYGROUND = 'API_PLAYGROUND',
  LIA_CO_PILOT = 'LIA_CO_PILOT',
}
const organisation_id = LocalStorageService.getRaw(LocalStorageKeys.__ORGANISATION_ID__);

export const AppMetadata: IAppMetaData = {
  [IframeApps.OpsDashboard]: {
    iframeId: 'persistent-iframe-ops-dashboard',
    origin: window.location.origin,
    url: `${window.location.origin}/dashboardold/?inside_react_app=true`,
    basePath: '',
  },
  [IframeApps.AnalyticsDashboard]: {
    iframeId: 'persistent-iframe-analytics-dashboard',
    origin: window.location.origin,
    url: `${window.location.origin}/analytics`,
    basePath: '',
  },
  [IframeApps.GENERIC_ANALYTICS]: {
    iframeId: 'persistent-iframe-generic-analytics',
    origin: `https://${organisation_id}.${QUERYBUILDER_CONFIG.ANALYTICS_FRONTEND_URL}`,
    url: `https://${organisation_id}.${QUERYBUILDER_CONFIG.ANALYTICS_FRONTEND_URL}`,
    basePath: '/analytics',
    passCredentials: true,
  },
  [IframeApps.API_PLAYGROUND]: {
    iframeId: 'persistent-iframe-api_playground',
    origin: `${API_PLAYGROUND_CONFIG.URL}`,
    url: `${API_PLAYGROUND_CONFIG.URL}`,
    basePath: API_PLAYGROUND_BASE_PATH,
    passCredentials: true,
  },
  [IframeApps.LIA_CO_PILOT]: {
    iframeId: 'persistent-iframe-lia-co-pilot',
    origin: `https://${organisation_id}.${LIA_CO_PILOT_CONFIG?.URL}`,
    url: `https://${organisation_id}.${LIA_CO_PILOT_CONFIG?.URL}`,
    basePath: LIA_CO_PILOT_BASE_PATH,
    passCredentials: true,
  },
};

export enum IncomingActions {
  Loaded = 'LOADED',
  ROUTING = 'ROUTING',
  LOGOUT = 'LOGOUT',
}

export enum OutgoingActions {
  Routing = 'ROUTING',
  CREDENTIALS_SHARING = 'CREDENTIALS_SHARING',
}

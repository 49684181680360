import withStyles from 'react-jss';
import React, { useState } from 'react';
import { Drawer, Tabs } from 'antd';
import { CssType, ThemeType } from '@theming/jssTypes';
const helpIcon = require('@assets/images/virtual-assistant.svg');
const { TabPane } = Tabs;
import {
  ArrowRightOutlined,
  GiftOutlined,
  QuestionCircleOutlined,
  LinkOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import Loader from '@components/common/Loader';
import { isMobile } from 'react-device-detect';

const productUrl = 'https://product.shipsy.io/';
const helpUrl = 'https://shipsy-helpdesk.refined.site/space/HELP';

const styles = (theme: ThemeType): CssType => ({
  drawer: {
    '& .ant-drawer-body': {
      padding: 0,
    },
    '& .ant-tabs': {
      backgroundColor: theme.colors.globalHeader,
      color: theme.colors.textOnDarkBg,
      height: '100%',
    },
    '& .ant-tabs-content': {
      height: '100%',
    },
    '& .ant-drawer-header': {
      backgroundColor: theme.colors.globalHeader,
      '& .anticon svg': {
        color: theme.colors.textOnDarkBg,
      }
    },
    '& .ant-drawer-title': {
      color: theme.colors.textOnDarkBg,
    }
  },
  tabpane: {
    padding: '5px',
  },
  iframeContainer: {
    height: '100%',
  },
  iframe: {
    height: '100%',
    width: '100%',
    border: 'none',
  },
  viewAll: {
    fontSize: theme.sizes.bodyText,
    position: 'absolute',
    top: '120px',
    right: '5px',
  },
  help: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  helpText: {
    color: theme.colors.primaryColor,
    fontSize: theme.sizes.bodyTextLarge,
  },
  helpLink: {
    fontSize: theme.sizes.heading,
  },
  tabContainer: {
    '& .ant-tabs-nav-list': {
      width: '100%',
    },
    '& .ant-tabs-tab': {
      width: '50%',
      justifyContent: 'center',
    },
  },
  helpHeaderStyle: {
    backgroundColor: theme.colors.globalHeader,
  }
});
const HelpCenterDrawer = (props: any) => {
  const { classes, isVisible, onModalClose } = props;
  const [loading, setLoading] = useState(true);

  const renderUpdates = () => {
    return (
      <div className={classes.iframeContainer}>
        <div className={classes.viewAll}>
          <a target="_blank" href={productUrl}>
            View All <ArrowRightOutlined />
          </a>
        </div>
        <iframe
          title={'Product Board'}
          className={classes.iframe}
          src={productUrl}
          onLoad={() => setLoading(false)}
        ></iframe>
        {loading ? <Loader /> : null}
      </div>
    );
  };

  const renderHelp = () => {
    return (
      <div className={classes.help}>
        <div>
          <img src={helpIcon} />
        </div>
        <div className={classes.helpText}>
          Detailed step-by-step guide available on
        </div>
        <div className={classes.helpLink}>
          <a target="_blank" href={helpUrl}>
            Help Centre <LinkOutlined />
          </a>
        </div>
      </div>
    );
  };

  return (
    <Drawer
      width={isMobile ? "80%" : "30%"}
      visible={isVisible}
      closable={false}
      onClose={onModalClose}
      title={'Updates and Help Centre'}
      className={classes.drawer}
      extra={<CloseOutlined onClick={onModalClose} />}
    >
      <Tabs
        defaultActiveKey={'updates'}
        type="line"
        {...props}
        className={classes.tabContainer}
      >
        <TabPane
          tab={
            <span className={classes.tabpane}>
              <GiftOutlined />
              What's New
            </span>
          }
          key={'updates'}
        >
          {renderUpdates()}
        </TabPane>
        <TabPane
          tab={
            <span className={classes.tabpane}>
              <QuestionCircleOutlined />
              Help Centre
            </span>
          }
          key={'help'}
        >
          {renderHelp()}
        </TabPane>
      </Tabs>
    </Drawer>
  );
};

export default withStyles(styles, { injectTheme: true })(HelpCenterDrawer);

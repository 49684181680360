import * as React from 'react';
import { message } from 'antd';
import url from 'url';
import Loader from '@components/common/Loader';
import styles from '@components/pages/LoginPageAuth/styles';
import withStyles from 'react-jss';
import { redirectToUrl, getURLConfigs } from '@api/utils';
import { getOrganisationDetails } from '@api/login';
import { LocalStorageKeys, LocalStorageService } from 'src/services/localStorage';
import Cookies from 'universal-cookie';

export const fetchOrganisationDetailsAndRedirect = async () => {
    const urlConfigs = getURLConfigs();
    const response = await getOrganisationDetails({
        organisationPrettyName: urlConfigs.organisationPrettyName,
        origin: urlConfigs.origin,
    });

    if (response.isSuccess) {
        window.location.href = redirectToUrl(response?.data);
    } else {
        window.location.href = '/';
    }
}

export const setLocalStorageDataFromJSON = async (dataToSet) => {
    const {
        organisationId,
        logoUrl,
        accessTokenId,
        accessTokenExpiry,
        employeeId,
        employeeFullName,
        faviconUrl,
        opsLoginHub,
        opsLoginRedirectTo,
        bookingVisible,
    } = dataToSet;
    if (accessTokenId) {
        await LocalStorageService.set(LocalStorageKeys.__AUTH_TOKEN__, accessTokenId);
        const cookies = new Cookies();
        cookies.set('is_authenticated', 'true', {
            path:'/',
            expires: new Date((new Date().getTime()) + (1000 * 60 * 60 * 24 * 365)) ,
            secure: true, sameSite: 'lax',
        });
    }
    if (organisationId) {
        await LocalStorageService.set(LocalStorageKeys.__ORGANISATION_ID__, organisationId);
    }
    if (employeeId) {
        await LocalStorageService.set(LocalStorageKeys.___USER_ID__, employeeId);
    }
    if (logoUrl) {
        await LocalStorageService.set(LocalStorageKeys.__LOGO_URL__, logoUrl);
    }
    if (opsLoginRedirectTo) {
        await LocalStorageService.set(LocalStorageKeys.__REDIRECT_TO_VIEW_TYPE__, opsLoginRedirectTo);
    }
    if (opsLoginHub) {
        await LocalStorageService.set(LocalStorageKeys.__REDIRECT_TO_HUB__, opsLoginHub);
    }
    if (employeeFullName) {
        await LocalStorageService.set(LocalStorageKeys.__NAME__, employeeFullName);
    }
    if (faviconUrl) {
        await LocalStorageService.set(LocalStorageKeys.__FAVICON_URL__, faviconUrl);
    }
    if (bookingVisible) {
        await LocalStorageService.set(LocalStorageKeys.__BOOKING_VISIBLE__, bookingVisible);
    }
};

const getValueFromSearchParams = (searchParams, key) => {
    if (searchParams.has(key)) {
        return searchParams.get(key);
    }
    return null;
};

const SSORedirect = (props) => {
    const { history, classes } = props;
    const { location } = window;
    const { href = '' } = location;
    const { query } = url.parse(href);
    const searchParams = new URLSearchParams(query);
    const error = getValueFromSearchParams(searchParams, 'error');
    const organisationId = getValueFromSearchParams(searchParams, 'organisation_id');
    const accessTokenId = getValueFromSearchParams(searchParams, 'access_token_id');
    const accessTokenExpiry = getValueFromSearchParams(searchParams, 'access_token_expiry');
    const employeeId = getValueFromSearchParams(searchParams, 'employee_id');
    const logoUrl = getValueFromSearchParams(searchParams, 'logo_url');
    const opsLoginHub = getValueFromSearchParams(searchParams, 'ops_login_hub');
    const opsLoginRedirectTo = getValueFromSearchParams(searchParams, 'ops_login_redirect_to');
    const employeeFullName = getValueFromSearchParams(searchParams, 'employee_full_name');
    const faviconUrl = getValueFromSearchParams(searchParams, 'favicon_url');
    const bookingVisible = getValueFromSearchParams(searchParams, 'booking_visible');

    if (error || !organisationId || !accessTokenId || !accessTokenExpiry || !employeeId) {
        message.error(error);
        window.location.href = '/login';
        return null;
    }

    const setDataAndOrgDetails = async () => {
        await setLocalStorageDataFromJSON({
            organisationId,
            logoUrl,
            accessTokenId,
            accessTokenExpiry,
            employeeId,
            employeeFullName,
            faviconUrl,
            opsLoginHub,
            opsLoginRedirectTo,
            bookingVisible,
        });

        fetchOrganisationDetailsAndRedirect();
    }

    setDataAndOrgDetails();

    return (
        <div className={classes.loaderContainer}>
            <Loader zIndex={100} />
        </div>
    );
};

export default withStyles(styles, { injectTheme: true })(SSORedirect);

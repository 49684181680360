import apiProvider from '../provider';
import {
  getAuthenticationHeaders,
  getErrorMessage,
} from '../utils';
import { API_BASE_URL, LTL_BASE_URL } from '../../globals';
import {
  GET_ALL_CONSIGNMENT_FOR_BAGGING,
  FETCH_BAG_DETAILS,
  TMS_CREATE_NEW_BAG,
  TMS_CREATE_BAG_SAVE_PROGRESS,
  TMS_SEAL_BAG,
  TMS_AUTOFILL_BAG_DESTINATION_HUBS,
  BAG_NUMBER_SEARCH,
  GET_AVAILABLE_CONSIGNMENT_FOR_DEBAG,
  DE_BAG,
  GET_AVAILABLE_CONSIGNMENTS_FOR_BAG,
  PRINT_MOTHER_BAGGING_LABEL,
  BAG_INSCAN_AT_HUB,
  BAG_DELETE_AT_HUB,
  BAG_OUTSCAN_AT_HUB,
  SCAN_ITEM_FOR_BAGGING,
  REMOVE_ITEM_FROM_BAGGING_DRAFT,
  SCAN_ITEM_FOR_DEBAGGING,
  REMOVE_ITEM_FROM_DEBAGGING_DRAFT,
  DOWNLOAD_BAG_REPORT,
  ASSIGN_BAG_THIRD_PARTY,
  ASSIGN_SCANNED_BAGS_TO_THIRD_PARTY,
  FETCH_BAGS_AVAIlABLE_FOR_HANDOVER,
  FETCH_COURIER_PARTNER_ACCOUNT,
  HANDOVER_SCANNED_BAGS_TO_THIRD_PARTY,
  GET_AVAILABLE_BAGS_FOR_COURIER_MANIFEST,
  UPDATE_COURIER_PARTNER_DETAILS,
  UPDATE_BAG_WEIGHTS,
  VALIDATE_BAG_CONSIGNMENTS,
  UPDATE_BAG,
  FETCH_BAG_VIRTUAL_SERIES,
  MARK_BAG_EXCEPTION,
} from '../routes';

import {
  requestModelTMSBag, responseModelTMSBags,
} from '../../model/tmsView';
import { morphism } from 'morphism';
import qs from 'qs';
import { GET, POST } from '../apiHandler';

export const getAllTmsDataBag = async (body) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_ALL_CONSIGNMENT_FOR_BAGGING}`,
      {
        params: {
          ...morphism(requestModelTMSBag, body),
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
        headers: await getAuthenticationHeaders(),
      },
    );

    return {
      data: morphism(responseModelTMSBags, response.data.data),
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const fetchBagDetails = async (val) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${FETCH_BAG_DETAILS}`,
      {
        params: {
          reference_number: val,
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const createNewBag = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${TMS_CREATE_NEW_BAG}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const saveBagProgress = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${TMS_CREATE_BAG_SAVE_PROGRESS}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const sealBag =  async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${TMS_SEAL_BAG}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const autoFillDestinationBagHubs = async (hubId, queryString?: string) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${TMS_AUTOFILL_BAG_DESTINATION_HUBS}`,
      {
        params: {
          hub_id: hubId,
          search: queryString,
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const bagNumberSearch = async(refNo, hubId) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${BAG_NUMBER_SEARCH}`,
      {
        params: {
          reference_number: refNo,
          hub_id: hubId,
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const getBagDetailsForDebag = async(refNo) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_AVAILABLE_CONSIGNMENT_FOR_DEBAG}`,
      {
        params: {
          reference_number: refNo,
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const deBag = async (
  body,
) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${DE_BAG}`, body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const getAllAvailableConsignmentsForBag = async(bagId, referenceNumber?: string) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_AVAILABLE_CONSIGNMENTS_FOR_BAG}`,
      {
        params: {
          bag_id: bagId,
          reference_number: referenceNumber,
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const printBaggingLabel =  async(params) => {
  return GET(`${API_BASE_URL}${PRINT_MOTHER_BAGGING_LABEL}`,params, 'blob');
};
export const bagInscanAtHub =  async(body) => {
  return POST(`${API_BASE_URL}${BAG_INSCAN_AT_HUB}`, body );
};

export const deleteBagAtHub =  async(body) => {
  return POST(`${API_BASE_URL}${BAG_DELETE_AT_HUB}`, body );
};

export const bagOutscanAtHub =  async(body) => {
  return POST(`${API_BASE_URL}${BAG_OUTSCAN_AT_HUB}`, body );
};

export const scanItemForBagging =  async(body) => {
  return POST(`${API_BASE_URL}${SCAN_ITEM_FOR_BAGGING}`, body );
};

export const removeItemFromBaggingDraft =  async(body) => {
  return POST(`${API_BASE_URL}${REMOVE_ITEM_FROM_BAGGING_DRAFT}`, body );
};

export const scanItemForDebagging =  async(body) => {
  return POST(`${API_BASE_URL}${SCAN_ITEM_FOR_DEBAGGING}`, body );
};

export const removeItemFromDebaggingDraft =  async(body) => {
  return POST(`${API_BASE_URL}${REMOVE_ITEM_FROM_DEBAGGING_DRAFT}`, body );
};

export const requestBagReportDownload = async (body) => {
  return POST(`${API_BASE_URL}${DOWNLOAD_BAG_REPORT}`, body );
};

export const assignBagThirdParty = async(params) => {
  return GET(`${API_BASE_URL}${ASSIGN_BAG_THIRD_PARTY}`,params);
};
export const assignScannedBagsToThirdParty = async(body) => {
  return POST(`${API_BASE_URL}${ASSIGN_SCANNED_BAGS_TO_THIRD_PARTY}`,body);
};
export const fetchAvailableBagsForHandover = async(params) => {
  return GET(`${API_BASE_URL}${FETCH_BAGS_AVAIlABLE_FOR_HANDOVER}`,params);
};
export const fetchCourierPartnerAccount = async (params = {}) => {
  return GET(`${LTL_BASE_URL}${FETCH_COURIER_PARTNER_ACCOUNT}`,params);
};
export const handoverScannedBagsToThirdParty = async(body) => {
  return POST(`${API_BASE_URL}${HANDOVER_SCANNED_BAGS_TO_THIRD_PARTY}`,body);
};
export const fetchAvailableBagsForCourierManifest = async(params) => {
  return GET(`${API_BASE_URL}${GET_AVAILABLE_BAGS_FOR_COURIER_MANIFEST}`,params);
};
export const updateCourierDetails = async(body) => {
  return POST(`${API_BASE_URL}${UPDATE_COURIER_PARTNER_DETAILS}`,body);
};
export const updateBagWeights = async(body) => {
  return POST(`${API_BASE_URL}${UPDATE_BAG_WEIGHTS}`, body);
};

export const validateBagConsignments = async(body) => {
  return POST(`${API_BASE_URL}${VALIDATE_BAG_CONSIGNMENTS}`, body);
};

export const updateBag = async(body) => {
  return POST(`${API_BASE_URL}${UPDATE_BAG}`, body);
};

export const fetchBagVirtualSeries = async() => {
  return GET(`${API_BASE_URL}${FETCH_BAG_VIRTUAL_SERIES}`);
};

export const markBagException = async(body) => {
  return POST(`${API_BASE_URL}${MARK_BAG_EXCEPTION}`, body);
};

import {
  downloadSampleCsvForCNV,
  downloadSampleCsvForConsignment,
  downloadSampleCsvForUpdateConsignment,
} from '../../../../api/dashboard';

export const errorColumnsForPieces = [
  {
    title: 'Row No.',
    dataIndex: 'row_num',
  },
  {
    title: 'Unique Id',
    dataIndex: 'unique_id',
  },
  {
    title: 'Error',
    dataIndex: 'reason',
  },
];
export const defaultErrorColumns = [
  {
    title: 'Consignments',
    dataIndex: 'reference_number',
  },
  {
    title: 'Error',
    dataIndex: 'message',
  },
];
export const errorColumnsForCNV = [
  {
    title: 'Consignments',
    dataIndex: 'reference_number',
  },
  {
    title: 'Error',
    dataIndex: 'message',
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
  },
];
export const cnvViaExcelMapping = {
  reference_number: 'Reference No',
  length: 'Length',
  width: 'Width',
  height: 'Height',
  dimension_unit: 'Dimension Unit',
  weight: 'Weight',
  weight_unit: 'Weight Unit',
  is_cnv_done: 'CNV',
};
const qcStatus = {
  qc_status: 'QC Status',
};
const markRtoOptions = {
  mark_as_rto: 'Mark As RTO',
  rto_reason: 'RTO Reason',
};
export const getCnvViaExcelMapping = (showQcStatus, showRtoReasons) => {
  let mapping = cnvViaExcelMapping;
  if (showQcStatus) {
    mapping = {
      ...mapping,
      ...qcStatus,
    };
  }
  if (showRtoReasons) {
    mapping = {
      ...mapping,
      ...markRtoOptions,
    };
  }
  return mapping;
};
export const sampleCSVForCNV =
  'https://shipsy-public-assets.s3-us-west-2.amazonaws.com/retail/sample-download/sample_bulk_cnv_upload.xlsx';
export const sampleCSVForExpressBulkUpload =
  'https://shipsy-public-assets.s3-us-west-2.amazonaws.com/retail/sample-download/sample_bulk_softdata_upload.xlsx';
export const sampleCSVWithPieces =
  'https://shipsy-public-assets.s3-us-west-2.amazonaws.com/retail/sample-download/sample_bulk_cn_upload_with_pieces.xlsx';
export const defaultSampleCSV =
  'https://shipsy-public-assets.s3-us-west-2.amazonaws.com/retail/sample-download/sample_bulk_cn_upload.xlsx';

export const bulkActionEnum = {
  CNV: 'consignment_verification',
};

export const bulkActionConfig: any = {
  [bulkActionEnum.CNV]: {
    fileName: 'sample_csv_cnv',
    extension: '.csv',
    handler: downloadSampleCsvForCNV,
  },
  consignment: {
    fileName: 'sample',
    extension: '.xlsx',
    handler: downloadSampleCsvForConsignment,
  },
  update_consignment: {
    fileName: 'sample',
    extension: '.xlsx',
    handler: downloadSampleCsvForUpdateConsignment,
  },
  update_consignment_pieces: {
    fileName: 'sample',
    extension: '.xlsx',
    handler: downloadSampleCsvForUpdateConsignment,
    params: { is_pieces_update : true }
  },
};

import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Navbar from '@components/navbar/Navbar';
import { useSelector } from 'react-redux';
import {
  ArrowLeftOutlined,
  EnvironmentOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import PinLocationMap from 'src/components/common/PinLocationMap/PinLocationMapRider';
import { startCase, lowerCase } from 'lodash';
import { DatePicker, Timeline, Divider, message, Button } from 'antd';
import moment from 'moment';
import { getNavbarFilters } from 'src/components/pages/GenericConsignment/NavbarFilters';
import { VechileIcon } from 'src/assets/images/vechile';
import {
  getStatusForRiderHistory,
  isToday,
  renderTimeDiff,
} from 'src/utils/utils';
import { useParams } from 'react-router-dom';
import { getRiderHistory, getRiderAggregateHistory } from 'src/api/dashboard';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const useStyles = createUseStyles({
  timeLineDot: {
    '& .ant-timeline-item-head': {
      backgroundColor: '#006EC3',
    },
  },
  timeLineDotGrey: {
    '& .ant-timeline-item-head': {
      backgroundColor: '#666',
    },
  },
  autocomplete: {
    height: '60px',
  },
  timeLineBody: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  startText: {
    color: '#666',
    fontWeight: 600,
    fontSize: '0.75rem',
  },
  riderName: {
    color: '#666',
    fontSize: '0.75rem',
    fontFamily: 'Open Sans',
    fontWeight: 500,
    maxWidth: '40%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  riderCode: {
    color: '#006EC3',
    fontSize: '14px',
    fontFamily: 'Open Sans',
    fontWeight: 600,
    maxWidth: '40%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  riderNameCode: {
    borderBottom: '1px solid #D9D9D9',
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',
    padding: '18px 0.75rem',
  },
  filterBody: {
    padding: '16px',
    display: 'flex',
    border: '1px solid #D9D9D9',
    boxShadow: '0px 1px 4px 0px rgba(0,0,0,0.2)',
    justifyContent: 'space-between',
  },
  dateText: {
    padding: '4px 22px',
    border: '1px solid #D9D9D9',
    borderRight: '0px',
  },
  divider: {
    borderBottom: '1px solid #D9D9D9',
  },
  dividerRider: {
    background: '#D9D9D9',
    width: '1px',
    margin: 0,
  },
  aggregateDiv: {
    padding: '1rem 2rem',
    borderBottom: '1px solid #D9D9D9',
    color: '#666',
    fontSize: '14px',
    fontFamily: 'Open Sans',
    fontWeight: 500,
  },
  riderData: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    marginLeft: '0.625rem',
    marginRight: '50px',
    color: '#111',
    fontSize: '0.75rem',
    fontFamily: 'Open Sans',
    fontWeight: 500,
  },
  aggregateValue: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    marginLeft: '0.625rem',
    color: '#111',
    fontSize: '0.75rem',
    fontFamily: 'Open Sans',
    fontWeight: 500,
  },
  aggregateMainDiv: {
    display: 'flex',
    alignItems: 'center',
    padding: '1rem 0.75rem 0px 1.5rem',
  },
  bodyDiv: {
    display: 'flex',
    flexDirection: 'column',
    width: '21.875rem',
    borderRight: '1px solid #D9D9D9',
  },
  subDiv: {
    display: 'flex',
    flexDirection: 'column',
    width: '21.875rem',
  },
  referenceNumber: {
    color: '#232529',
    fontWeight: 600,
    fontSize: '0.75rem',
    display: 'inline-block',
    maxWidth: '8rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  timeDistanceText: {
    color: '#666',
    fontWeight: 500,
    fontSize: '0.625rem',
  },
});

const TimelineComponent = (props) => {
  const { classes, data, loaded, riderData } = props;
  if (data?.length === 0 && riderData.startTime && riderData.endTime) {
    return <></>;
  }
  let startTimeInSeconds = riderData?.start_time;
  return (
    <Timeline>
      {riderData?.start_time && (
        <Timeline.Item color="#666" className={classes.timeLineDotGrey}>
          <div className={classes.timeLineBody}>
            <div className={classes.startText}>Start</div>
            <div className={classes.timeDistanceText}>
              {moment(riderData?.start_time).format('hh:mm A')}
            </div>
          </div>
        </Timeline.Item>
      )}
      {loaded ? (
        <>
          {data?.map((data, index) => {
            const timeInWord = renderTimeDiff(
              data?.completion_time,
              startTimeInSeconds,
            );
            startTimeInSeconds = data?.completion_time;
            if (!data) {
              return null;
            }
            const status = getStatusForRiderHistory({
              status: data.status,
              taskType: data.task_type,
            });
            const statusColorMap = {
              Delivered: '#64A67A',
              'Picked Up': '#E6AC7F',
            };
            const color = statusColorMap[status] || '#E67F7F';
            const time = moment(data.completion_time).format('hh:mm A');
            const referenceNumber =
              data.task_type === 'pickup'
                ? data.task_reference_number
                : data.reference_number;
            return (
              <Timeline.Item
                color={'#006EC3'}
                key={referenceNumber}
                className={classes.timeLineDot}
              >
                <div className={classes.timeLineBody}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className={classes.referenceNumber}>
                      {referenceNumber}
                    </span>
                    <span
                      style={{ color, fontWeight: 600, fontSize: '0.75rem' }}
                    >
                      {status}
                    </span>
                    <span className={classes.timeDistanceText}>
                      {timeInWord !== '' && <span>{timeInWord}  <span style={{padding: '0px 4px'}}>•</span></span>}
                      {data?.distance_gps !== null &&
                        data?.distance_gps !== undefined && (
                          <span>
                            {`${(
                              (parseFloat(data?.distance_gps) || 0) / 1000
                            ).toFixed(2)} km`}
                          </span>
                        )}
                    </span>
                  </div>
                  <div className={classes.timeDistanceText}>{time}</div>
                </div>
              </Timeline.Item>
            );
          })}
        </>
      ) : (
        <>
          {Array.from({ length: 3 }).map((_, index) => (
            <Timeline.Item
              key={index}
              color={'#006EC3'}
              className={classes.timeLineDot}
            >
              <Skeleton width={200} count={2} height={'1.25rem'} />
            </Timeline.Item>
          ))}
        </>
      )}
      {riderData?.end_time && (
        <Timeline.Item color="#666" className={classes.timeLineDotGrey}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div className={classes.startText}>End</div>
            <div className={classes.timeDistanceText}>
              {moment(riderData?.end_time).format('hh:mm A')}
            </div>
          </div>
        </Timeline.Item>
      )}
    </Timeline>
  );
};

const RiderRouteTracking = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const currHub = useSelector((state) => state.cachedData.currHub);
  const viewType = useSelector((state) => state.masterData.viewType);
  const [riderData, setRiderData] = useState({});
  const [riderHistory, setRiderHistory] = useState([]);
  const [riderHistoryAggregateData, setRiderHistoryAggregateData] = useState(
    {},
  );
  const [riderConsignmentData, setRiderConsignmentData] = useState([]);
  const [loaded, setLoaded] = useState({});
  const [selectedDate, setSelectedDate] = useState(
    location.state?.date
      ? moment(location.state?.date)
      : localStorage.getItem('dateRiderHistory')
      ? moment(localStorage.getItem('dateRiderHistory'))
      : moment(),
  );
  const { workerId } = useParams();
  const fetchData = async () => {
    const responsePromise = getRiderHistory(
      {
        date: selectedDate.format('YYYY-MM-DD'),
        use_firebase_tracking: true,
      },
      workerId,
    );
    const riderHistoryPromise = getRiderAggregateHistory({
      dateString: selectedDate.format('YYYY-MM-DD'),
      workerId,
      hubId: currHub.id,
    });

    const [response, riderHistory] = await Promise.all([
      responsePromise,
      riderHistoryPromise,
    ]);

    if (!response.isSuccess) {
      message.error(response.errorMessage);
    }
    setLoaded({
      ...loaded,
      getRiderHistory: true,
      getRiderAggregateHistory: true,
    });
    setRiderHistory(response?.data ?? []);
    setRiderHistoryAggregateData(riderHistory?.response?.aggregate ?? {});
    setRiderData(riderHistory?.response?.workerData ?? {});
    setRiderConsignmentData(riderHistory?.data ?? []);
  };
  useEffect(() => {
    if(currHub && selectedDate) {
      fetchData();
    }
  }, [currHub, selectedDate]);
  const onChangeDatePicker = (e) => {
    setSelectedDate(e);
    localStorage.setItem('dateRiderHistory', e.format('YYYY-MM-DD'));
  };

  const onClickBackButton = () => {
    history.push({
      pathname: '/manage/tracking/v2',
      state: { date: selectedDate.format('YYYY-MM-DD') },
    });
  };
  let hours = 0;
  let minutes = 0;
  if (riderData.start_time && riderData.end_time) {
    const startTime = moment(riderData.start_time);
    const endTime = moment(riderData.end_time);
    const duration = moment.duration(endTime.diff(startTime));
    hours = duration.hours();
    minutes = duration.minutes();
  }
  const onClickRefresh = async () => {
    setLoaded({});
    setRiderData([]);
    setRiderHistoryAggregateData({});
    fetchData();
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
      }}
    >
      <Navbar
        hideMenuBar={true}
        pageTitle={'Rider Tracking History'}
        leftComponents={getNavbarFilters(['hub_search_with_type'], viewType)}
        hideNotificationIcon={true}
      />
      <div className={classes.filterBody}>
        <div style={{ display: 'flex' }}>
          <div className={classes.dateText}> Date </div>
          <DatePicker onChange={onChangeDatePicker} value={selectedDate} />
        </div>
        <Button icon={<ReloadOutlined />} onClick={onClickRefresh}>
          Refresh
        </Button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className={classes.bodyDiv}>
          <div className={classes.subDiv}>
            <div className={classes.riderNameCode}>
              <ArrowLeftOutlined onClick={onClickBackButton} />
              <span className={classes.riderCode}>{riderData.code}</span>
              <Divider className={classes.dividerRider} type="vertical" />
              <span className={classes.riderName}>{riderData.name}</span>
            </div>
            <div className={classes.divider} />
            <div className={classes.aggregateMainDiv}>
              <VechileIcon />
              <div className={classes.riderData}>
                <span>
                  {riderData?.gps_distance &&
                    (riderData?.gps_distance / 1000).toFixed(2)}{' '}
                  km
                </span>
                <span>
                  {hours} hrs {minutes} mins
                </span>
              </div>
              <EnvironmentOutlined
                style={{ fontSize: '1.5rem', color: '#000' }}
              />
              <div className={classes.aggregateValue}>
                <span>
                  {Object.values(riderHistoryAggregateData)
                    .slice(isToday(selectedDate) ? -3 : -2)
                    .reduce((a, b) => a + b, 0)}{' '}
                  <span style={{ color: '#666' }}>Total Pickups</span>
                </span>
                <span>
                  {Object.values(riderHistoryAggregateData)
                    .slice(0, isToday(selectedDate) ? 3 : 2)
                    .reduce((a, b) => a + b, 0)}{' '}
                  <span style={{ color: '#666' }}>Total Deliveries</span>
                </span>
              </div>
            </div>
            <div className={classes.aggregateDiv}>
              {loaded.getRiderAggregateHistory ? (
                <>
                  {Object.entries(riderHistoryAggregateData).map(
                    ([key, value]) => (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '3px 0px',
                        }}
                        key={key}
                      >
                        <div>{startCase(lowerCase(key))}</div>
                        <div style={{ fontWeight: 700, color: '#000' }}>
                          {value}
                        </div>
                      </div>
                    ),
                  )}
                </>
              ) : (
                <Skeleton width={300} count={5} height={'1.25rem'} />
              )}
            </div>
            <div
              style={{
                padding: '16px 42px',
                maxHeight: isToday(selectedDate) ? '365px' : '420px',
                overflowY: 'auto',
              }}
            >
              <TimelineComponent
                classes={classes}
                data={riderConsignmentData ?? []}
                loaded={loaded.getRiderHistory}
                riderData={riderData}
              />
            </div>
          </div>
        </div>
        <div style={{ width: '100%', height: '700px' }}>
          <PinLocationMap
            initialLat={currHub?.hub_loc?.[0]}
            initialLng={currHub?.hub_loc?.[1]}
            hubLocation={currHub?.hub_loc}
            onLocationChange={() => {}}
            riderConsignmentData={riderConsignmentData}
            coordinates={riderHistory?.geometry?.coordinates ?? []}
          />
        </div>
      </div>
    </div>
  );
};

export default RiderRouteTracking;

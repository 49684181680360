import { Reducer } from 'react';
import {
  ActionType,
  TripManagerMapEditActionTypes,
} from './TripManagerMapEdit.types';

const {
  TRIP_MANAGER_MAP_EDIT_MAP_VIEW_SWITCH,
  TRIP_MANAGER_MAP_EDIT_UPDATE_DRAG_END,
  TRIP_MANAGER_MAP_EDIT_UPDATE_DIRECTIONS,
  TRIP_MANAGER_MAP_EDIT_SET_TRIP_UPDATE_DATA,
  TRIP_MANAGER_MAP_EDIT_SET_ALL_TRIPS,
  TRIP_MANAGER_MAP_EDIT_SET_ALL_UNPLANNED_CNS,
  TRIP_MANAGER_MAP_EDIT_ADD_ACTION,
  TRIP_MANAGER_MAP_EDIT_SET_SAVE_LOADER,
  TRIP_MANAGER_MAP_EDIT_SET_SAVE_FAILURE_ARRAY,
  TRIP_MANAGER_MAP_EDIT_SET_SELECTED_MARKERS,
  TRIP_MANAGER_MAP_EDIT_RESET_SELECTED_MARKERS,
  TRIP_MANAGER_MAP_RESET_MAP_DATA,
  TRIP_MANAGER_MAP_TOGGLE_PARTITIONS,
  TRIP_MANAGER_MAP_UPDATE_METRICS,
} = TripManagerMapEditActionTypes;

export const defaultState = {
  isMapView: true,
  dragEndTimestamp: null,
  routeResultMap: {},
  tripUpdateData: {
    loading: false,
    list: [] as any,
    error: null,
  },
  allTrips: [],
  allUnplannedCNs: [],
  editActions: [],
  saveLoading: false,
  saveFailureArray: {},
  selectedMarkers: [],
  markerDataForChange: {} as any,
  cachedTripIds: [],
  redoActions: [],
  showPartitions: false,
  partitionData: [],
};

export type StateType = typeof defaultState;

export const TripManagerMapEditReducer: Reducer<StateType, ActionType> = (
  state: StateType = defaultState,
  action: ActionType,
) => {
  switch (action.type) {
    case TRIP_MANAGER_MAP_EDIT_SET_ALL_TRIPS:
      return {
        ...state,
        allTrips: action.payload,
      };
    case TRIP_MANAGER_MAP_RESET_MAP_DATA:
      return {
        ...state,
        allTrips: action.payload.allTrips,
        allUnplannedCNs: action.payload.allUnplannedCNs,
        selectedMarkers: [],
        markerDataForChange: {
          isReset: true,
          oldSelectedMarkers: state.selectedMarkers,
        },
        editActions: [],
        saveFailureArray: {},
        cachedTripIds: [],
        routeResultMap: {},
      };
    case TRIP_MANAGER_MAP_EDIT_SET_ALL_UNPLANNED_CNS:
      return {
        ...state,
        allUnplannedCNs: action.payload,
      };
    case TRIP_MANAGER_MAP_EDIT_MAP_VIEW_SWITCH:
      return {
        ...state,
        isMapView: action.payload.isMapView,
      };
    case TRIP_MANAGER_MAP_EDIT_UPDATE_DRAG_END:
      return {
        ...state,
        dragEndTimestamp: +new Date(),
      };
    case TRIP_MANAGER_MAP_EDIT_UPDATE_DIRECTIONS:
      return {
        ...state,
        routeResultMap: action.payload.routeResultMap,
        cachedTripIds: action.payload.cachedTripIds,
      };
    case TRIP_MANAGER_MAP_EDIT_SET_TRIP_UPDATE_DATA:
      return {
        ...state,
        tripUpdateData: {
          ...state.tripUpdateData,
          ...action.payload,
        },
      };
    case TRIP_MANAGER_MAP_EDIT_ADD_ACTION:
      return {
        ...state,
        allTrips: action.payload.allTrips || state.allTrips,
        allUnplannedCNs:
          action.payload.allUnplannedCNs || state.allUnplannedCNs,
        editActions: action.payload.editActions || state.editActions,
        selectedMarkers: [],
        markerDataForChange: {
          isReset: true,
          oldSelectedMarkers: state.selectedMarkers,
        },
        cachedTripIds: action.payload.cachedTripIds,
        redoActions: action.payload.redoActions || state.redoActions,
      };
    case TRIP_MANAGER_MAP_EDIT_SET_SAVE_LOADER:
      return {
        ...state,
        ...action.payload,
      };
    case TRIP_MANAGER_MAP_EDIT_SET_SAVE_FAILURE_ARRAY:
      return {
        ...state,
        saveFailureArray: action.payload.saveFailureArray,
      };
    case TRIP_MANAGER_MAP_EDIT_SET_SELECTED_MARKERS:
      return {
        ...state,
        selectedMarkers: action.payload.selectedMarkers,
        markerDataForChange: action.payload.markerDataForChange,
      };
    case TRIP_MANAGER_MAP_EDIT_RESET_SELECTED_MARKERS:
      return {
        ...state,
        selectedMarkers: [],
        markerDataForChange: {
          isReset: true,
          oldSelectedMarkers: state.selectedMarkers,
        },
      };
    case TRIP_MANAGER_MAP_TOGGLE_PARTITIONS:
      return {
        ...state,
        showPartitions: action.payload.showPartitions,
        partitionData: action.payload.partitionData || state.partitionData,
      };
    case TRIP_MANAGER_MAP_UPDATE_METRICS:
      return {
        ...state,
        allTrips: action.payload.allTrips,
      };
    default:
      return state;
  }
};

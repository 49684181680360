import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '@theming/jssTypes';
import { Modal, Image } from 'antd';

const styles = (theme: ThemeType): CssType => ({
  image: {
    maxHeight: '450px',
    maxWidth: '450px',
  },
});
const PodImageModal = (props: any) => {
  const { handleModalClose, isVisible, classes, record, title } = props;

  return (
    <Modal
      title={title}
      visible={isVisible}
      onCancel={handleModalClose}
      destroyOnClose
      maskClosable={false}
      width={500}
      footer={null}
    >
      <Image.PreviewGroup>
        {record.pod_image_url?.map((url) => {
          return (
            <Image className={classes.image} src={url} placeholder={true} />
          );
        })}
      </Image.PreviewGroup>
    </Modal>
  );
};

export default withStyles(styles, { injectTheme: true })(PodImageModal);

import * as React from 'react';
import withStyles from 'react-jss';
import { Modal, Input, Button, message, Dropdown, Menu } from 'antd';
import { connect } from 'react-redux';
import styles from './styles';
import { fetchOne, downloadCNHistory, fetchCallLogs, decryptConsignment, revokeDeliveryCNDetails, revokeCancellationCNDetails } from '../../../../api/genericConsignmentView';
import ConsignmentDetailsContentStyled from './consignmentDetailsContent';
import FailureArrayHandler from 'src/components/common/FailureArrayHandler';

function TrackConsignmentModal(props: any) {
  const { classes, isVisible, onModalClose, showRevokeActionMenu } = props;
  const [consignmentReferenceNo, setConsignmentReferenceNo] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [descrypting, setDecrypting] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [isErrorModalVisible, setIsErrorModalVisible] = React.useState(false);
  const [failureArray, setFailureArray] = React.useState([]);
  // revoke text will be used to render revoke confirmation modal
  const [revokeText, setRevokeText] = React.useState('');
  const [cnData, setCnData] = React.useState(undefined);
  const [callLogs, setCallLogs] = React.useState([]);
  const [decrypted, setDecrpted] = React.useState(false);

  const getCallLogs = async() => {
    let callLogs = [];
    const params = { reference_number: consignmentReferenceNo};
    const responseCallLogs = await fetchCallLogs(params);
    if(responseCallLogs.isSuccess){
      callLogs = responseCallLogs.data;
    }
    else{
      message.error(responseCallLogs.errorMessage);
    }
    return callLogs;
  };

  const revokeCN = async() => {
    setIsLoading(true);
    let res;
    if (revokeText === 'Delivery') {
      res = await revokeDeliveryCNDetails({
        consignments: [
          {reference_number: consignmentReferenceNo},
        ],
      });
    } else if (revokeText === 'Cancellation') {
      res = await revokeCancellationCNDetails({
        consignments: [
          consignmentReferenceNo,
        ],
      });
    }
    console.log(res);
    if (res.success) {
      message.success(`Shipment successfully reverted from 
      ${revokeText === 'Delivery' ? 'Delivered' : revokeText}`);
    } else if(res.failures && res.failures.length) {
      setIsErrorModalVisible(true);
      setFailureArray(res.failures || []);
    } else {
      message.error(res.errorMessage || 'Something went wrong!');
    }
    setRevokeText('');
    setIsLoading(false);
  };

  const handleFind = async() => {
    if (consignmentReferenceNo) {
      try {
        setIsFetching(true);
        const response = await fetchOne(consignmentReferenceNo);
        const data = response.data
        const callLogs = props.showCallLogs ? await getCallLogs() : [];
        setCnData(data);
        setCallLogs(callLogs)
        setIsFetching(false);
      } catch (err) {
        message.error(err.message);
        setIsFetching(false);
      }

      setDecrpted(false);
    }
  };

  const handleDecrypt = async() => {
    if (cnData?.reference_number) {
      try {
        setDecrypting(true);
        const response = await decryptConsignment({ referenceNumber: cnData.reference_number });
        const data = response.data;
        setCnData({
          ...cnData,
          ...(data?.decryptedKeys || {}),
        });
      } catch (err) {
        message.error(err.message); 
      }
    }
    setDecrpted(true);
    setDecrypting(false);
  };

  const reload = async () => {
    setIsFetching(true);
    const response = await fetchOne(consignmentReferenceNo);
    const data = response.data;
    const callLogs = props.showCallLogs ? await getCallLogs() : [];
    setCnData(data);
    setCallLogs(callLogs)
    setIsFetching(true);
  };

  const handleCancel = () => {
    onModalClose && onModalClose();
  };

  const handleInputChange = (e) => {
    setConsignmentReferenceNo(e.target.value);
  };

  const handlePrintClick = async() => {
    try {
      setIsLoading(true);
      const response = await downloadCNHistory(consignmentReferenceNo);
      setIsLoading(false);
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'consignment_tracking.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      message.error(err.message);
      setIsLoading(false);
    }
  };

  // const renderDecryptButton = () => {
  //   if (!props.showDecrypt) {
  //     return null;
  //   }

  //   return <Button
  //     type="primary"
  //     className={ classes.modalButton }
  //     onClick={ handleDecrypt }
  //     disabled={!cnData?.reference_number || decrypted}
  //     loading={ descrypting }
  //   >Decrypt</Button>;
  // };

  const consignmentData = cnData;

  const renderRevokeMenu = () => (
    <Menu>
      <Menu.Item key="revokeDelivery" onClick={() => setRevokeText('Delivery')}>Revoke Delivery</Menu.Item>
      <Menu.Item key="revokeCancellation" onClick={() => setRevokeText('Cancellation')}>Revoke Cancellation</Menu.Item>
    </Menu>
  );

  const renderRevokeConfirm = () => {
    if (revokeText === '')return null;
    return (
      <Modal
        visible={revokeText !== ''}
        onCancel={() => setRevokeText('')}
        onOk={() => revokeCN()}
        title="Confirmation"
      >
        Are you sure you wish to Revoke {revokeText} for selected consignments?
      </Modal>
    );
  };

  const renderLeftFilters = () => (
    <div style={{ display: 'flex' }}>
      <h3 className={ classes.modalTitle }>Search Consignment</h3>
      {
        consignmentData &&
        <Button
          className={ classes.downloadBtn }
          type="primary"
          onClick={ handlePrintClick }
          loading={ isLoading }
        >
          Print
        </Button>
      }
    </div>
  );

  const renderRightFilters = () => (
    <div style={{ display: 'flex', margin: '0 30px' }}>
      {
        consignmentData && props.isSuperAdmin && showRevokeActionMenu &&
        <Dropdown
          isOpen={isDropdownOpen}
          overlay={renderRevokeMenu}
          onClose={() => setIsDropdownOpen(false)}
          trigger={['click']}
        >
          <Button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            type="primary">
            Action
          </Button>
        </Dropdown>
      }
      {renderRevokeConfirm()}
    </div>
  );

  return (
      <Modal
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {renderLeftFilters()}
            {renderRightFilters()}
          </div>
        }
        visible={ isVisible }
        onCancel={ handleCancel }
        footer = { null }
        className= { classes.trackConsignmentModal }
        width="95%"
        bodyStyle={{
          height: '100%',
        }}
      >
        <div className={ classes.inputRow } style = {{margin: '20px 14px'}}>
          <Input
            className={ classes.modalInput }
            placeholder="Enter Reference Number"
            onChange={ handleInputChange }
          />
          <Button
            type="primary"
            className={ classes.modalButton}
            onClick={ handleFind }
            loading={ isFetching }
          >Find</Button>
        </div>
        {
          (!isFetching && consignmentData) ?
          <ConsignmentDetailsContentStyled
          cnData = {consignmentData}
          reload = {reload}
          callLogsData = {callLogs}
          /> : null
        }
        {isErrorModalVisible ? (
        <FailureArrayHandler
          failureArray={failureArray}
          isVisible={isErrorModalVisible}
          onModalClose={() => {
            setIsErrorModalVisible(false);
            setFailureArray([]);
          }}
        ></FailureArrayHandler>
      ) : null}
      </Modal>
  );
}

const mapStateToProps = ({ masterData }) => {
  return {
    showProofOfPickup: masterData.ops_dashboard_config.parts_to_show.show_proof_of_pickup,
    showRevokeActionMenu: masterData.ops_dashboard_config.parts_to_show.CRM_in_tracking,
    showCallLogs: masterData.enable_call_logs,
    isSuperAdmin: masterData.ops_dashboard_config?.parts_to_show?.super_admin,
    showDecrypt: masterData.show_decrypt_option,
  };
};
export default connect(mapStateToProps,
  )(withStyles(styles, { injectTheme: true })(TrackConsignmentModal));

import * as React from 'react';
import { Popup } from 'react-map-gl';

import { IUIMapPopup } from 'src/components/common/UIMap/utils/types';

const UIMapPopup = (props: IUIMapPopup) => {
  const {
    id,
    coordinates,
    showPopup,
    popupContent,
    anchor = 'left',
    offset = 15,
    onPopupClose = () => {},
    closeOnClick,
    closeButton = true,
    ...rest
  } = props;

  return (
    showPopup && (
      <Popup
        longitude={coordinates[0]}
        latitude={coordinates[1]}
        offset={offset}
        anchor={anchor}
        onClose={() => onPopupClose(false, id)}
        closeOnClick={closeOnClick}
        closeButton={closeButton}
        {...rest}
      >
        {popupContent}
      </Popup>
    )
  );
};

export default UIMapPopup;

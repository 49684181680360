import {
    fetchAllCancellationReasons,
    createCancellationReason,
    updateCancellationReason,
    deleteCancellationReason,
  } from '@api/appFlowConfig';
  
  const getKeyMappingReasonSingle = (masterData) => {
    return (
      masterData.ops_dashboard_config?.config?.cancellation_reason_config
        ?.csv_field_list || []
    );
  };
  
  const reasonFormItemLayout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  };
  
  const viewControl = {
    pageTitle: 'Setup',
    buckets: ['cancellationReasonMaster'],
    globalActions: [],
    addOptions: [
      {
        key: 'add_single',
        title: 'Single',
        action: 'add',
        type: 'single',
      },
    ],
    id: 'reason_id',
    fetchData: fetchAllCancellationReasons,
    onSingleAdd: createCancellationReason,
    add: {
      sampleUrl: undefined,
      downloadSampleCsvFromApiCall: false,
      downloadSampleCsv: null,
      title: 'Add Reason',
    },
    edit: {
      title: 'Edit Reason',
      okText: 'Update',
    },
    warningMsg: 'No Reason to upload',
    successMsg: 'Uploaded Successfully',
    updateMsg: 'Updated Successfully',
    modalHeading: 'Upload Reason',
    deleteMsg: 'Deleted Successfully',
    singleUploadMapping: getKeyMappingReasonSingle,
    onSingleUpdate: updateCancellationReason,
    formItemLayout: reasonFormItemLayout,
    onSingleDelete: deleteCancellationReason,
  };
  
  const buckets = {
    cancellationReasonMaster: {
      name: 'Reasons',
      filters: [],
      actions: [],
    },
  };
  
  const bucketColor = {};
  
  export default { viewControl, buckets, bucketColor };
  
const BlankLocationSVG = (
  props: any,
) => `<svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(-585.000000, -201.000000)">
        <g transform="translate(585.000000, 201.000000)">
            <rect x="0" y="0" width="48" height="48"></rect>
            <g transform="translate(6.312286, 1.537381)">
                <path stroke="#000" stroke-width="1px" d="M5.16075439,5.22712727 C-1.72025146,12.1081331 -1.72025146,23.2236041 5.16075439,30.10461 C7.10155092,32.0454065 9.39521954,33.4568949 11.6888882,34.1626391 L17.6877138,44.925238 L23.6865394,34.1626391 C26.1566441,33.2804589 28.2738766,31.8689705 30.2146732,30.10461 C37.095679,23.2236041 37.095679,12.1081331 30.2146732,5.22712727 C23.3336673,-1.65387859 12.0417603,-1.83031463 5.16075439,5.22712727 Z" fill="${props.color}" fill-rule="nonzero"></path>
                <g transform="translate(5.687714, 10.462619)"></g>
                <circle fill="#FFFFFF" cx="17.4903114" cy="18.0401218" r="14.1148838"></circle>
            </g>
        </g>
    </g>
</g>
</svg>`;

export default BlankLocationSVG;

import * as React from 'react';
import withStyles from 'react-jss';
import { connect } from 'react-redux';
import { CssType, ThemeType } from '@theming/jssTypes';
import { Button, Table, Modal, Input, message, Tooltip, Carousel } from 'antd';
import { EditOutlined, WarningFilled } from '@ant-design/icons';
import OpenImageModal from './OpenImageModal';
import lodash from 'lodash';
import { getConsignmentStatusPrettyName } from '@utils/consignmentStatus';
import {
  getColumnList,
  addLBHColumn,
  unnecessaryColumns,
} from './PieceDetailsTabConfig';
import { getDetailsRoute } from '@routing/utils';
import { Link } from 'react-router-dom';
import { addCommentsToPieces } from '@api/dashboard';
import TextWithEllipsisAndTooltip from '@components/common/TextWithEllipsisAndTooltip';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import FixedHeaderTable from 'src/components/common/FixedHeaderTable/FixedHeaderTable';
import * as moment from 'moment';

const styles = (theme: ThemeType): CssType => ({
  tableContainer: {
    flex: '1 1 0',
    overflow: 'auto',
    '& .ant-table-tbody > tr > td, .ant-table-thead > tr > th': {
      padding: 9,
      textAlign: 'center',
    },
    height: '100%',
  },
  underline: {
    textDecoration: 'underline',
    color: '#065492',
    cursor: 'pointer',
  },
  editIcon: {
    marginLeft: 10,
    color: '#085492',
  },
});

const PieceDetailsModal = (props: any) => {
  const {
    sorterImageBucketUrl,
    isShowLBH,
    viewType,
    columns,
    data,
    reload,
    cnReferenceNumber,
  } = props;
  const { isVisible, classes, pieceDetails } = props;
  const [modalData, setModalData] = React.useState({
    isImageModalVisible: false,
    imageUrl: '',
  });
  const [imageVideoModalData, setImageVideoModalData] = React.useState({
    isImageVideoModalData: false,
    images: [],
    videos: [],
  });
  const [commentModal, setCommentModal] = React.useState({
    isVisible: false,
    fieldValue: '',
    pieceReferenceNumber: '',
    referenceNumber: '',
    isLoading: false,
  });
  const handleOpenImageModal = (imageUrl) => {
    setModalData({
      isImageModalVisible: true,
      imageUrl,
    });
  };

  const handleCloseImageModal = () => {
    setModalData({
      ...modalData,
      isImageModalVisible: false,
    });
  };

  const handleOpenImageVideoModal = (images = [], videos = []) => {
    setImageVideoModalData({
      isImageVideoModalData: true,
      images,
      videos,
    });
  };

  const handleCloseImageVideoModal = () => {
    setImageVideoModalData({
      isImageVideoModalData: false,
      images: [],
      videos: [],
    });
  };

  const showEditCommentModal = (pieceReferenceNumber, value = null) => {
    setCommentModal({
      ...commentModal,
      fieldValue: value,
      isVisible: true,
      pieceReferenceNumber,
      referenceNumber: cnReferenceNumber,
    });
  };

  const handleCommentModalClose = () => {
    setCommentModal({
      ...commentModal,
      isVisible: false,
      fieldValue: '',
      pieceReferenceNumber: '',
      referenceNumber: '',
    });
  };

  const handleEditCommentSubmit = async () => {
    const requestBody = {
      comment: commentModal.fieldValue,
      piece_reference_number: commentModal.pieceReferenceNumber || null,
      reference_number: commentModal.referenceNumber,
    };
    setCommentModal({
      ...commentModal,
      isLoading: true,
    });
    const response = await addCommentsToPieces(requestBody);
    if (response.isSuccess) {
      message.success(
        `Comments updated for ${commentModal.pieceReferenceNumber}`,
      );
      reload();
    } else {
      message.error(
        `Unable to update comments for ${commentModal.pieceReferenceNumber}.`,
      );
    }
    setCommentModal({
      isVisible: false,
      pieceReferenceNumber: '',
      fieldValue: '',
      referenceNumber: '',
      isLoading: false,
    });
  };

  const generateDataColumns = () => {
    const columnData: any = [];
    // const {sorter_image_bucket_url, isShowLBH} = this.props;
    let indexList = columns;

    if (isShowLBH) {
      indexList = [
        ...indexList.filter(
          (column) =>
            unnecessaryColumns.findIndex((y) => y === column.id) === -1,
        ),
        ...addLBHColumn,
      ];
    }
    indexList.forEach((item) => {
      const itemToPush = {};
      itemToPush['title'] = item.pretty_name;
      itemToPush['dataIndex'] = item.id;
      itemToPush['width'] = item.width || 80;
      itemToPush['key'] = item.id;

      if (item.id === 'serial_number') {
        itemToPush['width'] = 50;
        itemToPush['render'] = (text, record, index) => {
          return <span> {index + 1}</span>;
        };
      }
      if (item.id === 'status') {
        itemToPush['width'] = 150;
        itemToPush['render'] = (x, record) => {
          return record._internal ? (
            <span>
              {getConsignmentStatusPrettyName(record._internal.status)}
            </span>
          ) : null;
        };
      }
      if (item.id === 'delivery_failure_reason') {
        itemToPush['render'] = (x, record) => {
          return record._internal &&
            (record._internal.status === 'attempted' ||
              record._internal.status === 'not_picked_up') ? (
            <span>{x}</span>
          ) : null;
        };
      }
      if (item.id === 'volume') {
        itemToPush['title'] = (
          <span>
            Volume(m<sup>3</sup>)
          </span>
        );
        itemToPush['render'] = (x, record) => {
          const dim = record.width ? 'width' : 'breadth';
          const LBH = ['length', 'height', dim];
          const isPresent = LBH.every((dimension) =>
            Object.hasOwnProperty.bind(record)(dimension),
          );
          const calVolume = isPresent
            ? Math.round(
                (record.length * record[dim] * record.height + Number.EPSILON) *
                  100,
              ) / 100
            : '';
          const volume = record.volume ? record.volume : calVolume;
          return <span>{`${volume}`}</span>;
        };
      }
      if (item.id === 'breadth') {
        itemToPush['render'] = (x, record) => {
          const value = record.breadth || record.width;
          return <span>{value ? value : null}</span>;
        };
      }
      if (item.id === 'weight') {
        itemToPush['render'] = (x, record) => {
          const weight = record.weight ? record.weight : '';
          return <span>{`${weight}`}</span>;
        };
      }
      if (item.id === 'piece_product_code') {
        itemToPush['render'] = (x, record) => {
          const code = record.piece_product_code
            ? record.piece_product_code
            : '';
          return <span>{`${code}`}</span>;
        };
      }
      if (item.id === 'reference_image_url') {
        itemToPush['render'] = (x, record) => {
          if (!record.reference_image_url) return null;
          return (
            <div>
              <Button
                onClick={() => {
                  handleOpenImageModal(record.reference_image_url);
                }}
                type="primary"
              >
                Open Image
              </Button>
            </div>
          );
        };
      }
      if (item.id === 'reference_number' && !props.deletedPieces) {
        itemToPush['render'] = (x, record) => {
          return (
            <Link
              target={'_blank'}
              to={getDetailsRoute(record.reference_number)}
            >
              {x}
            </Link>
          );
        };
      }
      if (item.id === 'image') {
        itemToPush['render'] = (x, record) => {
          const sorter_image_path = lodash.get(
            record,
            '_internal.sorter_image_link',
            '',
          );
          const sorter_image_url =
            sorter_image_path && sorterImageBucketUrl
              ? `${sorterImageBucketUrl}${sorter_image_path}`
              : '';
          const pieces_images =
            lodash.get(record, '_internal.image_url_list') || [];
          const pieces_videos =
            lodash.get(record, '_internal.video_url_list') || [];
          const image_url = sorter_image_url ? sorter_image_url : x;
          if (
            (Array.isArray(pieces_images) && pieces_images.length) ||
            (Array.isArray(pieces_videos) && pieces_videos.length)
          ) {
            return (
              <div>
                <Button
                  onClick={() => {
                    handleOpenImageVideoModal(pieces_images, pieces_videos);
                  }}
                  type="primary"
                >
                  Click Here
                </Button>
              </div>
            );
          } else if (image_url) {
            return (
              <div>
                <Button
                  onClick={() => {
                    handleOpenImageModal(image_url);
                  }}
                  type="primary"
                >
                  Open Image
                </Button>
              </div>
            );
          }
          return null;
        };
      }
      if (item.id === 'comment') {
        itemToPush['render'] = (x, record) => {
          return record.comment ? (
            <div>
              <TextWithEllipsisAndTooltip width="100px">
                {record.comment}
              </TextWithEllipsisAndTooltip>
              <EditOutlined
                className={classes.editIcon}
                onClick={() =>
                  showEditCommentModal(record.reference_number, record.comment)
                }
              />
            </div>
          ) : (
            <div
              className={classes.underline}
              onClick={() => showEditCommentModal(record.reference_number)}
            >
              Add Comment
            </div>
          );
        };
      }
      if (item.id === 'quantity_delivered') {
        itemToPush['render'] = (x, record) => {
          return record.hasOwnProperty('quantity_delivered') ? (
            <span>{record.quantity_delivered}</span>
          ) : null;
        };
      }
      if (item.id === 'deleted_at') {
        itemToPush['render'] = (x, record) => {
          const localTimeStamp = record.deleted_at ? moment(record.deleted_at).local().format('YYYY-MM-DD HH:mm') : null;
          return localTimeStamp ? (
            <div>
              {localTimeStamp}
            </div>
          ) : null;
        };
      }
      columnData.push(itemToPush);
    });
    return columnData;
  };

  const header = `${pieceDetails.selectedReferenceNumber} Piece Detail View`;
  return (
    <div className={classes.tableContainer}>
      {modalData.isImageModalVisible ? (
        <OpenImageModal
          imageUrl={modalData.imageUrl}
          isVisible={modalData.isImageModalVisible}
          onModalClose={handleCloseImageModal}
        ></OpenImageModal>
      ) : null}
      {imageVideoModalData.isImageVideoModalData ? (
        <Modal
          className={classes.modalContainer}
          visible={imageVideoModalData.isImageVideoModalData}
          onCancel={() => handleCloseImageVideoModal()}
          footer={null}
        >
          <Carousel
            className={classes.carouselContainer}
            prevArrow={<LeftCircleOutlined />}
            nextArrow={<RightCircleOutlined />}
            arrows
          >
            {imageVideoModalData?.images?.map((data) => (
              <div key={data}>
                <img object-fit="cover" style={{ width: '100%' }} src={data} />
              </div>
            ))}
            {imageVideoModalData?.videos?.map((data) => (
              <div key={data} style={{ maxWidth: '100%' }}>
                <video controls style={{ maxWidth: '100%' }}>
                  <source src={data} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ))}
          </Carousel>
        </Modal>
      ) : null}
      <FixedHeaderTable
        locale={{ emptyText: 'No pieces' }}
        pagination={false}
        dataSource={data}
        columns={generateDataColumns()}
        resizable={true}
      />
      <Modal
        title={'Add Comment'}
        width={450}
        visible={commentModal.isVisible}
        okText={'Add'}
        onCancel={handleCommentModalClose}
        onOk={handleEditCommentSubmit}
        confirmLoading={commentModal.isLoading}
        destroyOnClose
      >
        <Input.TextArea
          rows={4}
          onChange={(e) =>
            setCommentModal({ ...commentModal, fieldValue: e.target.value })
          }
          defaultValue={commentModal.fieldValue}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ masterData }) => {
  return {
    viewType: masterData.viewType,
    isShowLBH: lodash.get(
      masterData,
      'do_not_fetch_crm_piece_details_from_child_cn',
      false,
    ),
    sorterImageBucketUrl: lodash.get(
      masterData,
      'sorter_info.sorter_image_bucket_url',
      '',
    ),
  };
};

const PieceDetailsModalStyled = withStyles(styles, { injectTheme: true })(
  PieceDetailsModal,
);
export default connect(mapStateToProps, null)(PieceDetailsModalStyled);

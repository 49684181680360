// tslint:disable-next-line: ter-arrow-parens
import { ThemeType } from "../jssTypes";
export const getSelectStyleForSelectWithInput = ({ height, theme }: { height?: string; theme?: ThemeType } = {}) => {
  return {
    '&.ant-select .ant-select-selector': {
      backgroundColor: theme?.colors.textOnDarkBg ? `${theme.colors.textOnDarkBg}1A` : '#fafafa',
      display: 'flex',
      color: theme?.colors.textOnDarkBg,
      fontSize: theme?.sizes.bodyText,
      fontFamily: theme?.fonts.fontFamily,
      alignItems: 'center',
      height: height || 'auto', // Use provided height or theme value or fallback to default
      border: 'none',
    },
    '& .ant-select-arrow': {
      color: theme?.colors.textOnDarkBg || 'inherit',
      fontSize: theme?.sizes.bodyText,
    },
    '& .ant-select-selector': {
      backgroundColor: theme?.colors.textOnDarkBg ? `${theme.colors.textOnDarkBg}1A` : '#fafafa',
      color: theme?.colors.textOnDarkBg,
      fontSize: theme?.sizes.bodyText,
      fontFamily: theme?.fonts.fontFamily,
      borderTopRightRadius: '0 !important',
      borderBottomRightRadius: '0 !important',
    },
  };
};

export const getImportantPosition = (pos: string): any => {
  return `${pos} !important`;
};

export const getTextInputStyleForSelectWithInput = () => ({
  '& .ant-input': {
    borderRadius: '0 4px 4px 0',
  },
  '& .ant-input-search .ant-input-group .ant-input-affix-wrapper' :{
    borderTopLeftRadius: '0 !important',
    borderBottomLeftRadius: '0 !important',
  },
});

export const tablePaginationStyle = () => ({
  '& .ant-table-pagination' : {
    marginRight: '10px !important',
  },
});

export const refreshButton = ({ color }) => ({
  color: color || 'grey',
  background: 'transparent',
  border: 'none',
  boxShadow: 'none',
  fontSize: 16,
  margin: '10px 0 0 0',
  '&:hover': {
    border: 'none',
    background: 'transparent !important',
    boxShadow: 'none',
  },
  '&:focus': {
    backgroundColor: 'transparent',
  },
});

export const getSelectStyleForNoLeftRadius = () => (
{
  '& .ant-select-selection': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}
);
export const getAntdTableComponentsStyle = () => {
  return {
    '& .ant-checkbox-inner': {
      width: '15px',
      height: '15px',
    },
    '& .anticon-down': {
      fontSize: '12px',
    },
  };
};

import Icon from '@ant-design/icons';
import * as React from 'react';
const BlueDotSVG = ({ width = '8', height = '8', ...props }: any) => (
  // tslint:disable-next-line: max-line-length
  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 4 4" fill="none">
  <circle opacity="0.5" cx="2" cy="2" r="2" fill="#1659CB"/>
</svg>
);
export const BlueDot = (props) => (
  <Icon component={() => <BlueDotSVG {...props} />} {...props} />
);

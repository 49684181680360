import {
  addCN,
  assignRider,
  assignVehicle,
  bulkDeleteTripConsignment,
  deleteTrip,
  getTrackingDirections,
  getTrackingDirectionsByTasks,
  getTripSimulatedMetrics,
  modifyVehicleMake,
  reorderCN,
} from 'src/api/trips';
import {
  ActionType,
  TripManagerMapEditActionTypes,
} from './TripManagerMapEdit.types';
import * as polyline from '@mapbox/polyline';
import {
  TRIP_OFFLINE_ACTIONS,
  UNPLANNED_CN_OFFLINE_ACTIONS,
  convertTripCNToUnplannedCN,
  convertUnplannedCNToTripCN,
} from '../TripManagerMapEditUtils';
import { createManualTrip } from 'src/api/retailDashboard';
import { fetchSavedParamsForHub } from 'src/components/pages/RoutingPlaygroundV2/api/api';
import { convertHHMMSSToSeconds } from 'src/utils/utils';
import { message } from 'antd';

export const setTripData = (allTrips): ActionType => ({
  type: TripManagerMapEditActionTypes.TRIP_MANAGER_MAP_EDIT_SET_ALL_TRIPS,
  payload: allTrips,
});

export const setUnplannedCnData = (unplannedCNs): ActionType => ({
  type: TripManagerMapEditActionTypes.TRIP_MANAGER_MAP_EDIT_SET_ALL_UNPLANNED_CNS,
  payload: unplannedCNs,
});

export const switchMapView = (isMapView: boolean): ActionType => ({
  type: TripManagerMapEditActionTypes.TRIP_MANAGER_MAP_EDIT_MAP_VIEW_SWITCH,
  payload: {
    isMapView,
  },
});

export const updateDragEndTime = (): ActionType => ({
  type: TripManagerMapEditActionTypes.TRIP_MANAGER_MAP_EDIT_UPDATE_DRAG_END,
  payload: null,
});

export const setSaveFailureArray = (saveFailureArray: any): ActionType => ({
  type: TripManagerMapEditActionTypes.TRIP_MANAGER_MAP_EDIT_SET_SAVE_FAILURE_ARRAY,
  payload: {
    saveFailureArray,
  },
});

export const resetMapData = (
  dispatch: (value: ActionType) => void,
  payload: {
    allTrips: any;
    allUnplannedCNs: any;
  },
) => {
  dispatch({
    type: TripManagerMapEditActionTypes.TRIP_MANAGER_MAP_RESET_MAP_DATA,
    payload,
  });
};

export const setSelectedMarkers = (
  dispatch: (value: ActionType) => void,
  payload: {
    isMultiSelect: boolean;
    markerDataIdx: number;
    selectedMarkers: any;
    referenceNumber: any;
    tripId?: string;
  },
): void => {
  const {
    selectedMarkers,
    referenceNumber,
    tripId,
    isMultiSelect,
    markerDataIdx,
  } = payload;
  let newSelectedMarkers = [...(selectedMarkers ?? [])];
  let selected = true;
  let oldSelectedMarkers;
  const existingIdx = newSelectedMarkers.findIndex(
    (marker) => marker.referenceNumber === referenceNumber,
  );
  if (existingIdx > -1) {
    if (isMultiSelect) {
      newSelectedMarkers.splice(existingIdx, 1);
      selected = false;
    } else {
      oldSelectedMarkers = newSelectedMarkers.filter(
        (m) => m.referenceNumber !== referenceNumber,
      );
      newSelectedMarkers = oldSelectedMarkers.length
        ? newSelectedMarkers.filter(
            (m) => m.referenceNumber === referenceNumber,
          )
        : [];
      selected = !!oldSelectedMarkers.length;
    }
  } else {
    const markerDataObject = {
      referenceNumber,
      tripId,
    };
    if (isMultiSelect) {
      newSelectedMarkers.push(markerDataObject);
    } else {
      oldSelectedMarkers = newSelectedMarkers;
      newSelectedMarkers = [markerDataObject];
    }
  }
  const markerDataForChange = {
    markerDataIdx,
    isPlanned: !!tripId,
    selected,
    isMultiSelect,
    oldSelectedMarkers,
  };

  dispatch({
    type: TripManagerMapEditActionTypes.TRIP_MANAGER_MAP_EDIT_SET_SELECTED_MARKERS,
    payload: {
      markerDataForChange,
      selectedMarkers: newSelectedMarkers,
    },
  });
};

export const calculateTripMetricsForSimulatedTrips = async (
  dispatch: (value: ActionType) => void,
  payload: {
    allTrips: any;
    hubCode: any;
  },
): Promise<void> => {
  const { allTrips, hubCode } = payload;
  const allTripsNew = [...allTrips];
  const tripsForSimulatedMetrics = allTrips.filter(
    (trip) =>
      !trip.tripDetails.is_local_temp_trip &&
      trip.tripDetails.fetch_trip_metrics,
  );

  if (tripsForSimulatedMetrics && tripsForSimulatedMetrics.length) {
    const promises = tripsForSimulatedMetrics.map(async (tripData) => {
      const response = await getTripSimulatedMetrics({
        trip_id: tripData.tripDetails.id,
        task_id_list: tripData.taskDetails.map((t) => t.task_id),
        hub_code: hubCode,
      });

      return response;
    });

    const res: any = await Promise.allSettled<any>(promises);

    const errs = [];

    res.forEach(({ status, value, reason }, idx) => {
      if (status === 'fulfilled' && value.isSuccess) {
        const { data } = value;
        const { trip_metrics, violations } = data;
        const {
          total_distance,
          total_time,
          total_service_time,
          delivery_stops,
          weight_utilization_percent,
          number_utilization_percent,
          volume_utilization_percent,
        } = trip_metrics;
        const tripData = tripsForSimulatedMetrics[idx];
        const tripIdx = allTripsNew.findIndex(
          (t) => t.tripDetails.id === tripData.tripDetails.id,
        );
        if (tripIdx > -1) {
          const serviceTimeSec = total_service_time
            ? convertHHMMSSToSeconds(total_service_time)
            : allTripsNew[tripIdx].tripDetails.service_time_mins;
          allTripsNew[tripIdx] = {
            ...allTripsNew[tripIdx],
            tripDetails: {
              ...allTripsNew[tripIdx].tripDetails,
              fetch_trip_metrics: false,
              is_metric_breached: violations && violations.length > 0,
              estimated_distance_metres:
                total_distance ??
                allTripsNew[tripIdx].tripDetails.estimated_distance_metres,
              estimated_time_seconds: total_time
                ? convertHHMMSSToSeconds(total_time)
                : allTripsNew[tripIdx].tripDetails.estimated_time_seconds,
              service_time_mins: serviceTimeSec ? +serviceTimeSec / 60 : null,
              delivery_stops:
                delivery_stops ??
                allTripsNew[tripIdx].tripDetails.delivery_stops,
              vehicle_weight_utilisation:
                weight_utilization_percent ??
                allTripsNew[tripIdx].tripDetails.vehicle_weight_utilisation,
              vehicle_consignment_utilisation:
                number_utilization_percent ??
                allTripsNew[tripIdx].tripDetails
                  .vehicle_consignment_utilisation,
              vehicle_volume_utilisation:
                volume_utilization_percent ??
                allTripsNew[tripIdx].tripDetails.vehicle_volume_utilisation,
            },
          };
        }
      } else if (reason) {
        errs.push(reason);
      }
    });

    if (errs.length) {
      message.error(errs.join(', '));
    }

    dispatch({
      type: TripManagerMapEditActionTypes.TRIP_MANAGER_MAP_UPDATE_METRICS,
      payload: {
        allTrips: allTripsNew,
      },
    });
  }
};

export const calculatePolylines = async (
  dispatch: (value: ActionType) => void,
  payload: {
    tripIds: any;
    hubId: any;
    routeResultMap: any;
    allTrips: any;
    cachedTripIds: any;
  },
): Promise<void> => {
  const { tripIds, hubId, routeResultMap, allTrips, cachedTripIds } = payload;

  let changes = false;

  for (let i = 0; i < (tripIds?.length ?? 0); i++) {
    const tripId = tripIds[i];
    if (
      !routeResultMap.hasOwnProperty(tripId) ||
      !routeResultMap[tripId].result ||
      cachedTripIds.includes(tripId)
    ) {
      changes = true;
      const tripData = allTrips.find((t) => t.tripDetails.id === tripId);
      // eslint-disable-next-line no-await-in-loop
      const trackingDirectionsResult: any = await (tripData.tripDetails
        .is_local_temp_trip || tripData.tripDetails.fetch_directions_from_tasks
        ? getTrackingDirectionsByTasks({
            tasks: tripData.taskDetails?.map((t) => t.task_id),
            hubId,
          })
        : getTrackingDirections({
            tripId,
            hubId,
          }));
      if (!trackingDirectionsResult.isSuccess) {
        routeResultMap[tripId] = {
          errorMessage: `${tripId}: ${
            trackingDirectionsResult?.errorMessage ||
            'There was an issue while plotting routes on map'
          }`,
        };
      } else {
        const response: any = trackingDirectionsResult.response;
        const encodedPolylineList =
          response?.encoded_polyline_list?.filter((el) => !!el) ?? [];
        const finalResp = { polyline_coordinates: [] };
        if (Array.isArray(encodedPolylineList) && encodedPolylineList.length) {
          for (const encodedPolylineObj of encodedPolylineList) {
            const encodedPolyline = encodedPolylineObj.encoded_polyline;
            if (encodedPolyline) {
              try {
                const decodedPolyline = polyline
                  .decode(encodedPolyline)
                  .map((item) => {
                    const [latitude, longitude] = item;
                    return [longitude, latitude];
                  });
                finalResp.polyline_coordinates = decodedPolyline;
              } catch (e) {}
            }
          }
        }
        const tripIndex = allTrips.findIndex(
          (t) => t.tripDetails.id === tripId,
        );
        routeResultMap[tripId] = {
          result: finalResp,
          tripIndex,
        };
      }
    }
  }

  if (changes) {
    dispatch({
      type: TripManagerMapEditActionTypes.TRIP_MANAGER_MAP_EDIT_UPDATE_DIRECTIONS,
      payload: {
        routeResultMap: {
          ...routeResultMap,
        },
        cachedTripIds: [],
      },
    });
  }
};

export const fetchTripUpdateData = async (
  dispatch: (value: ActionType) => void,
  payload,
  searchFunction,
  responseDataMapper,
): Promise<void> => {
  dispatch({
    type: TripManagerMapEditActionTypes.TRIP_MANAGER_MAP_EDIT_SET_TRIP_UPDATE_DATA,
    payload: {
      loading: true,
      error: null,
      list: [],
    },
  });

  const response = await searchFunction(payload);

  const newState: any = {
    loading: false,
  };

  if (response.isSuccess) {
    newState.list = responseDataMapper
      ? responseDataMapper(response.data)
      : response.data;
  } else {
    newState.error = response.errorMessage;
  }

  dispatch({
    type: TripManagerMapEditActionTypes.TRIP_MANAGER_MAP_EDIT_SET_TRIP_UPDATE_DATA,
    payload: newState,
  });
};

export const updateTripDataOffline = async (
  dispatch: (value: ActionType) => void,
  payload: {
    actionType: TRIP_OFFLINE_ACTIONS;
    tripId: string;
    allTrips: any;
    allUnplannedCNs: any;
    newData: any;
    editActions: any;
    routeResultMap?: any;
    redoActions?: any;
  },
) => {
  const {
    actionType,
    tripId,
    allTrips,
    newData,
    allUnplannedCNs,
    editActions,
    routeResultMap,
    redoActions,
  } = payload;
  const tripIdx = tripId
    ? allTrips.findIndex((t) => t.tripDetails.id === tripId)
    : null;
  const allTripsNewData = [...allTrips];
  const allUnplannedCNsNewData = [...allUnplannedCNs];
  const tripData = tripId ? allTripsNewData[tripIdx] : null;
  const newEditActions = [...editActions];
  const cachedTripIds = [];
  switch (actionType) {
    case TRIP_OFFLINE_ACTIONS.UPDATE_VEHICLE:
      newEditActions.push({
        actionType,
        id: tripId,
        referenceNumber: tripData.tripDetails.trip_reference_number,
        apiPayload: {
          tripId,
          vehicleId: newData.id,
        },
        actionMethod: assignVehicle,
        oldData: {
          tripDetails: {
            ...tripData.tripDetails,
          },
        },
      });
      allTripsNewData[tripIdx] = {
        ...tripData,
        tripDetails: {
          ...tripData.tripDetails,
          vehicle_registration_number: newData.name,
          vehicle_id: newData.id,
        },
      };
      break;
    case TRIP_OFFLINE_ACTIONS.UPDATE_WORKER:
      newEditActions.push({
        actionType,
        id: tripId,
        referenceNumber: tripData.tripDetails.trip_reference_number,
        apiPayload: {
          tripId,
          workerId: newData.id,
        },
        actionMethod: assignRider,
        oldData: {
          tripDetails: {
            ...tripData.tripDetails,
          },
        },
      });
      allTripsNewData[tripIdx] = {
        ...tripData,
        tripDetails: {
          ...tripData.tripDetails,
          worker_id: newData.id,
          worker_name: newData.name,
        },
      };
      break;
    case TRIP_OFFLINE_ACTIONS.UPDATE_VEHICLE_MAKE:
      newEditActions.push({
        actionType,
        id: tripId,
        referenceNumber: tripData.tripDetails.trip_reference_number,
        apiPayload: {
          tripId,
          vehicleMakeId: newData.id,
        },
        actionMethod: modifyVehicleMake,
        oldData: {
          tripDetails: {
            ...tripData.tripDetails,
          },
        },
      });
      allTripsNewData[tripIdx] = {
        ...tripData,
        tripDetails: {
          ...tripData.tripDetails,
          vehicle_make: newData.name,
          vehicle_make_id: newData.id,
        },
      };
      break;
    case TRIP_OFFLINE_ACTIONS.REORDER_TASKS:
      newEditActions.push({
        actionType,
        id: tripId,
        referenceNumber: tripData.tripDetails.trip_reference_number,
        apiPayload: {
          tripId,
          tasks: newData?.map((x: any) => x.task_id) ?? [],
        },
        actionMethod: reorderCN,
        oldData: {
          taskDetails: [...tripData.taskDetails],
          tripDetails: {
            ...tripData.tripDetails,
          },
        },
      });
      tripData.taskDetails = [...newData];
      tripData.tripDetails.fetch_directions_from_tasks = true;
      tripData.tripDetails.fetch_trip_metrics = true;
      if (routeResultMap && routeResultMap[tripId]) {
        cachedTripIds.push(tripId);
      }
      break;
    case TRIP_OFFLINE_ACTIONS.DELETE_TRIP:
      newEditActions.push({
        actionType,
        id: tripId,
        referenceNumber: tripData.tripDetails.trip_reference_number,
        apiPayload: {
          tripId,
        },
        actionMethod: deleteTrip,
        oldData: {
          tripData: {
            ...tripData,
          },
          tripIdx,
        },
      });
      allTripsNewData.splice(tripIdx, 1);
      const tripUnplannedCNData = tripData.taskDetails.map(
        convertTripCNToUnplannedCN,
      );
      allUnplannedCNsNewData.unshift(...tripUnplannedCNData);
      break;
    case TRIP_OFFLINE_ACTIONS.REMOVE_CNS_FROM_TRIPS:
      const plannedCNsTripMap = newData?.reduce((acc, it) => {
        if (it.tripId) {
          if (!acc[it.tripId]) {
            acc[it.tripId] = [];
          }
          acc[it.tripId].push(it.referenceNumber);
        }
        return acc;
      }, {});
      const consignments = [];
      let totalTasksToUnplan = [];
      Object.keys(plannedCNsTripMap).forEach((tripId) => {
        const tripIdx = allTripsNewData.findIndex(
          (t) => t.tripDetails.id === tripId,
        );
        if (tripIdx > -1) {
          const taskDetails = allTripsNewData[tripIdx].taskDetails;
          const orderedReferenceNumbers = taskDetails.map(
            (t) => t.reference_number,
          );
          const tasksToUnplan = [];
          const remainingTasks = [];
          taskDetails.forEach((taskDetail) => {
            if (
              plannedCNsTripMap[tripId].includes(taskDetail.reference_number)
            ) {
              tasksToUnplan.push(taskDetail);
              consignments.push({
                referenceNumber: taskDetail.reference_number,
                taskType: taskDetail.task_type,
              });
            } else {
              remainingTasks.push(taskDetail);
            }
          });

          plannedCNsTripMap[tripId] = {
            tasks: tasksToUnplan,
            orderedReferenceNumbers,
            tripDetails: {
              ...allTripsNewData[tripIdx].tripDetails,
            },
          };

          allTripsNewData[tripIdx] = {
            ...allTripsNewData[tripIdx],
            tripDetails: {
              ...allTripsNewData[tripIdx].tripDetails,
              fetch_directions_from_tasks: true,
              fetch_trip_metrics: true,
              is_metric_breached: false,
            },
            taskDetails: remainingTasks,
          };

          totalTasksToUnplan.push(...tasksToUnplan);

          if (routeResultMap && routeResultMap[tripId]) {
            cachedTripIds.push(tripId);
          }
        }
      });

      newEditActions.push({
        actionType,
        id: null,
        referenceNumber: null,
        apiPayload: { consignments },
        actionMethod: bulkDeleteTripConsignment,
        oldData: {
          plannedCNsTripMap,
        },
      });

      totalTasksToUnplan = totalTasksToUnplan.map(convertTripCNToUnplannedCN);
      allUnplannedCNsNewData.unshift(...totalTasksToUnplan);
      break;
  }

  const newPayload: any = {
    allTrips: allTripsNewData,
    allUnplannedCNs: allUnplannedCNsNewData,
    editActions: newEditActions,
    cachedTripIds,
    redoActions,
  };

  dispatch({
    type: TripManagerMapEditActionTypes.TRIP_MANAGER_MAP_EDIT_ADD_ACTION,
    payload: newPayload,
  });
};

export const updateCNDataOffline = (
  dispatch: (value: ActionType) => void,
  payload: {
    actionType: UNPLANNED_CN_OFFLINE_ACTIONS;
    newData: any;
    allUnplannedCNs: any;
    allTrips: any;
    tripId: any;
    editActions: any;
    extraDetails?: any;
    routeResultMap?: any;
    redoActions: any;
  },
) => {
  const {
    actionType,
    newData,
    allUnplannedCNs,
    allTrips,
    tripId,
    editActions,
    extraDetails,
    routeResultMap,
    redoActions,
  } = payload;
  let allUnplannedCNsNewData = [...allUnplannedCNs];
  const allTripsNewData = [...allTrips];
  const newEditActions = [...editActions];
  let newActionData;
  let extraAction;
  let plannedCNsTripMap;
  const cachedTripIds = [];

  if (
    extraDetails &&
    extraDetails.plannedCns &&
    extraDetails.plannedCns.length
  ) {
    // unplan the planned CNs
    plannedCNsTripMap = extraDetails.plannedCns?.reduce((acc, it) => {
      if (it.tripId) {
        if (!acc[it.tripId]) {
          acc[it.tripId] = [];
        }
        acc[it.tripId].push(it.referenceNumber);
      }
      return acc;
    }, {});
    const consignments = [];
    Object.keys(plannedCNsTripMap).forEach((tId) => {
      const tripIdx = allTripsNewData.findIndex(
        (t) => t.tripDetails.id === tId,
      );
      const taskDetails = allTripsNewData[tripIdx].taskDetails;
      const orderedReferenceNumbers = taskDetails.map(
        (t) => t.reference_number,
      );
      const tasksToUnplan = [];
      const remainingTasks = [];
      taskDetails.forEach((taskDetail) => {
        if (plannedCNsTripMap[tId].includes(taskDetail.reference_number)) {
          tasksToUnplan.push(taskDetail);
          consignments.push({
            referenceNumber: taskDetail.reference_number,
            taskType: taskDetail.task_type,
          });
        } else {
          remainingTasks.push(taskDetail);
        }
      });

      plannedCNsTripMap[tId] = {
        tasks: tasksToUnplan,
        orderedReferenceNumbers,
        tripDetails: { ...allTripsNewData[tripIdx].tripDetails },
      };

      allTripsNewData[tripIdx] = {
        ...allTripsNewData[tripIdx],
        tripDetails: {
          ...allTripsNewData[tripIdx].tripDetails,
          fetch_directions_from_tasks: true,
          fetch_trip_metrics: true,
          is_metric_breached: false,
        },
        taskDetails: remainingTasks,
      };
    });

    extraAction = {
      actionType: TRIP_OFFLINE_ACTIONS.REMOVE_CNS_FROM_TRIPS,
      apiPayload: { consignments },
      actionMethod: bulkDeleteTripConsignment,
      oldData: {
        plannedCNsTripMap,
      },
    };
  }

  switch (actionType) {
    case UNPLANNED_CN_OFFLINE_ACTIONS.CREATE_TRIP:
      const oldData = allUnplannedCNsNewData
        .filter((cn) => newData.consignments.includes(cn.reference_number))
        .map(convertUnplannedCNToTripCN);
      allUnplannedCNsNewData = allUnplannedCNsNewData.filter(
        (cn) => !newData.consignments.includes(cn.reference_number),
      );
      const currTimestamp = +new Date();
      const newTripReferenceNumber = tripId.toUpperCase();
      allTripsNewData.push({
        taskDetails: [
          ...oldData,
          ...Object.values<any>(plannedCNsTripMap ?? {})
            .map((v) => v.tasks)
            .flat(),
        ],
        tripDetails: {
          id: tripId,
          is_local_temp_trip: true,
          trip_reference_number: newTripReferenceNumber,
          organisation_reference_number: newTripReferenceNumber,
          created_at: currTimestamp,
        },
      });

      newActionData = {
        id: tripId,
        actionType,
        apiPayload: newData,
        referenceNumber: newTripReferenceNumber,
        actionMethod: createManualTrip,
        oldData,
        extraAction,
      };
      break;

    case UNPLANNED_CN_OFFLINE_ACTIONS.MOVE_TO_TRIP:
      const oldUnplannedCNs = allUnplannedCNsNewData
        .filter((cn) => newData.consignments.includes(cn.reference_number))
        .map(convertUnplannedCNToTripCN);
      allUnplannedCNsNewData = allUnplannedCNsNewData.filter(
        (cn) => !newData.consignments.includes(cn.reference_number),
      );

      const tripIdx = allTripsNewData.findIndex(
        (t) => t.tripDetails.id === tripId,
      );

      const oldDataObject: any = {
        oldUnplannedCNs,
      };

      if (tripIdx > -1) {
        oldDataObject.tripDetails = {
          ...allTripsNewData[tripIdx].tripDetails,
        };
        allTripsNewData[tripIdx] = {
          ...allTripsNewData[tripIdx],
          tripDetails: {
            ...allTripsNewData[tripIdx].tripDetails,
            fetch_directions_from_tasks: true,
            fetch_trip_metrics: true,
            is_metric_breached: false,
          },
          taskDetails: [
            ...allTripsNewData[tripIdx].taskDetails,
            ...oldUnplannedCNs,
            ...Object.values<any>(plannedCNsTripMap ?? {})
              .map((v) => v.tasks)
              .flat(),
          ],
        };
      }

      newActionData = {
        id: tripId,
        actionType,
        apiPayload: newData,
        referenceNumber: newTripReferenceNumber,
        actionMethod: addCN,
        oldData: oldDataObject,
        extraAction,
      };

      if (routeResultMap && routeResultMap[tripId]) {
        cachedTripIds.push(tripId);
      }
      break;
  }

  if (newActionData) {
    newEditActions.push(newActionData);
  }

  const newPayload: any = {
    allUnplannedCNs: allUnplannedCNsNewData,
    allTrips: allTripsNewData,
    editActions: newEditActions,
    cachedTripIds,
    redoActions,
  };

  dispatch({
    type: TripManagerMapEditActionTypes.TRIP_MANAGER_MAP_EDIT_ADD_ACTION,
    payload: newPayload,
  });
};

export const undoAction = (
  dispatch: (value: ActionType) => void,
  payload: {
    editActions: any;
    redoActions: any;
    allTrips: any;
    allUnplannedCNs: any;
    routeResultMap: any;
  },
) => {
  const {
    editActions,
    redoActions,
    allTrips,
    allUnplannedCNs,
    routeResultMap,
  } = payload;
  const newEditActions = [...editActions];
  const newRedoActions = [...redoActions];
  const recentEditAction = newEditActions.pop();
  const {
    actionType,
    id,
    referenceNumber,
    apiPayload,
    actionMethod,
    oldData,
    extraAction,
  } = recentEditAction;

  const tripId = id;
  const tripIdx = tripId
    ? allTrips.findIndex((t) => t.tripDetails.id === tripId)
    : null;
  const allTripsNewData = [...allTrips];
  const allUnplannedCNsNewData = [...allUnplannedCNs];
  const tripData = tripId ? allTripsNewData[tripIdx] : null;
  const cachedTripIds: any = [];
  let plannedCNsTripMap;
  let newData;
  let plannedCns;

  if (extraAction) {
    const { actionType, oldData } = extraAction;
    switch (actionType) {
      case TRIP_OFFLINE_ACTIONS.REMOVE_CNS_FROM_TRIPS:
        plannedCNsTripMap = oldData.plannedCNsTripMap ?? {};
        Object.entries<any>(plannedCNsTripMap).forEach(([tripId1, data]) => {
          const { tasks, orderedReferenceNumbers, tripDetails } = data;

          tasks.forEach((t) => {
            plannedCns.push({
              referenceNumber: t.reference_number,
              tripId: tripId1,
            });
          });

          const tripDataIdx = allTripsNewData.findIndex(
            (t) => t.tripDetails.id === tripId1,
          );

          if (tripDataIdx > -1) {
            const tripDataFromList = allTripsNewData[tripDataIdx];
            const allTaskDetails = [...tasks, ...tripDataFromList.taskDetails];
            allTripsNewData[tripDataIdx] = {
              ...tripDataFromList,
              tripDetails: {
                ...tripDataFromList.tripDetails,
                ...tripDetails,
                fetch_directions_from_tasks: true,
                fetch_trip_metrics: true,
                is_metric_breached: false,
              },
              taskDetails: orderedReferenceNumbers.map((refNum) =>
                allTaskDetails.find((t) => t.reference_number === refNum),
              ),
            };

            if (routeResultMap && routeResultMap[tripId1]) {
              cachedTripIds.push(tripId1);
            }
          }
        });
        break;
    }
  }

  switch (actionType) {
    case TRIP_OFFLINE_ACTIONS.UPDATE_VEHICLE: {
      const { tripDetails } = oldData;
      const { vehicleId } = apiPayload;
      newData = {
        id: vehicleId,
        name: tripData.tripDetails.vehicle_registration_number,
      };
      allTripsNewData[tripIdx] = {
        ...tripData,
        tripDetails: {
          ...tripData.tripDetails,
          ...tripDetails,
        },
      };
      break;
    }
    case TRIP_OFFLINE_ACTIONS.UPDATE_WORKER: {
      const { tripDetails } = oldData;
      newData = {
        id: tripData.tripDetails.worker_id,
        name: tripData.tripDetails.worker_name,
      };
      allTripsNewData[tripIdx] = {
        ...tripData,
        tripDetails: {
          ...tripData.tripDetails,
          ...tripDetails,
        },
      };
      break;
    }
    case TRIP_OFFLINE_ACTIONS.UPDATE_VEHICLE_MAKE: {
      const { tripDetails } = oldData;
      newData = {
        id: tripData.tripDetails.vehicle_make_id,
        name: tripData.tripDetails.vehicle_make,
      };
      allTripsNewData[tripIdx] = {
        ...tripData,
        tripDetails: {
          ...tripData.tripDetails,
          ...tripDetails,
        },
      };
      break;
    }
    case TRIP_OFFLINE_ACTIONS.REORDER_TASKS: {
      const { taskDetails, tripDetails } = oldData;
      newData = [...tripData.taskDetails];
      allTripsNewData[tripIdx] = {
        ...tripData,
        taskDetails: [...taskDetails],
        tripDetails: {
          ...tripData.tripDetails,
          ...tripDetails,
          fetch_directions_from_tasks: true,
          fetch_trip_metrics: true,
          is_metric_breached: false,
        },
      };
      if (routeResultMap && routeResultMap[tripId]) {
        cachedTripIds.push(tripId);
      }
      break;
    }
    case TRIP_OFFLINE_ACTIONS.DELETE_TRIP: {
      const { tripData: deletedTripData, tripIdx: tripIdxForDelete } = oldData;
      // insert at same index
      allTripsNewData.splice(tripIdxForDelete, 0, {
        ...deletedTripData,
      });
      break;
    }
    case TRIP_OFFLINE_ACTIONS.REMOVE_CNS_FROM_TRIPS: {
      const { plannedCNsTripMap } = oldData;
      newData = [];
      newData = Object.entries<any>(plannedCNsTripMap).forEach(
        ([tripId, data]) => {
          data.tasks.forEach((t) => {
            newData.push({
              referenceNumber: t.reference_number,
              tripId,
            });
          });
        },
      );
      Object.entries<any>(plannedCNsTripMap).forEach(([tripId1, data]) => {
        const { tasks, orderedReferenceNumbers, tripDetails } = data;

        const tripDataIdx = allTripsNewData.findIndex(
          (t) => t.tripDetails.id === tripId1,
        );

        if (tripDataIdx > -1) {
          const tripDataFromList = allTripsNewData[tripDataIdx];
          const allTaskDetails = [...tasks, ...tripDataFromList.taskDetails];
          allTripsNewData[tripDataIdx] = {
            tripDetails: {
              ...tripDataFromList.tripDetails,
              ...tripDetails,
              fetch_directions_from_tasks: true,
              fetch_trip_metrics: true,
              is_metric_breached: false,
            },
            taskDetails: orderedReferenceNumbers.map((refNum) =>
              allTaskDetails.find((t) => t.reference_number === refNum),
            ),
          };

          if (routeResultMap && routeResultMap[tripId1]) {
            cachedTripIds.push(tripId1);
          }
        }
      });
      break;
    }
    case UNPLANNED_CN_OFFLINE_ACTIONS.CREATE_TRIP:
      const tasksToUnplan = (oldData ?? []).map(convertTripCNToUnplannedCN);
      newData = { ...apiPayload };
      allUnplannedCNsNewData.unshift(...tasksToUnplan);
      allTripsNewData.splice(tripIdx, 1);
      break;
    case UNPLANNED_CN_OFFLINE_ACTIONS.MOVE_TO_TRIP: {
      newData = { ...apiPayload };
      const otherTripsPlannedCNsToRemove = Object.values<any>(
        plannedCNsTripMap ?? {},
      )
        .map((v) => v.tasks)
        .flat();
      const { oldUnplannedCNs, tripDetails } = oldData;
      const unplannedCNsToRemove = (oldUnplannedCNs ?? []).map(
        convertTripCNToUnplannedCN,
      );
      const referenceNumList = [
        ...otherTripsPlannedCNsToRemove,
        ...unplannedCNsToRemove,
      ].map((t) => t.reference_number);
      allTripsNewData[tripIdx] = {
        ...allTripsNewData[tripIdx],
        tripDetails: {
          ...allTripsNewData[tripIdx].tripDetails,
          ...tripDetails,
          fetch_directions_from_tasks: true,
          fetch_trip_metrics: true,
          is_metric_breached: false,
        },
        taskDetails: allTripsNewData[tripIdx].taskDetails.filter(
          (t) => !referenceNumList.includes(t.reference_number),
        ),
      };
      allUnplannedCNsNewData.unshift(...unplannedCNsToRemove);
      if (routeResultMap && routeResultMap[tripId]) {
        cachedTripIds.push(tripId);
      }
      break;
    }
  }

  const redoActionToPush = {
    ...recentEditAction,
    newData,
  };

  if (extraAction) {
    redoActionToPush.extraAction = extraAction;
    switch (extraAction.actionType) {
      case TRIP_OFFLINE_ACTIONS.REMOVE_CNS_FROM_TRIPS:
        redoActionToPush.extraAction.plannedCns = plannedCns;
        break;
    }
  }

  newRedoActions.push(redoActionToPush);

  dispatch({
    type: TripManagerMapEditActionTypes.TRIP_MANAGER_MAP_EDIT_ADD_ACTION,
    payload: {
      allUnplannedCNs: allUnplannedCNsNewData,
      allTrips: allTripsNewData,
      editActions: newEditActions,
      cachedTripIds,
      redoActions: newRedoActions,
    },
  });
};

export const redoAction = (
  dispatch: (value: ActionType) => void,
  payload: {
    editActions: any;
    redoActions: any;
    allTrips: any;
    allUnplannedCNs: any;
    routeResultMap: any;
  },
) => {
  const {
    editActions,
    redoActions,
    allTrips,
    allUnplannedCNs,
    routeResultMap,
  } = payload;
  const newRedoActions = [...redoActions];
  const recentRedoAction = newRedoActions.pop();

  const { actionType, newData, id, extraAction } = recentRedoAction;

  let extraDetails;

  if (extraAction && extraAction.plannedCns) {
    extraDetails = {
      plannedCns: extraAction.plannedCns,
    };
  }

  if (Object.keys(TRIP_OFFLINE_ACTIONS).includes(actionType)) {
    updateTripDataOffline(dispatch, {
      tripId: id,
      actionType,
      allTrips,
      allUnplannedCNs,
      newData,
      editActions,
      routeResultMap,
      redoActions: newRedoActions,
    });
  } else {
    updateCNDataOffline(dispatch, {
      actionType,
      tripId: id,
      newData,
      allUnplannedCNs,
      allTrips,
      editActions,
      routeResultMap,
      extraDetails,
      redoActions: newRedoActions,
    });
  }
};

export const resetSelectedMarkers = (dispatch: (value: ActionType) => void) => {
  dispatch({
    type: TripManagerMapEditActionTypes.TRIP_MANAGER_MAP_EDIT_RESET_SELECTED_MARKERS,
    payload: null,
  });
};

export const saveOfflineChanges = async (
  dispatch: (value: ActionType) => void,
  payload: { editActions: any; allTrips: any },
) => {
  dispatch({
    type: TripManagerMapEditActionTypes.TRIP_MANAGER_MAP_EDIT_SET_SAVE_LOADER,
    payload: {
      saveLoading: true,
      saveFailureArray: {},
    },
  });

  const { editActions, allTrips } = payload;
  const bulkActions: any = [];
  const tripIdActionsMap = editActions.reduce((acc, it) => {
    if (it.id) {
      if (acc[it.id]) {
        acc[it.id].push(it);
      } else {
        acc[it.id] = [it];
      }
    } else {
      bulkActions.push(it);
    }
    return acc;
  }, {});

  const saveFailureArray = {};

  const promises = Object.entries<any>(tripIdActionsMap).map(
    async ([tripId, tripActions]) => {
      if (tripId.includes('temp_trip')) {
        // For local trip, if trip is not deleted after creation
        // All the actions should be coeleaced into a single create trip API
        const isTripDeleted = !!tripActions.find(
          (action) => action.actionType === TRIP_OFFLINE_ACTIONS.DELETE_TRIP,
        );

        if (!isTripDeleted) {
          const createAction = tripActions.find(
            (action) =>
              action.actionType === UNPLANNED_CN_OFFLINE_ACTIONS.CREATE_TRIP,
          );

          if (createAction) {
            const { apiPayload, actionMethod, referenceNumber } = createAction;
            const tripData = allTrips.find(
              ({ tripDetails }) => tripDetails.id === tripId,
            );

            if (apiPayload && actionMethod && tripData) {
              apiPayload.consignments = tripData?.taskDetails?.map(
                (task) => task.reference_number,
              );
              if (tripData.tripDetails.worker_id) {
                apiPayload.workerId = tripData.tripDetails.worker_id;
              }
              if (tripData.tripDetails.vehicle_make_id) {
                apiPayload.vehicleMakeId = tripData.tripDetails.vehicle_make_id;
              }
              if (tripData.tripDetails.vehicle_id) {
                apiPayload.changeVehicle = true;
                apiPayload.vehicleId = tripData.tripDetails.vehicle_id;
              }

              const resp = await actionMethod(apiPayload);

              if (!resp.isSuccess) {
                saveFailureArray[referenceNumber] =
                  saveFailureArray[referenceNumber] ?? [];
                saveFailureArray[referenceNumber].push(
                  `CREATE TRIP: ${resp.errorMessage}`,
                );
              }
            }
          }
        }
      } else {
        for (let i = 0; i < tripActions.length; i++) {
          const actionData = tripActions[i];
          const { apiPayload, actionMethod, referenceNumber, actionType } =
            actionData;

          if (apiPayload && actionMethod) {
            // eslint-disable-next-line no-await-in-loop
            const resp = await actionMethod(apiPayload);

            if (!resp.isSuccess) {
              saveFailureArray[referenceNumber] =
                saveFailureArray[referenceNumber] ?? [];
              saveFailureArray[referenceNumber].push(
                `${actionType}: ${resp.errorMessage}`,
              );
              break;
            }
          }
        }
      }
    },
  );

  promises.push(
    ...bulkActions.map(async (actionData) => {
      const { apiPayload, actionMethod, actionType, extraAction } = actionData;

      if (extraAction) {
        const {
          // actionType: extraActionType,
          apiPayload: extraApiPayload,
          actionMethod: extraActionMethod,
        } = extraAction;

        // eslint-disable-next-line no-await-in-loop
        const resp = await extraActionMethod(extraApiPayload);

        if (!resp.isSuccess) {
          saveFailureArray[actionType] = saveFailureArray[actionType] ?? [];
          saveFailureArray[actionType].push(resp.errorMessage);
        }
      }

      if (apiPayload && actionMethod) {
        const resp = await actionMethod(apiPayload);

        if (!resp.isSuccess) {
          saveFailureArray[actionType] = saveFailureArray[actionType] ?? [];
          saveFailureArray[actionType].push(resp.errorMessage);
        }
      }
    }),
  );

  await Promise.allSettled(promises);

  const newPayload: any = {
    saveFailureArray,
    saveLoading: false,
  };

  if (Object.keys(saveFailureArray).length === 0) {
    newPayload.editActions = [];
  }

  dispatch({
    type: TripManagerMapEditActionTypes.TRIP_MANAGER_MAP_EDIT_SET_SAVE_LOADER,
    payload: newPayload,
  });
};

export const toggleShowPartition = (
  dispatch: (value: ActionType) => void,
  payload: {
    showPartitions: boolean;
  },
) => {
  const { showPartitions } = payload;
  dispatch({
    type: TripManagerMapEditActionTypes.TRIP_MANAGER_MAP_TOGGLE_PARTITIONS,
    payload: {
      showPartitions,
    },
  });
};

export const fetchPartitionDataFromHub = async (
  dispatch: (value: ActionType) => void,
  hubData: any,
) => {
  const { id, routing_strategy_id } = hubData;
  if (id && routing_strategy_id) {
    const data = await fetchSavedParamsForHub({
      hub_id: id,
      id: routing_strategy_id,
    });

    const partitions =
      data?.data && Array.isArray(data.data)
        ? data.data.reduce((acc, it) => {
            acc.push(...(it?.partitions_data ?? []));
            return acc;
          }, [])
        : [];

    dispatch({
      type: TripManagerMapEditActionTypes.TRIP_MANAGER_MAP_TOGGLE_PARTITIONS,
      payload: {
        partitionData: partitions,
        showPartitions: false,
      },
    });
  }
};

import * as React from 'react';
import i18n from '../../../../../i18n';
import withStyles, { WithStylesProps } from 'react-jss';
import { CheckOutlined } from '@ant-design/icons';
import MenuIcon from '../../../../common/icons/MenuIcon';
import Media from 'react-media';
import classNames from 'classnames';
import HelpCenter from 'src/components/navbar/HelpCenter';
import QuickSearchModal from '../../../../common/QuickSearchModal';
import { MOBILE_BREAK_POINT, applicationType } from 'src/globals';
import { bindActionCreators } from 'redux';
import { trackGaEvents } from 'src/utils/googleAnalyticsHelper';
import { BUTTON_CLICK } from 'src/utils/gaConstants';
import { toggleSideMenu } from 'src/actions/navigationActions';
import styles from './GenericNavbar.styles';
import { connect } from 'react-redux';
import { RouteChildrenProps, withRouter } from 'react-router';
import { ArrowLeftOutlined, QuestionCircleOutlined, ReloadOutlined, SwapOutlined, ThunderboltFilled } from '@ant-design/icons';
import { Avatar, Button, Divider, Dropdown, Menu, Space, message,Select, Tooltip  } from 'antd';
import { logoutUserAndClearCache } from 'src/api/utils';
import ChangePasswordModal from '../../../../common/navbarModals/changePassword/changePasswordModal';
import { LogoutIcon } from '../../../../common/icons/LogoutIcon';
import SwitchViewType from '../../../../common/SwitchViewType';
import { Link } from 'react-router-dom';
import { ActivityLogIcon, LanguageIcon } from '../../../../common/icons/NavbarMenuIcons';
import { WithTranslation, withTranslation } from 'react-i18next';
import { getActivityLogRoute } from 'src/routing/utils';

const { useState } = React;

interface IProps extends RouteChildrenProps, WithStylesProps<ReturnType<typeof styles>>, WithTranslation {
    hideMenuBar?: boolean;
    pageTitle: string;
    toggleSideMenu: () => any;
    masterData: any;
    username: string;
    email: string;
    showLanguageChangeOption: boolean;
    name: string;
    shouldDisplayBack: boolean;
    showProductBoardHelpIcon?: boolean;
    hideProductUpdates?: boolean;
    viewTypeForSwitchView?: string;
    showHybridView: boolean;
    showGenericSideMenu: boolean;
    showLogisticsExpressOptions: boolean;
    leftComponents: any;
}

const GenericNavbar = (props: IProps) => {
    const [helpVisible, setHelpVisible] = useState<boolean>(false);
    const [quickSearchVisible, setQuickSearchVisible] = useState<boolean>(false);
    const [isChangePassowrdModalVisible, setChangePassowrdModalVisible] = useState<boolean>(false);

    const {
        history,
        classes,
        toggleSideMenu,
        hideMenuBar,
        pageTitle,
        masterData,
        username,
        name,
        email,
        showLanguageChangeOption,
        hideProductUpdates,
        shouldDisplayBack,
        showLogisticsExpressOptions,
        showHybridView,
        showGenericSideMenu,
        showProductBoardHelpIcon,
        leftComponents,
        t,
    } = props;

    const handleBackClick = () => {
        history.goBack();
    };

    const renderMenu = () => {
        return (
            <BackOrMenu />
        );
    };

    const BackOrMenu = () => {
        if (shouldDisplayBack) {
            return (
                <Media query={`(max-width: ${MOBILE_BREAK_POINT}px)`}>
                    {matches =>
                        matches ? (
                            <ArrowLeftOutlined
                                className={classes.hamburger}
                                onClick={handleBackClick}
                            />
                        ) : (<div id="sidemenu_hamburger_icon">
                            <MenuIcon
                                className={classes.hamburger}
                                onClick={toggleSideMenu}
                            />
                        </div>)
                    }
                </Media>
            );
        }

        return (
            <div id="sidemenu_hamburger_icon">
                <MenuIcon
                    className={classes.hamburger}
                    onClick={toggleSideMenu}
                />
            </div>
        );
    };

    const renderHelp = () => {
        if (!helpVisible) {
            return null;
        }
        return (
            <HelpCenter
                isVisible={helpVisible}
                onModalClose={() => setHelpVisible(false)}
            />
        );
    };

    const renderQuickSearch = () => {
        if (!masterData.show_logistics_express_options && !masterData.show_ondemand_options) {
            return null;
        }
        return (
            <QuickSearchModal
                masterData={masterData}
                isModalVisible={quickSearchVisible}
                setModalVisibility={(val) => setQuickSearchVisible(val)}
            />
        )
    };

    const showHelpModal = () => {
        trackGaEvents({ actionType: BUTTON_CLICK }, { label: "Updates & Help Center" });
        setHelpVisible(true);
    };

    const renderQuestionMark = () => {
        if (!showProductBoardHelpIcon || hideProductUpdates) {
            return null;
        }
        return (
            <div className={classes.trackIcon}
                onClick={() => showHelpModal()}>
                <QuestionCircleOutlined className={classes.helpIcon} />
            </div>
        );
    };

    const renderProfile = () => {
        return (
            <Avatar>
                {username && username[0]}
            </Avatar>
        );
    };
    const languageList =  [
      { key:'en', val: 'English' },
      { key:'ba', val:  'Bahasa'  },
      { key: 'ar', val: 'Arabic' },
    ];

    const handleLanguageChange = (val: string) => {
      i18n.changeLanguage(val, (err,t) => {
        if(err) {
          message.error(err);
          return;
        }
        message.success('Language changed successfully');
        window.location.reload();
      });
    };

    const generateOptionsFromKeyValue = (optionsList: any) => {
      const x:any = [];
      if (optionsList) {
      optionsList.forEach((ele:any) => {
        x.push(<Select.Option key={ele.key} value = {ele.key} >{ele.val}{i18n.language === ele.key ?
             <CheckOutlined style={tickStyle}  /> : null}</Select.Option>);
      });
    }
    return x;
    };

    const tickStyle = {
      marginLeft: '30px',
      color: 'green',
    };

    const renderProfileDropdown = () => {
        if (hideMenuBar) {
            return null;
        }
        const dropdownMenu = (
            <div className={classes.profileDropDown}>
                <div key="logo" style={{ justifyContent: 'center', textAlign: 'center' }}>
                    <img style={{ maxHeight: '80px', maxWidth: '100%' }} src={masterData.logoUrl} />
                </div>
                <Divider key="logo-divider" style={{ margin: '16px 0 0 0' }} />
                {/* {username} */}

                <div style={{ display: 'flex', flexDirection: 'row', padding: '0 16px 0 8px', height: 38, margin: '8px 0 4px 0', alignItems: 'center'}}>
                    {renderProfile()}
                    <div style={{ display: 'flex', flexDirection: 'column', margin: '0 5px'}}>
                        <div style={{
                            fontFamily: 'Open Sans',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: 12,
                            color: '#111111',
                        }}>
                            {name || username}
                        </div>
                        {email &&
                            <div style={{
                                fontFamily: 'Open Sans',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: 10,
                                color: '#333333',
                            }}>
                                {email}
                            </div>
                        }
                    </div>
                </div>
                <Menu mode="inline" style={{ width: 256, fontSize: 12, fontWeight: 600 }}>
                    {masterData.show_view_type_switch ?
                        <Menu.Item
                            key="switchViewType">
                            <SwitchViewType>
                                <SwapOutlined style={{ marginRight: 10 }}/>
                                Switch View
                            </SwitchViewType>
                        </Menu.Item>
                    : null }
                    { (showLogisticsExpressOptions
                        || showHybridView
                        || showGenericSideMenu
                    ) && applicationType === 'ops'
                    && masterData.show_password_policy ?
                        <Menu.Item
                            key="activityLog"
                            style={{ height: 38 }}
                            icon={<ActivityLogIcon />}>
                            <Link to={getActivityLogRoute()}>
                                {t('activity_log')}
                            </Link>
                        </Menu.Item>
                    : null }
                     <Menu.Item
                        key="changePassword"
                        style={{ height: 38 }}
                        onClick={() => setChangePassowrdModalVisible(true)}
                        icon={<ReloadOutlined />}>
                            {t('reset_password')}
                    </Menu.Item>
                    {
          showLanguageChangeOption && (
            <Menu.Item style = {{marginLeft: '0px', marginBottom: '20px', marginTop: '10px'}}
             onClick={(e) => e.domEvent.stopPropagation()}
             icon={<LanguageIcon />}
            >
              <Select
                onChange={handleLanguageChange}
                value={t('change_language')}
                defaultValue={i18n.language}
                style={{width: '150px'}}
                >
                  {generateOptionsFromKeyValue(languageList)}
              </Select>
              </Menu.Item>
        )}
                    <Menu.Item
                        key="logout" onClick={() => logoutUserAndClearCache()}
                        style={{ color: '#FF4D4F', height: 38 }} icon={<LogoutIcon />}>
                        {t('logout')}
                    </Menu.Item>
                </Menu>
            </div>
        );
        return (
            <Dropdown overlay={dropdownMenu} trigger={['click']}>
                <Button type="link" style={{ padding: 0, marginLeft: 8 }}>
                    {renderProfile()}
                </Button>
            </Dropdown>
        );
    };

    const renderRightComponents = () => {
        return (
            <div className={classes.filterGroupToolsRight}>
                {renderQuestionMark()}
                {renderProfileDropdown()}
            </div>
        );
    };

    const renderLeftComponents = () => {
        return (
            <div className={classNames(classes.filterGroupToolsLeft)}>
                {leftComponents?.map(
                    (item, i) => <div className={classes.toolContainer} key={i}> {item} </div>,
                )}          
            </div>
        );
    };

    const renderMenuAndPageTitle = () => {
        const title = pageTitle || 'SHIPSY';
        const threshold = 35;
        const isOverflow = title.length > threshold;
        return (
            <div className={classes.leftDiv} >
                <span>
                    {renderMenu()}
                </span>
                <span className={classNames(classes.pageTitle)}>
                    {isOverflow ? <Tooltip title={title}>{title.substring(0, threshold)}...</Tooltip> : title}
                </span>
            </div>
        );
    };

    const renderChangePassword = () => {
        if (!isChangePassowrdModalVisible) return;
        return (
            <ChangePasswordModal
                isModalActive={isChangePassowrdModalVisible}
                onModalClose={() => setChangePassowrdModalVisible(false)}
            />
        );
    };

    return (
        <div className={classes.navbar}>
            {renderMenuAndPageTitle()}
            {renderLeftComponents()}
            {renderRightComponents()}
            {renderHelp()}
            {renderQuickSearch()}
            {renderChangePassword()}
        </div>
    );

};

const mapStateToProps = ({ masterData }, ownProps) => {
    return {
        masterData,
        username: masterData.user_data?.username,
        name: masterData.user_data?.name,
        email: masterData.user_data?.email,
        showLanguageChangeOption : masterData.show_language_change_option || false,
        viewTypeForSwitchView: ownProps.viewType || masterData.viewType,
        showProductBoardHelpIcon: !masterData.hide_product_board,
        showGenericSideMenu: masterData?.ops_dashboard_config?.parts_to_show?.ops_sidemenu_list_enable,
        showLogisticsExpressOptions: masterData?.show_logistics_express_options,
        showHybridView: masterData.show_logistics_hybrid_express_view,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        toggleSideMenu,
    }, dispatch);
};

const NavbarWithTranslation = withTranslation()(GenericNavbar);
const NavbarStyled = withStyles(styles, { injectTheme: true })(NavbarWithTranslation);
const NavbarConnected = connect(mapStateToProps, mapDispatchToProps)(NavbarStyled);
export default withRouter(NavbarConnected) as React.ComponentType<any>;

import Spiderfy from '../spiderfy/index';
import type { MapRef } from 'react-map-gl';

import { SPIDERFY_DEFAULT_CONFIG } from '@components/common/UIMap/utils/constants';
import { IUIMapIcon } from '@components/common/UIMap/utils/types';

import { GeocodingApi, Configuration } from '@stadiamaps/api';
import { STADIA_MAPS_API_KEY } from '@src/globals';

export const applySpiderfyToLayer = (
  mapRef: React.RefObject<MapRef>,
  layerId: string,
  config: any = SPIDERFY_DEFAULT_CONFIG,
) => {
  const spiderfy = Spiderfy(mapRef, config);
  spiderfy.applyTo(layerId);
  return spiderfy;
};

export const loadImages = (
  mapRef: React.RefObject<MapRef>,
  images: IUIMapIcon[],
) => {
  images.forEach((image) => {
    if (!(mapRef as any).hasImage(image.name)) {
      const customImage = new Image(image.imageHeight, image.imageWidth);
      customImage.onload = (e) => {
        if (image.maintainRatio) {
          const ratio = customImage.naturalHeight / customImage.naturalWidth;
          customImage.width = image.imageWidth;
          customImage.height = ratio * image.imageWidth;
        }
        (mapRef as any).addImage(image.name, customImage, { sdf: image.isSdf });
      };
      customImage.crossOrigin = 'Anonymous';
      customImage.src = image.src;
    }
  });
};

export const createFeatures = (shapeArray = [], featureShape = 'Point') => {
  if (!(shapeArray.length > 0)) {
    return {
      type: 'FeatureCollection',
      features: [],
    };
  }

  let features = [];
  features = shapeArray.map((shape) => {
    return {
      id: shape.id,
      type: 'Feature',
      geometry: {
        type: featureShape,
        coordinates: shape.coordinates,
      },
      properties: shape.properties,
    };
  });

  return {
    type: 'FeatureCollection',
    features,
  };
};

export const calculateBounds = (bounds = [[]]) => {
  const filteredBounds = bounds.filter((point) => {
    if (point && point[0] && point[1]) return point;
  });

  const applyToArray = (func, array) => func.apply(Math, array);

  const pointsLong = filteredBounds.map((point) => point[0]);
  const pointsLat = filteredBounds.map((point) => point[1]);
  const cornersLongLat: [number, number][] = [
    [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
    [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
  ];

  const isNotValid = cornersLongLat.some((corner) =>
    corner.some((coord) => isNaN(coord) || !isFinite(coord)),
  );
  return isNotValid ? null : cornersLongLat;
};

export const sampleMapDataPoints = (coordinates: number[][] = []) => {
  const threshold = 50000;
  const length = coordinates.length;

  if (length < threshold) {
      return coordinates;
  } else {
      const skip = Math.floor(length / (length - threshold));
      return coordinates.filter((_, i) => i % skip !== skip - 1);
  }
}

const config = new Configuration({ apiKey: STADIA_MAPS_API_KEY });
export const geocodingApi = new GeocodingApi(config);
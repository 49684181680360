import * as React from 'react';
import { Source, useMap, Layer } from 'react-map-gl';
import type { LayerProps } from 'react-map-gl';
import { UIMapPopup } from '@components/common/UIMap';
import { createFeatures } from '@components/common/UIMap/utils/utils';
import {
  IUIHoverPopup,
  IUIMarkerGroup,
  IUIMarker,
} from '@components/common/UIMap/utils/types';
import { applySpiderfyToLayer } from '@components/common/UIMap/utils/utils';

const POPUP_HOVER_DEFAULT_STATE: IUIHoverPopup = {
  id: '',
  isVisible: false,
  coordinates: [undefined, undefined],
  content: <></>,
};

const UIMarkerGroup = (props: IUIMarkerGroup) => {
  const {
    id,
    spiderfy,
    markers,
    cluster = false,
    clusterMaxZoom = 22,
    clusterRadius = 50,
    closePopupOnClick = true,
    layerProps = {},
    clusterProps = {},
    ...rest
  } = props;

  const { current: map } = useMap();
  const spiderfyRef = React.useRef(null);
  const [visiblePopups, setVisiblePopups] = React.useState([]);
  const [sourceData, setSourceData] = React.useState<any>({});
  const [markerData, setMarkerData] = React.useState<IUIMarker[]>([]);
  const [hoverPopup, setHoverPopup] = React.useState<IUIHoverPopup>(
    POPUP_HOVER_DEFAULT_STATE,
  );
  const clusterLayerId = `${id}-cluster`;
  const markerLayerId = `${id}-marker`;

  const onLayerClickCallback = (e) => {
    setVisiblePopups((prevState) => [
      ...prevState,
      e.features[0].properties?.id,
    ]);
  };

  const queryRenderedFeatures = (e) => {
    return map.getMap().queryRenderedFeatures(e.point);
  };

  const onClickCallback = (e) => {
    const features = queryRenderedFeatures(e);

    // spiderfy leaves popover
    if (
      features.length > 0 &&
      features?.[0]?.source.includes(`${markerLayerId}-spiderfy-leaf`)
    ) {
      setVisiblePopups((prevState) => [
        ...prevState,
        features[0].properties?.id,
      ]);
    }

    // marker click handler if onClick handler passed to marker data
    if (
      features.length > 0 &&
      (features?.[0]?.source === id ||
        features?.[0]?.source.includes(`${markerLayerId}-spiderfy-leaf`))
    ) {
      const propId = features[0]?.properties?.id;
      e.preventDefault();
      e.originalEvent?.preventDefault();
      e.originalEvent?.stopPropagation();
      if (propId) {
        const markerDataIdx: any = markers.findIndex(
          (m: any) => m.properties?.id === propId,
        );
        if (markerDataIdx > -1 && markers[markerDataIdx].onClick) {
          const shiftKey = e.originalEvent.shiftKey;
          const altKey = e.originalEvent.altKey;
          const ctrlKey = e.originalEvent.ctrlKey;
          markers[markerDataIdx].onClick(
            {
              shiftKey,
              altKey,
              ctrlKey,
            },
            markerDataIdx,
            propId,
          );
        }
      }
    }

    if (
      features.length > 0 &&
      features?.[0]?.source === id &&
      features[0].layer.id !== clusterLayerId
    ) {
      setVisiblePopups((prevState) => [
        ...prevState,
        features[0].properties?.id,
      ]);
      return;
    }

    // close all open popups on outside click
    if (!features.length || features[0].source === 'openmaptiles') {
      setVisiblePopups([]);
    }
  };

  const renderMarkers = () => {
    const features = createFeatures(markers);
    setMarkerData(markers);
    setSourceData(features);
  };

  React.useEffect(() => {
    const defaultClickEventId = [markerLayerId];
    const spiderfyClickEventId = [
      clusterLayerId,
      `${markerLayerId}-spiderfy-leaf`,
    ];

    const eventIds = spiderfy
      ? defaultClickEventId.concat(spiderfyClickEventId)
      : defaultClickEventId;

    eventIds.map((eventId) => {
      map.getMap().on('click', eventId, onLayerClickCallback);
    });

    if (!closePopupOnClick) {
      map.getMap().on('click', onClickCallback);
    }

    if (spiderfy && spiderfyRef?.current) {
      setTimeout(() => {
        if (map.getMap().getLayer(markerLayerId)) {
          spiderfyRef?.current?.unspiderfyAll();
          spiderfyRef?.current?.resetListeners();
          spiderfyRef?.current?.applyTo(markerLayerId);
        }
      }, 0);
    }

    return () => {
      // cleanup
      eventIds.map((eventId) => {
        map.getMap().off('click', eventId, onLayerClickCallback);
      });
      if (!closePopupOnClick) {
        map.getMap().off('click', onClickCallback);
      }
    };
  }, [markerData]);

  React.useEffect(() => {
    const onMouseMoveCallback = (e) => {
      const features = queryRenderedFeatures(e);

      const isNode =
        features.length > 0 &&
        features[0].source === id &&
        !features[0].properties?.cluster;

      if (!isNode && !spiderfy) {
        setHoverPopup(POPUP_HOVER_DEFAULT_STATE);
        return;
      }

      const marker =
        markers.filter((m) => m.id === features?.[0]?.properties?.id) || [];

      const popupHoverContent = marker[0]?.popupHoverContent;

      if (isNode && popupHoverContent) {
        setHoverPopup({
          id: features[0].properties?.['id'],
          isVisible: true,
          coordinates: [e.lngLat.lng, e.lngLat.lat],
          content: <div>{popupHoverContent}</div>,
        });
        return;
      }

      const leaf = features?.find((f) =>
        f.layer.id.includes(`${markerLayerId}-spiderfy-leaf`),
      );

      // if leaf/child node not found, don't show popup for any other layer
      if (!leaf) {
        setHoverPopup(POPUP_HOVER_DEFAULT_STATE);
        return;
      }

      // leaves that are in cluster
      if (leaf && hoverPopup.id !== leaf.layer?.id && popupHoverContent) {
        setHoverPopup({
          id: leaf.layer?.id,
          isVisible: true,
          coordinates: [e.lngLat.lng, e.lngLat.lat],
          content: <div>{popupHoverContent}</div>,
        });
      }
    };

    map.getMap().on('mousemove', onMouseMoveCallback);

    renderMarkers();

    return () => {
      map.getMap().off('mousemove', onMouseMoveCallback);
    };
  }, [markers]);

  React.useEffect(() => {
    map.on('load', () => {
      spiderfyRef.current = applySpiderfyToLayer(
        map.getMap() as any,
        markerLayerId,
      );
    });

    return () => {
      if (spiderfy && spiderfyRef?.current) {
        spiderfyRef?.current?.unspiderfyAll();
        spiderfyRef?.current?.resetListeners();
      }
    };
  }, []);

  const onPopupClose = (e, id) => {
    const index = visiblePopups.indexOf(id);
    if (index > -1) {
      const updatedPopups = [...visiblePopups];
      updatedPopups.splice(index, 1);
      setVisiblePopups(updatedPopups);
    }
  };

  const getLayerProps = (): LayerProps => {
    return {
      id: markerLayerId,
      source: id,
      type: 'symbol',
      layout: {
        'text-field': layerProps['text-field'] || [
          'coalesce',
          ['get', 'text-field'],
          '',
        ],
        'text-font': ['Roboto Medium'],
        'text-justify': 'auto',
        'text-offset': layerProps['text-offset'] || [
          'coalesce',
          ['get', 'text-offset'],
          ['literal', [0, 1.25]],
        ],
        'text-anchor': ['coalesce', ['get', 'text-anchor'], 'top'],
        'text-size': ['coalesce', ['get', 'text-size'], 11],
        'icon-image': layerProps['icon'] || [
          'concat',
          ['coalesce', ['get', 'icon'], 'spiderfy'],
          ['coalesce', ['get', 'color'], '#000'],
        ],
        'icon-size': layerProps['size'] || ['coalesce', ['get', 'size'], 1],
        'icon-allow-overlap': true,
      },
      paint: {
        'icon-color': layerProps['color'] || [
          'coalesce',
          ['get', 'color'],
          '#000',
        ],
        'icon-halo-color': ['coalesce', ['get', 'icon-halo-color'], ''],
        'text-color': ['coalesce', ['get', 'text-color'], '#202'],
        'text-halo-color': ['coalesce', ['get', 'text-halo-color'], '#fff'],
      },
    };
  };

  const getClusterCountLayer = (): LayerProps => {
    return {
      id: clusterLayerId,
      source: id,
      type: 'symbol',
      filter: ['has', 'point_count'],
      layout: {
        'icon-image':
          clusterProps['icon'] || clusterProps['color']
            ? `${clusterProps['icon']}${clusterProps['color']}`
            : 'spiderfy',
        'icon-size': clusterProps['size'] || 1,
        'icon-allow-overlap': true,
      },
      paint: {
        'icon-color': clusterProps['color'] || '#000',
        'icon-halo-color': clusterProps['icon-halo-color'] || '#000',
      },
    };
  };

  return (
    <>
      <Source
        id={id}
        key={markers as any}
        type="geojson"
        data={sourceData}
        cluster={cluster}
        clusterMaxZoom={clusterMaxZoom}
        clusterRadius={clusterRadius}
        {...rest}
      >
        {spiderfy ? (
          <>
            <Layer {...getLayerProps()} />
            <Layer {...getClusterCountLayer()} />
          </>
        ) : (
          <Layer {...getLayerProps()} />
        )}
      </Source>
      {markerData?.length > 0 &&
        markerData.map((marker) =>
          marker.popupClickContent ? (
            <UIMapPopup
              id={marker.id}
              key={marker.id}
              coordinates={marker.coordinates}
              showPopup={visiblePopups.includes(marker.id)}
              popupContent={marker.popupClickContent}
              onPopupClose={onPopupClose}
              closeOnClick={closePopupOnClick}
            />
          ) : (
            <React.Fragment key={marker.id}></React.Fragment>
          ),
        )}
      <UIMapPopup
        coordinates={hoverPopup.coordinates}
        showPopup={hoverPopup.isVisible}
        popupContent={hoverPopup.content}
      />
    </>
  );
};

export default UIMarkerGroup;

/* eslint-disable max-len */
import * as base64 from 'base-64';
import * as _ from 'lodash';
const config = require('./config').default;
import { getHostname, getOrganisationPrettyName } from './utils/helper';
const mergedConfig = config.SHOW_DEV_TOOLS ?
  {
    ...config,
    ...JSON.parse(window.localStorage.getItem('dev-tools-config') || '{}'),
  } :
  {...config};

export const ORGANISATION_PRETTY_NAME = config.ORGANISATION_PRETTY_NAME || '';
const organisationPrettyName = getOrganisationPrettyName(ORGANISATION_PRETTY_NAME);
const hostname = getHostname();

export const DEFAULT_ORG = config.DEFAULT_ORG;
export const API_BASE_URL = (config.PROJECTX_BASE_URL_CONFIG && config.PROJECTX_BASE_URL_CONFIG[hostname]) ||
  (config.PROJECTX_BASE_URL_CONFIG && config.PROJECTX_BASE_URL_CONFIG[organisationPrettyName]) ||
  mergedConfig.API_BASE_URL;
export const BUSINESS_BOOKING_API_URL = (mergedConfig.BUSINESS_BOOKING_CONFIG && mergedConfig.BUSINESS_BOOKING_CONFIG[hostname]) || (mergedConfig.BUSINESS_BOOKING_CONFIG && mergedConfig.BUSINESS_BOOKING_CONFIG[organisationPrettyName]) ||
  mergedConfig.BUSINESS_BOOKING_API_URL;
export const ONLINE_BOOKING_BASE_URL = (mergedConfig.ONLINE_BOOKING_CONFIG && mergedConfig.ONLINE_BOOKING_CONFIG[hostname]) || (mergedConfig.ONLINE_BOOKING_CONFIG && mergedConfig.ONLINE_BOOKING_CONFIG[organisationPrettyName]) ||
  mergedConfig.ONLINE_BOOKING_BASE_URL;
export const RAVEN_BASE_URL = (config.RAVEN_BASE_URL_CONFIG && config.RAVEN_BASE_URL_CONFIG[hostname]) || (config.RAVEN_BASE_URL_CONFIG && config.RAVEN_BASE_URL_CONFIG[organisationPrettyName]) ||
  mergedConfig.RAVEN_BASE_URL;
export const LTL_BASE_URL = (config.LTL_BASE_URL_CONFIG && config.LTL_BASE_URL_CONFIG[hostname]) || (config.LTL_BASE_URL_CONFIG && config.LTL_BASE_URL_CONFIG[organisationPrettyName]) ||
  mergedConfig.LTL_BASE_URL;
export const USE_HARDCODED_MASTER_DATA = mergedConfig.USE_HARDCODED_MASTER_DATA;
export const HARDCODED_MASTER_DATA = mergedConfig.HARDCODED_MASTER_DATA;
export const CONSIGNMENT_LABEL_URL = (config.CONSIGNMENT_LABEL_URL_CONFIG && config.CONSIGNMENT_LABEL_URL_CONFIG[hostname]) || (config.CONSIGNMENT_LABEL_URL_CONFIG && config.CONSIGNMENT_LABEL_URL_CONFIG[organisationPrettyName]) ||
  mergedConfig.CONSIGNMENT_LABEL_URL;

export const NAVBAR_HEIGHT = 56;
export const INTEGRATION_MARKETPLACE_BANNER_HEIGHT = 250;
export const MOBILE_BREAK_POINT = 590;
export const GOOGLE_MAPS_API_KEY = mergedConfig.GOOGLE_MAPS_API_KEY;
export const SELLER_PROFILE_BASE_URL = mergedConfig.SELLER_PROFILE_BASE_URL;

// Read the injected data and export it
let injectedData = null;
const injectedDataString = (window as any).injectedConfig || base64.encode(JSON.stringify({
  applicationType: 'ops',
}));
const dataJson = base64.decode(injectedDataString);
injectedData = JSON.parse(dataJson);
export { injectedData };

// Export applicationType from injected data
export const applicationType = (injectedData as any).applicationType;
export const CUSTOMER_ACTIVITY_POLLING_FREQUENCY  =
mergedConfig.CUSTOMER_ACTIVITY_POLLING_FREQUENCY || 30000;
export const LTL_POLLING_FREQUENCY = mergedConfig.LTL_POLLING_FREQUENCY || 30000;
export const DOWNLOADS_POLLING_FREQUENCY = mergedConfig.DOWNLOADS_POLLING_FREQUENCY || 20000;
export const DOMESTIC_STATUS_FETCH_POLLING_FREQUENCY =
  mergedConfig.DOMESTIC_STATUS_FETCH_POLLING_FREQUENCY || 10000;
export const FoodDashboard_LIVE_TRACKING_POLLING_FREQUENCY =
    mergedConfig.FoodDashboard_LIVE_TRACKING_POLLING_FREQUENCY || 30000;
export const FoodDashboard_DISPATCH_POLLING_FREQUENCY =
      mergedConfig.FoodDashboard_DISPATCH_POLLING_FREQUENCY || 10000;
export const FoodDashboard_DISPATCH_ANALYTICS_POLLING_FREQUENCY_MILLIS =
      mergedConfig.FoodDashboard_DISPATCH_ANALYTICS_POLLING_FREQUENCY_MILLIS || 120000;
export const LOG_ROCKET_KEY = mergedConfig.LOG_ROCKET_KEY;
export const LOG_ROCKET_CONFIG = mergedConfig.LOG_ROCKET_CONFIG;

export const INVOICE_DOWNLOAD_REQUEST_POLLING =
  mergedConfig.INVOICE_DOWNLOAD_REQUEST_POLLING || 5000;
export const ANALYTICS_DATA =  mergedConfig.ANALYTICS_DATA;
export const SHOW_BOOKING = mergedConfig.SHOW_BOOKING;
export const SENTRY_KEY = mergedConfig.SENTRY_KEY;
export const VERSION = (window as any).version;
export const ENVIRONMENT = mergedConfig.ENVIRONMENT;
export const PLANNING_HUB = 'dc';

export const ROUTE_OPTIMISATION_BASE_URL = mergedConfig.ROUTE_OPTIMISATION_BASE_URL ||
  mergedConfig.API_BASE_URL;
export const IFRAME_NON_STRICT_MODE = mergedConfig.IFRAME_NON_STRICT_MODE || false;
export const RENDER_PERSISTENT_IFRAME_OPS = mergedConfig.RENDER_PERSISTENT_IFRAME_OPS || false;
export const HIDE_ADD_TO_EXISTING_TRIP_RETAIL = mergedConfig.HIDE_ADD_TO_EXISTING_TRIP_RETAIL;
export const HIDE_RETAIL_CUSTOMER_SUPPORT_PORTAL = mergedConfig.HIDE_RETAIL_CUSTOMER_SUPPORT_PORTAL;
export const HIDE_RETAIL_STORE_HUB_CHANGES = mergedConfig.HIDE_RETAIL_STORE_HUB_CHANGES;
export const HIDE_RETAIL_SCHEDULING = mergedConfig.HIDE_RETAIL_SCHEDULING;
export const QUERYBUILDER_CONFIG = mergedConfig.QUERYBUILDER_CONFIG;
export const API_PLAYGROUND_CONFIG = mergedConfig.API_PLAYGROUND_CONFIG;
export const LIA_CO_PILOT_CONFIG = mergedConfig.LIA_CO_PILOT_CONFIG;
export const ROUTING_PLAYGROUND_URL = mergedConfig.ROUTING_PLAYGROUND_URL;
export const SHOW_DEV_TOOLS = config.SHOW_DEV_TOOLS;
export const IS_FINAL_CONFIG_EQUAL = _.isEqual(mergedConfig, config);
export const USERPILOT_TOKEN = config.USERPILOT_TOKEN;
export const USERPILOT_BLACKLISTED_ORGS = config.USERPILOT_BLACKLISTED_ORGS;
export const GA_MID = config.GA_MID;
export const ZAPSCALE_ORG_LIST = config.ZAPSCALE_ORG_LIST;
export const IS_ENV_PRODUCTION = config.IS_ENV_PRODUCTION;
export const MAPBOX_ACCESS_TOKEN = mergedConfig.MAPBOX_ACCESS_TOKEN || '';
export const GREEN_DEPLOYMENT_URLS = config.GREEN_DEPLOYMENT_URLS || [];
export const SSO_REDIRECT_BASE_URL = config.SSO_REDIRECT_BASE_URL;
export const DEVREV_APP_ID = config.DEVREV_APP_ID;
export const GOOGLE_TAG_MANAGER_ID = config.GOOGLE_TAG_MANAGER_ID;
export const FIREBASE_CONFIG = config.FIREBASE_CONFIG || {};
export const FIREBASE_VAPID_KEY = config.FIREBASE_VAPID_KEY || '';
export const FIRESTORE_DB_ID = config.FIRESTORE_DB_ID || '';
export const AES_ENCRYPTION_KEY = config.AES_ENCRYPTION_KEY;
export const SSO_DISPLAY_MESSAGE_MAX_LENGTH = _.get(config,'SSO_DISPLAY_MESSAGE_MAX_LENGTH',30);
export const AUTH_RSA_PUBLIC_KEY = config.AUTH_RSA_PUBLIC_KEY;
export const STADIA_MAPS_API_KEY = config.STADIA_MAPS_API_KEY;


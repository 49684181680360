export const downloadColumns = [
  { key: 'planningId', label: 'Planning Id' },
  { key: 'createdAt', label: 'Creation Time' },
  { key: 'updatedAt', label: 'Updated At' },
  { key: 'status', label: 'Status' },
  { key: 'hubCode', label: 'Hub Code' },
  { key: 'constraint_data', label: 'Constraint Data' },
  { key: 'error', label: 'Error' },
  { key: 'optimisation_summary', label: 'Optimisation Summary' },
  { key: 'actions', label: 'Actions' },
];

export const optimisationSummaryChildColumns = [
  { key: 'planned_cn_count', label: 'Planned CNs' },
  { key: 'dropped_cn_count', label: 'Dropped CNs' },
  { key: 'planned_trips_count', label: 'Planned Trips' },
];

export const droppedCNColumns = [
  {
    key: 'reference_number',
    label: 'CN Number',
  },
  {
    key: 'drop_reason_code',
    label: 'Reason',
  },
];

export const downloadColumnsPrintLabel = [
  { key: 'createdAt', label: 'Creation Time' },
  { key: 'updatedAt', label: 'Updated At' },
  { key: 'status', label: 'Status' },
  { key: 'hubCode', label: 'Hub Code' },
  { key: 'error', label: 'Error' },
  { key: 'failed', label: 'Failed CNs' },
  { key: 'labelType', label: 'Label Type' },
  { key: 'actions', label: 'Actions' },
];
export const customTripColumns = [
  { key: 'createdAt', label: 'Creation Time' },
  { key: 'lastAttemptTime', label: 'Updated At' },
  { key: 'filterParams', label: 'Filters' },
  { key: 'status', label: 'Status' },
  { key: 'errors', label: 'Errors' },
  { key: 'actions', label: 'Actions' },
];

export const CNUploadRequestsColumns = [
  { key: 'createdAt', label: 'Creation Time' },
  { key: 'jobType', label: 'Job Type' },
  { key: 'employeeCode', label: 'Employee ID' },
  { key: 'totalConsignmentCount', label: 'Total CN' },
  { key: 'onGoingConsignmentCount', label: 'On Going Uploads' },
  { key: 'successConsignmentCount', label: 'Successful Uploads' },
  { key: 'failedConsignmentCount', label: 'Failed Uploads' },
  { key: 'status', label: 'Status' },
];

const requestType = {
  ASYNC_CUSTOM_TRIP_CREATION: 'Custom Trip',
};

export const jobType = {
  ASYNC_CONSIGNMENT_SOFTDATA_UPLOAD: 'ASYNC_CONSIGNMENT_SOFTDATA_UPLOAD',
};

export const csvRequestType = {
  totalConsignmentCount: 'ALL',
  onGoingConsignmentCount: 'ON_GOING',
  successConsignmentCount: 'SUCCESSFUL',
  failedConsignmentCount: 'FAILED',
};

export const consignmentUploadTypes = [
  'totalConsignmentCount',
  'onGoingConsignmentCount',
  'successConsignmentCount',
  'failedConsignmentCount',
];

export const getFilterParams = (record) => {
  return [
    {
      key: 'hubCode',
      value: record.hubCode,
    },
    {
      key: 'requestType',
      value: requestType[record.jobType] || record.jobType,
    },
    {
      key: 'fromDate',
      value: record.from_date,
    },
    {
      key: 'toDate',
      value: record.to_date,
    },
  ];
};

export const statusPrettyMapping = {
  failed_no_retry: 'Failed',
};

export const jobTypes = [
  {
    name: 'Custom Trip',
    id: 'ASYNC_CUSTOM_TRIP_CREATION',
  },
  {
    name: 'Third Party Reconcile',
    id: 'ASYNC_THIRD_PARTY_CONSIGNMENT_RECONCILE',
  },
  {
    name: 'Auto Cancellation',
    id: 'ASYNC_AUTO_CANCEL_CONSIGNMENT',
  },
  {
    name: 'Bulk Delete Trips',
    id: 'ASYNC_BULK_DELETE_TRIPS',
  },
];
